import StageHelper from '../utils/StageHelper';

const isStaging = StageHelper.isStaging();

export default {
  // Vuplex site URLs
  Contact: 'https://support.vuplex.com/contact',
  Dashboard: isStaging ? 'https://dashboard-staging.vuplex.com' : 'https://dashboard.vuplex.com',
  Developer: 'https://developer.vuplex.com',
  Login: isStaging ? 'https://login-staging.vuplex.com' : 'https://login.vuplex.com',
  Store: isStaging ? 'https://store-staging.vuplex.com' : 'https://store.vuplex.com',
  StoreBundle: 'https://store.vuplex.com/cart',

  // Social URLs
  Facebook: 'https://www.facebook.com/vuplexbrowser/',
  GitHub: 'https://github.com/vuplex',
  Twitter: 'https://twitter.com/vuplex'
};
