import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithPdfCreation',
  type: 'interface',
  description: 'An interface implemented by a webview if it supports creating a PDF from a web page. Created PDFs are saved to [Application.temporaryCachePath](https://docs.unity3d.com/ScriptReference/Application-temporaryCachePath.html), but you can move them to a different location after they are created. If you wish to format the PDF differently on Windows and macOS, you can achieve that by using [StandaloneWebView.CreatePdf()](/webview/StandaloneWebView#CreatePdf) instead.',
  warnings: [
    'On iOS, PDF creation is only supported on iOS 14 and newer.',
    "On iOS and visionOS, generated PDFs are not paginated (i.e. the PDF is one long page) due to a limitation of WKWebView's PDF functionality."
  ],
  example: `await webViewPrefab.WaitUntilInitialized();
var webViewWithPdfCreation = webViewPrefab.WebView as IWithPdfCreation;
if (webViewWithPdfCreation == null) {
    Debug.Log("This 3D WebView plugin doesn't yet support IWithPdfCreation: " + webViewPrefab.WebView.PluginType);
    return;
}
var pdfFilePath = await webViewWithPdfCreation.CreatePdf();
// Now that the PDF has been created, do something with it.
// For example, you can move it to a different location.
File.Move(pdfFilePath, someOtherLocation);`,
  members: [
    {
      name: 'CreatePdf',
      type: 'method',
      returns: 'Task<string>',
      description: 'Creates a PDF from the current web page and returns the full file path of the created PDF. PDFs are saved to [Application.temporaryCachePath](https://docs.unity3d.com/ScriptReference/Application-temporaryCachePath.html), but you can move them to a different location after they are created.',
    },
  ]
};
export default definition;
