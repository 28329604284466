import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithSettableUserAgent',
  type: 'interface',
  description: "An interface implemented by a webview if it supports changing the [User-Agent](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent) of an individual webview instance.",
  example: `await webViewPrefab.WaitUntilInitialized();
var webViewWithUserAgent = webViewPrefab.WebView as IWithSettableUserAgent;
if (webViewWithUserAgent != null) {
    // Set a flag indicating a mobile User-Agent.
    webViewWithUserAgent.SetUserAgent(true);
    // OR set a custom User-Agent string.
    webViewWithUserAgent.SetUserAgent("Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:91.0) Gecko/20100101 Firefox/91.0");
}`,
  members: [
    {
      name: 'SetUserAgent',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'mobile',
          type: 'bool'
        }
      ],
      description: "By default, a webview uses a User-Agent of a desktop computer so that servers return the desktop versions of websites. If you instead want the mobile versions of websites, you can invoke this method with the value `true` to configure the webview instance to use a User-Agent of a mobile device.",
      seeAlso: '[Web.SetUserAgent()](/webview/Web#SetUserAgent)'
    },
    {
      name: 'SetUserAgent',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'userAgent',
          type: 'string'
        }
      ],
      description: "Configures the webview instance to use a custom User-Agent string.",
      seeAlso: '[Web.SetUserAgent()](/webview/Web#SetUserAgent)'
    },
  ]

};
export default definition;
