
enum ProductId {
  UnityWebViewStandalone = 'Unity.WebView.Standalone',
  UnityWebViewAndroid = 'Unity.WebView.Android',
  UnityWebViewAndroidGecko = 'Unity.WebView.AndroidGecko',
  UnityWebViewiOS = 'Unity.WebView.iOS',
  UnityWebViewUwp = 'Unity.WebView.Uwp',
  UnityWebViewVisionOS = 'Unity.WebView.VisionOS',
  UnityWebViewWebGL = 'Unity.WebView.WebGL',
}

export default ProductId;

const enumValues = Object.values(ProductId) as string[];

export function isValidProductId(value: string) {

  return enumValues.includes(value);
}
