
export default function formatDate(date: Date|string, options?: { abbreviateMonth?: boolean }): string {

  if (typeof date === 'string') {
    date = new Date(date);
  }
  let month = MONTHS[date.getUTCMonth()];
  if (options && options.abbreviateMonth) {
    month = month.slice(0, 3);
  }
  return `${month} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
}

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
