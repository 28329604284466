import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import HashLink from '../HashLink';
import '../../shared-styles/page.scss';
import './styles.scss';

export default class TroubleshootingPage extends Component {

  render() {
    return (
      <div className="page troubleshooting-page">
        <Helmet>
          <title>Troubleshooting | 3D WebView</title>
          <meta name="description" content="Tips on troubleshooting Vuplex 3D WebView for Unity."/>
        </Helmet>
        <h1>Troubleshooting</h1>
        <p>
          Things aren't working as expected? Or not finding an API you need? Please try the following tips:
        </p>
        <ul>
          <li>
            <a href="https://support.vuplex.com/search">Use the support search page</a> to search all of 3D WebView's documentation and support articles (FAQ).
          </li>
          <li>
            Use <a href="https://support.vuplex.com/articles/how-to-debug-web-content">remote debugging</a> or <HashLink to="/webview/WebViewPrefab#LogConsoleMessages">JavaScript console logging</HashLink> to debug web content.
          </li>
          <li>
            If something's not working in your scene, please test with one of 3D WebView's demo scenes or example projects for comparison:
            <ul>
              <li>
                Demo scenes in Assets/Vuplex/WebView/Demos (like SimpleWebViewDemo and CanvasWebViewDemo)
              </li>
              <li>
                <a href="https://github.com/vuplex/oculus-webview-example">
                  Meta Quest example
                </a>
              </li>
              <li>
                <a href="https://github.com/vuplex/xrit-webview-example">
                  XR Interaction Toolkit example
                </a>
              </li>
              <li>
                <a href="https://github.com/vuplex/ar-foundation-webview-example">
                  AR Foundation example
                </a>
              </li>
              <li>
                <a href="https://github.com/vuplex/hololens-webview-example">
                  Hololens example (MRTK2)
                </a>
              </li>
            </ul>
            If the issue also occurs when running the demo scene, then it may be due to a project setting or that the 3D WebView installation has become corrupted. To isolate the issue, please create a new Unity project with a clean 3D WebView installation for comparison.
          </li>
          <li>
            JavaScript is 3D WebView's "Swiss Army knife". If a C# method doesn't exist for the goal you're trying to accomplish, it's likely that you can achieve it with JavaScript browser APIs
            using <HashLink to="/webview/IWebView#ExecuteJavaScript">ExecuteJavaScript()</HashLink>, <HashLink to="/webview/IWebView#PageLoadScripts">PageLoadScripts</HashLink>, and <a href="https://support.vuplex.com/articles/how-to-send-messages-from-javascript-to-c-sharp">JS-to-C# message passing</a>.
          </li>
          <li>
            Tried the tips above and are still encountering issues? Sorry about that! Please <a href="https://support.vuplex.com/contact">contact me</a>.
          </li>
        </ul>
      </div>
    );
  }
}
