import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'StandaloneX509Certificate',
  type: 'class',
  description: "An X.509 certificate used for [StandaloneClientCertificateRequestedEventArgs](/webview/StandaloneClientCertificateRequestedEventArgs).",
  members: [
    {
      name: 'Issuer',
      returns: 'StandaloneX509CertificatePrincipal',
      type: 'property',
      description: "The issuer of the X.509 certificate."
    },
    {
      name: 'Subject',
      returns: 'StandaloneX509CertificatePrincipal',
      type: 'property',
      description: "The subject of the X.509 certificate. For HTTPS server certificates this represents the web server.  The common name of the subject should match the host name of the web server."
    },
    {
      name: 'ValidStart',
      returns: 'DateTime',
      type: 'property',
      description: "The [DateTime](https://learn.microsoft.com/en-us/dotnet/api/system.datetime) before which the X.509 certificate is invalid, or [DateTime.MinValue](https://learn.microsoft.com/en-us/dotnet/api/system.datetime.minvalue) if no date was specified."
    },
    {
      name: 'ValidExpiry',
      returns: 'DateTime',
      type: 'property',
      description: "The [DateTime](https://learn.microsoft.com/en-us/dotnet/api/system.datetime) after which the X.509 certificate is invalid, or [DateTime.MinValue](https://learn.microsoft.com/en-us/dotnet/api/system.datetime.minvalue) if no date was specified."
    },
  ]
};
export default definition;
