import CodeFileMember from '../CodeFileMember';

const sharedMembers: CodeFileMember[] = [
  {
    name: 'KeyPressed',
    returns: 'EventHandler<EventArgs<string>>',
    type: 'event',
    description: 'Indicates that the user pressed a key on the keyboard.'
  },
  {
    name: 'Initialized',
    returns: 'EventHandler',
    type: 'event',
    description: 'Indicates that the keyboard finished initializing.'
  },
  {
    name: 'CustomKeyboardUrl',
    returns: 'string',
    type: 'property',
    description: 'If you want to load a customized version of the Keyboard UI, you can do so by setting this field. For example, you could load a customized Keyboard UI [from StreamingAssets](https://support.vuplex.com/articles/how-to-load-local-files) by using a URL like "streaming-assets://keyboard/index.html".'
  },
  {
    name: 'Resolution',
    returns: 'float',
    type: 'property',
    description: "Sets the keyboard's initial resolution in pixels per Unity unit.You can change the resolution to make the keyboard's content appear larger or smaller. For more information on scaling web content, see [this support article](https://support.vuplex.com/articles/how-to-scale-web-content)."
  },
  {
    name: 'WaitUntilInitialized',
    returns: 'Task',
    type: 'method',
    description: "Returns a task that completes when the keyboard is initialized, which means that its WebViewPrefab property is ready for use.",
    example: `await keyboard.WaitUntilInitialized();
keyboard.WebViewPrefab.Clicked += (sender, eventArgs) => {
    Debug.Log("Keyboard was clicked");
};`

  },
];

export default sharedMembers;
