import FileReferenceDefinition from '../FileReferenceDefinition';
import Products from '../../web-common/models/products/Products';

const definition: FileReferenceDefinition = {
  name: 'IWithMovablePointer',
  type: 'interface',
  description: 'An interface implemented by a webview if it supports [MovePointer()](#MovePointer), which can be used to implement hover or drag interactions.',
  example: `var webViewWithMovablePointer = webViewPrefab.WebView as IWithMovablePointer;
if (webViewWithMovablePointer == null) {
    Debug.Log("This 3D WebView plugin doesn't yet support IWithMovablePointer: " + webViewPrefab.WebView.PluginType);
    return;
}
// Move the pointer to (250px, 100px) in the web page.
var normalizedPoint = webViewPrefab.WebView.PointToNormalized(250, 100);
webViewWithMovablePointer.MovePointer(normalizedPoint);`,
  warnings: [
    `For details on the limitations of hover and drag interactions on iOS and UWP, please see [this page](https://support.vuplex.com/articles/hover-and-drag-limitations).`,
    `The [Android Gecko](${Products.androidGecko.storeUrl}) package doesn't support the HTML Drag and Drop API ([GeckoView limitation](https://caniuse.com/dragndrop#:~:text=4-,Firefox%20for%20Android)).`
  ],
  members: [
    {
      name: 'MovePointer',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'Vector2',
          name: 'point'
        },
        {
          type: 'bool',
          name: 'pointerLeave',
          defaultValue: 'false'
        }
      ],
      description: `Moves the pointer to the given [normalized point](https://support.vuplex.com/articles/normalized-points) in the web page. The optional \`pointerLeave\` parameter controls whether a [pointerleave](https://developer.mozilla.org/en-US/docs/Web/API/Element/pointerleave_event) JavaScript event is fired to indicate that the pointer has left the webview. This method can be used to trigger hover effects in the page or can be used in conjunction with [IWithPointerDownAndUp](/webview/IWithPointerDownAndUp) to implement drag interactions.`
    }
  ]
};
export default definition;
