import React, { Component } from 'react';
import Products from '../../models/products/Products';
import windowsImage from './assets/windows.svg';
import macImage from './assets/mac.png';
import androidImage from './assets/android.svg';
import iosImage from './assets/apple.svg';
import visionOSImage from './assets/visionos.png';
import webGLImage from './assets/html5.svg';
import './styles.scss';

export default class StoreLinks extends Component {

  render() {
    return (
      <div className="store-links">
        <a className="standalone" href={Products.standalone.storeUrl}>
          <img src={windowsImage} alt="Windows logo"/>
          <img src={macImage} alt="Mac logo"/>
          Windows + Mac
        </a>
        <div className="android">
          <div>
            <img src={androidImage} alt="Android logo"/>
            Android
          </div>
          <div className="dropdown">
            <div>
              <a href={Products.android.storeUrl}>Original</a>
              <a href={Products.androidGecko.storeUrl}>Gecko Engine</a>
              <a href="https://support.vuplex.com/articles/android-comparison">Comparison</a>
            </div>
          </div>
        </div>
        <a className="ios" href={Products.iOS.storeUrl}>
          <img src={iosImage} alt="Apple logo"/>
          iOS
        </a>
        <a className="visionos" href={Products.visionOS.storeUrl}>
          <img src={visionOSImage} alt="visionOS logo" />
          visionOS
        </a>
        <a className="webgl" href={Products.webGL.storeUrl}>
          <img src={webGLImage} alt="HTML5 logo" />
          WebGL
        </a>
        <a className="uwp" href={Products.uwp.storeUrl}>
          <img src={windowsImage} alt="UWP logo" />
          UWP
        </a>
      </div>
    );
  }
}
