import Products from '../../web-common/models/products/Products';
import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'VisionOSWebView',
  type: 'class',
  description: `VisionOSWebView is the [IWebView](/webview/IWebView) implementation used by ${Products.visionOS.markdownLink}. It also includes additional APIs for visionOS-specific functionality.`,
  inheritsFrom: 'IWebView',
  additionalInterfaces: [
    'IWithDeepLinking',
    'IWithDownloads',
    'IWithFallbackVideo',
    'IWithMovablePointer',
    'IWithNativeJavaScriptDialogs',
    'IWithNativeOnScreenKeyboard',
    'IWithPdfCreation',
    'IWithPointerDownAndUp',
    'IWithPopups',
    'IWithSettableUserAgent'
  ],
  sidebarSection: 'platform',
  members: [
    {
      name: 'Window',
      returns: 'VisionOSWindow',
      type: 'property',
      description: `If the webview was created via [CreateInWindow()](#CreateInWindow), this property is a [VisionOSWindow](/webview/VisionOSWindow) instance that provides APIs to manipulate the native SwiftUI window, such as closing and re-opening it. If the webview was not created via CreateInWindow() (e.g. it was created via a [CanvasWebViewPrefab](/webview/CanvasWebViewPrefab)), then this property is \`null\`.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow();
    // Add an event handler to detect when the user closes the window.
    webView.Window.Closed += (sender, eventArgs) => {
        Debug.Log("window closed");
    };
#endif`,
    },
    {
      name: 'CreateInWindow',
      type: 'method',
      returns: 'Task<VisionOSWebView>',
      description: `When running in Unity's [RealityKit app mode](https://docs.unity3d.com/Packages/com.unity.xr.visionos@2.0/api/UnityEditor.XR.VisionOS.VisionOSSettings.AppMode.html?q=appmode), the application can use this method to open a webview in a native visionOS (SwiftUI) window, which the user can reposition, resize, and close using visionOS's native windowing controls. The application can also manipulate the webview's window programmatically via the [VisionOSWebView.Window](#Window) property (for example, to close and reopen the window, or detect when it has been closed by the user). This native window approach provides the best performance, and since it supports hovering over elements in the web page via eye tracking, it also provides the best user experience. The tradeoff is that the application is unable to control the webview's position or size after it is opened because those aspects are controlled by the user. This version of CreateInWindow() opens the webview's window using the system's default size (1280 x 720 px) and position (in front of the user). To customize the window's initial size and position, use [CreateInWindow(VisionOSWindowOptions)](#CreateInWindow) instead.

Additional notes:
- For a full example, please see the [visionOS RealityKit WebView Example](https://github.com/vuplex/visionos-realitykit-webview-example) project.
- CreateInWindow() is only supported when the application's app mode is set to [RealityKit](https://docs.unity3d.com/Packages/com.unity.xr.visionos@2.0/api/UnityEditor.XR.VisionOS.VisionOSSettings.AppMode.html?q=appmode), and it throws an InvalidOperationException if invoked when the app mode is set to Metal or Windowed.
- When the window is closed (i.e. due to the user pressing its close button or the application calling [Window.Close()](/webview/VisionOSWindow#Close)),
  the webview is not automatically destroyed; the webview remains running and the window can be reopened by calling [Window.Open()](/webview/VisionOSWindow#Open). The webview is only destroyed if the application calls [IWebView.Dispose()](/webview/IWebView#Dispose). So, unless the application uses Window.Open() to reopen a webview after it has been closed by the user, it's recommended to attach an event handler to the [Window.Closed](/webview/VisionOSWindow#Closed) event that calls IWebView.Dispose() when the user has closes the window. This approach is demonstrated in the example below.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow();
    // The webview has been displayed to the user in a native window,
    // and now you can use IWebView APIs like LoadUrl() and LoadHtml().
    webView.LoadUrl("https://vuplex.com");
    // Add an event handler that destroys the webview when the user closes the window.
    webView.Window.Closed += (sender, eventArgs) => {
        webView.Dispose();
    };
#endif`,
      seeAlso: [
        '[VisionOSWebView.Window](#Window)',
        '[visionOS RealityKit webview example project](https://github.com/vuplex/visionos-realitykit-webview-example)'
      ]
    },
    {
      name: 'CreateInWindow',
      type: 'method',
      returns: 'Task<VisionOSWebView>',
      arguments: [
        {
          name: 'options',
          type: 'VisionOSWindowOptions'
        }
      ],
      description: `Like CreateInWindow(), except it also accepts a [VisionOSWindowOptions](/webview/VisionOSWindowOptions) parameter for customizing the window's initial size and position.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow(
        new VisionOSWindowOptions {
            Size = new Vector2Int(1000, 1000),
            Position = new VisionOSWindowPosition(
                VisionOSWindowPositionType.Trailing,
                VisionOSWindowPosition.UnityBoundedSceneID
            )
        }
    );
    // The webview has been displayed to the user in a native window,
    // and now you can use IWebView APIs like LoadUrl() and LoadHtml().
    webView.LoadUrl("https://vuplex.com");
    // Add an event handler that destroys the webview when the user closes the window.
    webView.Window.Closed += (sender, eventArgs) => {
        webView.Dispose();
    };
#endif`,
      warnings: [
        `The VisionOSWindowsOptions.Size and Position options require visionOS 2.0. On visionOS 1.x, the Size and Position options are ignored, and a window always uses the system's default size and position.`
      ]
    },
    {
      name: 'GetNativeWebView',
      type: 'method',
      returns: 'IntPtr',
      description: "Returns a pointer to the instance's native Objective-C [WKWebView](https://developer.apple.com/documentation/webkit/wkwebview?language=objc).",
      warnings: ["Adding code that interacts with the native WKWebView directly may interfere with 3D WebView's functionality and vice versa. So, it's highly recommended to stick to 3D WebView's C# APIs whenever possible and only use GetNativeWebView() if truly necessary. If 3D WebView is missing an API that you need, feel free to [contact us](https://vuplex.com/contact)."],
      example: [
        {
          title: 'Objective-C Example',
          code: `// Example of defining a native Objective-C function that sets WKWebView.allowsLinkPreview.
// Place this in a .m file in your project, like Assets/Plugins/WebViewCustom.m
#import <Foundation/Foundation.h>
#import <WebKit/WebKit.h>

void WebViewCustom_SetAllowsLinkPreview(WKWebView *webView, BOOL allowsLinkPreview) {

    webView.allowsLinkPreview = allowsLinkPreview;
}`
        },
        {
          title: 'C# Example',
          code: `// Example of calling the native Objective-C function from C#.
async void EnableLinkPreviews(WebViewPrefab webViewPrefab) {

    await webViewPrefab.WaitUntilInitialized();
    #if UNITY_VISIONOS && !UNITY_EDITOR
        var wkWebViewPtr = (webViewPrefab.WebView as VisionOSWebView).GetNativeWebView();
        WebViewCustom_SetAllowsLinkPreview(wkWebViewPtr, true);
    #endif
}

[System.Runtime.InteropServices.DllImport("__Internal")]
static extern void WebViewCustom_SetAllowsLinkPreview(System.IntPtr webViewPtr, bool allowsLinkPreview);`
        }
      ]
    },
    {
      name: 'SetCameraEnabled',
      returns: 'void',
      static: true,
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "Like [Web.SetCameraAndMicrophoneEnabled()](/webview/Web#SetCameraAndMicrophoneEnabled), but enables only the camera without enabling the microphone. In addition to calling this method, you must also complete the additional steps described [here](https://support.vuplex.com/articles/webrtc#ios) in order to successfully enable the camera.",
      example: `void Awake() {
    #if UNITY_VISIONOS && !UNITY_EDITOR
        VisionOSWebView.SetCameraEnabled(true);
    #endif
}`
    },
    {
      name: 'SetMicrophoneEnabled',
      returns: 'void',
      static: true,
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "Like [Web.SetCameraAndMicrophoneEnabled()](/webview/Web#SetCameraAndMicrophoneEnabled), but enables only the microphone without enabling the camera. In addition to calling this method, you must also complete the additional steps described [here](https://support.vuplex.com/articles/webrtc#ios) in order to successfully enable the microphone.",
      example: `void Awake() {
    #if UNITY_VISIONOS && !UNITY_EDITOR
        VisionOSWebView.SetMicrophoneEnabled(true);
    #endif
}`
    },
    {
      name: 'SetTargetFrameRate',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'targetFrameRate',
          type: 'uint'
        }
      ],
      description: "Sets the target web frame rate. The default is `30`, which is also the maximum value. This method can be used to lower the target web frame rate in order to decrease energy and CPU usage. 3D WebView's rendering speed is limited by the speed of the underlying visionOS APIs, so the actual web frame rate achieved is always lower than the default target of 30 FPS.",
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_VISIONOS && !UNITY_EDITOR
    var visionOSWebView = webViewPrefab.WebView as VisionOSWebView;
    visionOSWebView.SetTargetFrameRate(15);
#endif`,
    },
  ]
};
export default definition;

