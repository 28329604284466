import CodeFileMember from '../CodeFileMember';

export default function getSharedAndroidMembers(isGecko = false): CodeFileMember[] {

  const className = isGecko ? 'AndroidGeckoWebView' : 'AndroidWebView';
  const instanceName = isGecko ? 'androidGeckoWebView' : 'androidWebView';

  return [
    {
      name: 'ScriptAlerted',
      returns: 'EventHandler<ScriptDialogEventArgs>',
      type: 'event',
      description: `Event raised when a script in the page calls [window.alert()](https://developer.mozilla.org/en-US/docs/Web/API/Window/alert). If no handler is attached to this event, then \`window.alert()\` will return immediately and the script will continue execution. If a handler is attached to this event, then script execution will be paused until the event args' [Continue()](/webview/ScriptDialogEventArgs#Continue) callback is called.`,
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var ${instanceName} = webViewPrefab.WebView as ${className};
    ${instanceName}.ScriptAlerted += (sender, eventArgs) => {
        Debug.Log("Script alerted: " + eventArgs.Message);
        eventArgs.Continue();
    };
#endif`
    },
    {
      name: 'ScriptConfirmRequested',
      returns: 'EventHandler<ScriptDialogEventArgs<bool>>',
      type: 'event',
      description: `Event raised when a script in the page calls [window.confirm()](https://developer.mozilla.org/en-US/docs/Web/API/Window/confirm). If no handler is attached to this event, then \`window.confirm()\` will return \`false\` immediately and the script will continue execution. If a handler is attached to this event, then script execution will be paused until the event args' [Continue()](/webview/ScriptDialogEventArgs#Continue) callback is called, and \`window.confirm()\` will return the value passed to [Continue()](/webview/ScriptDialogEventArgs#Continue).`,
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var ${instanceName} = webViewPrefab.WebView as ${className};
    ${instanceName}.ScriptConfirmRequested += (sender, eventArgs) => {
        Debug.Log("Script confirm requested: " + eventArgs.Message);
        eventArgs.Continue(true);
    };
#endif`
    },
    {
      name: 'Pause',
      returns: 'void',
      type: 'method',
      description: "Pauses processing, media, and rendering for this webview instance until [Resume()](#Resume) is called.",
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    var ${instanceName} = webViewPrefab.WebView as ${className};
    ${instanceName}.Pause();
#endif`
    },
    {
      name: 'Resume',
      returns: 'void',
      type: 'method',
      description: "Resumes processing and rendering for all webview instances after a previous call to [Pause()](#Pause).",
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    var ${instanceName} = webViewPrefab.WebView as ${className};
    ${instanceName}.Resume();
#endif`
    },
    {
      name: 'ResumeAll',
      static: true,
      returns: 'void',
      type: 'method',
      description: 'Resumes processing and rendering for all webview instances after a previous call to [PauseAll()](#PauseAll). This method is automatically called by the plugin when the application resumes after being paused.',
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    ${className}.ResumeAll();
#endif`
    },
    {
      name: 'RunOnAndroidUIThread',
      static: true,
      returns: 'void',
      arguments: [{ type: 'Action', name: 'function' }],
      type: 'method',
      description: 'Runs the given function on the Android UI thread.',
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    ${className}.RunOnAndroidUIThread(() => {
        // TODO: Do something on the Android UI thread.
    });
#endif`
    },
    {
      name: 'SetDrmEnabled',
      static: true,
      returns: 'void',
      type: 'method',
      description: 'Enables WideVine DRM. DRM is disabled by default because it could potentially be used for tracking. You can verify that DRM is enabled by using the DRM Stream Test on [this page](https://bitmovin.com/demos/drm).',
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    ${className}.SetDrmEnabled(true);
#endif`,
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ]
    },
    {
      name: 'SetGeolocationEnabled',
      returns: 'void',
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: `
By default, web pages cannot access the device's geolocation via JavaScript, even if the user has granted the app permission to access location. Invoking \`SetGeolocationEnabled(true)\` allows **all web pages** to access the geolocation if the user has granted the app location permissions via the [standard Android permission dialogs](https://developer.android.com/training/permissions/requesting).

The following Android permissions must be included in the app's [AndroidManifest.xml](https://docs.unity3d.com/Manual/android-manifest.html) and also [requested by the application at runtime](https://docs.unity3d.com/Manual/android-RequestingPermissions.html):
- [\`android.permission.ACCESS_COARSE_LOCATION\`](https://developer.android.com/reference/android/Manifest.permission#ACCESS_COARSE_LOCATION)
- [\`android.permission.ACCESS_FINE_LOCATION\`](https://developer.android.com/reference/android/Manifest.permission#ACCESS_FINE_LOCATION)`,
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    ${className}.SetGeolocationEnabled(true);
#endif`,
      warnings: ["Geolocation doesn't work on Meta Quest devices because they lack GPS support."]
    },
    {
      name: 'SetSurface',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'IntPtr',
          name: 'surface'
        }
      ],
      description: `Sets the [Surface](https://developer.android.com/reference/android/view/Surface) to which the webview renders. This can be used, for example, to render to a Meta Quest [OVROverlay](https://developer.oculus.com/reference/unity/1.34/class_o_v_r_overlay). When the application invokes this method with a valid surface, the webview renders to that given surface instead of rendering to its original texture surface (so [IWebView.Texture](/webview/IWebView#Texture) is no longer updated). If the application invokes this method with a null parameter, it causes the webview to revert back to rendering to its original texture surface.`,
      example: `await webViewPrefab.WaitUntilInitialized();
var surface = ovrOverlay.externalSurfaceObject;
webViewPrefab.Resize(ovrOverlay.externalSurfaceWidth, ovrOverlay.externalSurfaceHeight);
#if UNITY_ANDROID && !UNITY_EDITOR
    var ${instanceName} = webViewPrefab.WebView as ${className};
    ${instanceName}.SetSurface(surface);
#endif`
    },
  ];
}


