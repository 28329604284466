import React, { Component } from 'react';
import './styles.scss';

export interface SidebarProps {
  mobileOnly?: boolean;
  mobileSidebarOpen: boolean;
  onMobileBackdropClick: () => void;
}

export default class Sidebar extends Component<SidebarProps> {

  render() {

    const classNames = ['sidebar'];
    if (this.props.mobileSidebarOpen) {
      classNames.push('mobile-open-container');
    }
    if (this.props.mobileOnly) {
      classNames.push('mobile-only-sidebar');
    }

    return (
      <div className={classNames.join(' ')}>
        <aside className={`${this.props.mobileSidebarOpen ? 'mobile-open' : 'desktop-sidebar'}`}>
          {this.props.children}
        </aside>
        {this.props.mobileSidebarOpen && <div className="mobile-sidebar-backdrop" onClick={this.props.onMobileBackdropClick} />}
      </div>
    );
  }
}
