import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'VisionOSWindowPosition',
  type: 'class',
  description: `Class for specifying a window's initial position via [VisionOSWindowOptions.Position](/webview/VisionOSWindowOptions#Position). For all [VisionOSWindowPositionType](/webview/VisionOSWindowPositionType) values except UtilityPanel (i.e. Above, Below, Leading, Trailing), a value for [RelativeWindowID](#RelativeWindowID) is required because the position is relative to another SwiftUI window or scene. This API mimics the native SwiftUI [WindowPlacement.Position](https://developer.apple.com/documentation/swiftui/windowplacement/position) structure with which it is implemented.`,
  members: [
    {
      name: 'VisionOSWindowPosition',
      type: 'constructor',
      description: `The constructor for VisionOSWindowPosition. For all [VisionOSWindowPositionType](/webview/VisionOSWindowPositionType) values except UtilityPanel (i.e. Above, Below, Leading, Trailing), the relativeWindowID parameter is required because the position is relative to another SwiftUI window or scene.`,
      arguments: [
        {
          name: 'type',
          type: 'VisionOSWindowPositionType'
        },
        {
          name: 'relativeWindowID',
          type: 'string',
          defaultValue: 'null'
        }
      ]
    },
    {
      name: 'Type',
      type: 'property',
      returns: 'VisionOSWindowPositionType',
      description: `The position type.`,
    },
    {
      name: 'RelativeWindowID',
      type: 'property',
      returns: 'string',
      description: `For all [VisionOSWindowPositionType](/webview/VisionOSWindowPositionType) values except UtilityPanel (i.e. Above, Below, Leading, Trailing), the ID of the existing SwiftUI window or scene to which the position is relative.`,
    },
    {
      name: 'UnityBoundedSceneID',
      type: 'property',
      returns: 'string',
      description: `A constant containing Unity's default ID for a bounded scene, which can be used as a value for RelativeWindowID. The value is \`"Bounded-1.000x1.000x1.000"\`.`,
    },
  ]
};
export default definition;

