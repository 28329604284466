import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithPopups',
  type: 'interface',
  description: `An interface implemented by a webview if it supports opening popups. For detailed examples, please see 3D WebView's PopupDemo and CanvasPopupDemo scenes.

There are several approaches that a web page can use to open a URL in a popup. For example, it can load a new URL using the [window.open()](https://developer.mozilla.org/en-US/docs/Web/API/Window/open) JavaScript API or use the [target="_blank"](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#target) attribute on a link. In a normal web browser, these approaches cause the browser to open the new URL in a new tab or window. In 3D WebView, the default behavior is to load the new URL in the existing webview without creating a new webview. However, an application can change this by using the IWithPopups interface to make popup URLs open in a new popup webview.`,
  example: `await webViewPrefab.WaitUntilInitialized;
var webViewWithPopups = webViewPrefab.WebView as IWithPopups;
if (webViewWithPopups != null) {
    webViewWithPopups.SetPopupMode(PopupMode.LoadInNewWebView);

    webViewWithPopups.PopupRequested += async (sender, eventArgs) => {
        Debug.Log("Popup opened with URL: " + eventArgs.Url);
        // Create and display a new WebViewPrefab for the popup.
        var popupPrefab = WebViewPrefab.Instantiate(eventArgs.WebView);
        popupPrefab.transform.parent = transform;
        popupPrefab.transform.localPosition = Vector3.zero;
        popupPrefab.transform.localEulerAngles = new Vector3(0, 180, 0);
        await popupPrefab.WaitUntilInitialized();
        popupPrefab.WebView.CloseRequested += (popupWebView, closeEventArgs) => {
            Debug.Log("Closing the popup");
            popupPrefab.Destroy();
        };
    };
}`,
  members: [
    {
      name: 'SetPopupMode',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'popupMode',
          type: 'PopupMode'
        }
      ],
      description: 'Sets how the webview handles popups.'
    },
    {
      name: 'PopupRequested',
      type: 'event',
      returns: 'EventHandler<PopupRequestedEventArgs>',
      description: 'Indicates that the webview requested a popup.'
    }
  ]

};
export default definition;
