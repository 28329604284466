import Products from '../../web-common/models/products/Products';
import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'UwpWebView',
  type: 'class',
  description: `UwpWebView is the [IWebView](/webview/IWebView) implementation used by ${Products.uwp.markdownLink}. It also includes additional APIs for UWP-specific functionality.`,
  inheritsFrom: 'IWebView',
  additionalInterfaces: [
    'IWithDeepLinking',
    'IWithMovablePointer',
    'IWithNative2DMode',
    'IWithPointerDownAndUp'
  ],
  sidebarSection: 'platform',
  members: [
    {
      name: 'SetGeolocationEnabled',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      description: `By default, web pages cannot access the device's geolocation via JavaScript. However, geolocation
access can be granted to **all web pages** via the following steps:

- Call \`UwpWebView.SetGeolocationEnabled(true)\` at the start of the app.
- Enable the "Location" capability in "UWP Player Settings" -> "Publishing Settings" -> "Capabilities".
- Enable the "Allow apps to access your location" setting in the device's Location Privacy Settings.

If all three of those conditions are met, then when a web page tries to access a location API, the system will
present the user with a popup to allow or deny location access. If the user allows access, then
**all web pages** will be able to access the device location.`,
      example: `void Awake() {
    #if UNITY_WSA && !UNITY_EDITOR
        UwpWebView.SetGeolocationEnabled(true);
    #endif
}`
    },
  ]
};
export default definition;
