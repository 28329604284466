import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'AuthRequestedEventArgs',
  type: 'class',
  description: 'Event args for [IWithAuth.AuthRequested](/webview/IWithHttpAuth#AuthRequested). Either [Continue()](#Continue) or [Cancel()](#Cancel) must be called in order to resume the page.',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Host',
      returns: 'string',
      type: 'property',
      description: 'The host that requested authentication.'
    },
    {
      name: 'Cancel',
      returns: 'void',
      type: 'method',
      description: 'Declines authentication and resumes the page.'
    },
    {
      name: 'Continue',
      returns: 'void',
      type: 'method',
      description: 'Sends an authentication request to the host.',
      arguments: [
        {
          name: 'username',
          type: 'string'
        },
        {
          name: 'password',
          type: 'string'
        }
      ]
    }
  ]
};
export default definition;
