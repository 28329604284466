import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ViewportMaterialView',
  type: 'class',
  description: 'Script that can be attached to a GameObject with a mesh renderer in order to render the contents of the WebView onto the mesh. Note that `WebViewPrefab` takes care of handling this for you, so you only need to deal with `ViewportMaterialView` directly if you create `IWebView` instances outside of a prefab using `Web.CreateWebView()`.',
  inheritsFrom: 'MonoBehaviour',
  members: [
    {
      name: 'Initialized',
      returns: 'EventHandler',
      type: 'event',
      description: "Indicates when the view's `Texture` property has finished initializing after a call to the asynchronous`Init()` method."
    },
    {
      name: 'IsVideo',
      returns: 'bool',
      type: 'property',
      description: 'A property set via the Unity editor to indicate that the material should default to the video maerial instead of the viewport material. The video material has a lower render queue than the viewport material to ensure that videos are rendered behind the viewport.'
    },
    {
      name: 'Texture',
      returns: 'Texture2D',
      type: 'property',
      description: "The texture for the view, which is a special texture created by `Web.CreateTexture()`. The texture is created asynchronously when `Init()` is called, this property will be `null` until the `Initialized` event is raised. If the webview raises its `TextureChanged` event, this property can be set to the new texture."
    },
    {
      name: 'Init',
      returns: 'void',
      type: 'method',
      description: "Initializes the view with the default material. Initialization is asynchronous, so the `Texture` property will be `null` until the `Initialized` event is raised."
    },
  ]
};
export default definition;
