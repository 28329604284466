import React, { Component } from 'react';
import CodeFileMember, { getFullMemberName } from '../../../models/CodeFileMember';
import HashLink from '../../HashLink';
import './styles.scss';

interface MemberTableProps {
  title: string;
  urlFragment: string;
  members: CodeFileMember[];
  omitLinks?: boolean;
}

export default class MemberTable extends Component<MemberTableProps> {

  render() {
    return (
      <div className="member-table">
        <h3 id={this.props.urlFragment}>{this.props.title}</h3>
        {this.props.members.map(this._renderMember)}
      </div>
    );
  }

  private _renderMember = (member: CodeFileMember) => {

    if (this.props.omitLinks) {
      // For now, enum values don't have links, because there's currently
      // not a bottom section shown for them.
      return (
        <div key={member.name + JSON.stringify(member.arguments)}>
          {getFullMemberName(member)}
        </div>
      )
    }
    return (
      <div key={member.name + JSON.stringify(member.arguments)}>
        <HashLink to={`#${member.name}`} behavior="smooth">
          {getFullMemberName(member)}
        </HashLink>
      </div>
    );

  }
}
