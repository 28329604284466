import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithFallbackTextureData',
  type: 'interface',
  description: `Implemented by AndroidWebView to provide an alternative to [IWebView.GetRawTextureData()](/webview/IWebView#GetRawTextureData) that works without the graphics extensions that 3D WebView normally requires on Android (the OpenGL [GL_OES_EGL_image_external](https://registry.khronos.org/OpenGL/extensions/OES/OES_EGL_image_external.txt) extension or Vulkan [VK_ANDROID_external_memory_android_hardware_buffer](https://registry.khronos.org/vulkan/specs/1.3-extensions/man/html/VK_ANDROID_external_memory_android_hardware_buffer.html) extension).`,
  example: `await webViewPrefab.WaitUntilInitialized();
var webViewWithFallbackTextureData = webViewPrefab.WebView as IWithFallbackTextureData;
if (webViewWithFallbackTextureData == null) {
    Debug.Log("This 3D WebView plugin doesn't yet support IWithFallbackTextureData: " + webViewPrefab.WebView.PluginType);
    return;
}
var textureData = await webViewWithFallbackTextureData.GetFallbackTextureData();
var texture = new Texture2D(
    webViewPrefab.WebView.Size.x,
    webViewPrefab.WebView.Size.y,
    TextureFormat.RGBA32,
    false,
    false
);
texture.LoadRawTextureData(textureData);
texture.Apply();`,
  members: [
    {
      name: 'GetFallbackTextureData',
      type: 'method',
      returns: 'Task<byte[]>',
      description: `Like [IWebView.GetRawTextureData()](/webview/IWebView#GetRawTextureData), except it has the following differences:
- It works without the graphics extensions that 3D WebView normally requires on Android
(the OpenGL [GL_OES_EGL_image_external](https://registry.khronos.org/OpenGL/extensions/OES/OES_EGL_image_external.txt) extension or Vulkan [VK_ANDROID_external_memory_android_hardware_buffer](https://registry.khronos.org/vulkan/specs/1.3-extensions/man/html/VK_ANDROID_external_memory_android_hardware_buffer.html) extension).
- It doesn't capture hardware accelerated content like video or WebGL.`,
    }
  ]

};
export default definition;
