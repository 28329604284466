import React, { Component } from 'react';
import WebViewProductDescription from '../../web-common/components/WebViewProductDescription';
import StoreLinks from '../../web-common/components/StoreLinks';
import demoVideo from './assets/webview-demo.mp4';
import './styles.scss';
import DefaultHead from '../DefaultHead';

export default class WebViewOverviewPage extends Component {

  state = {
    showAndroidDropdown: false
  };

  render() {
    return (
      <div className="webview-overview">
        <DefaultHead/>
        <h1><strong>3D WebView</strong>: the ultimate cross-platform web browser for <a style={{ textDecoration: 'underline' }} href="https://unity.com/products/unity-engine">Unity</a></h1>
        <h2 className="store-header">Buy from the store:</h2>
        <StoreLinks/>
        <video src={demoVideo} autoPlay={true} muted={true} loop={true} playsInline={true}/>
        <WebViewProductDescription
          omitDeveloperSiteReference={true}
          native2DMode="for Android, iOS, UWP, and WebGL"
          includeCustomersSection={true}
        />
      </div>
    );
  }
}
