import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithNative2DMode',
  type: 'interface',
  description: `An interface implemented by a webview if it supports [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode), which makes it so that 3D WebView positions a native 2D webview in front of the Unity game view instead of displaying web content as a texture in the Unity scene.`,
  seeAlso: [
    '[CanvasWebViewPrefab.Native2DModeEnabled](/webview/CanvasWebViewPrefab#Native2DModeEnabled)',
  ],
  members: [
    {
      name: 'Native2DModeEnabled',
      type: 'property',
      propertyAccessors: { get: true },
      returns: 'bool',
      description: 'Gets a value indicating whether the webview is running in Native 2D Mode.',
      example: `await canvasWebViewPrefab.WaitUntilInitialized();
var native2DWebView = canvasWebViewPrefab.WebView as IWithNative2DMode;
Debug.Log("Native 2D Mode enabled: " + native2DWebView?.Native2DModeEnabled);`
    },
    {
      name: 'Rect',
      type: 'property',
      propertyAccessors: { get: true },
      returns: 'Rect',
      description: `Gets the native 2D webview's rect on the screen, in pixels.`,
      seeAlso: [
        '[IWithNative2DMode.SetRect](#SetRect)',
      ]
    },
    {
      name: 'Visible',
      type: 'property',
      propertyAccessors: { get: true },
      returns: 'bool',
      description: `Gets a value indicating whether the native 2D webview is visible. The default is \`true\`.`,
      seeAlso: [
        '[CanvasWebViewPrefab.Visible](/webview/CanvasWebViewPrefab#Visible)',
        '[IWithNative2DMode.SetVisible](#SetVisible)',
      ]
    },
    {
      name: 'BringToFront',
      type: 'method',
      returns: 'void',
      description: `Brings the native webview to the front of the view hierarchy.  A webview is automatically placed in the front when it's created, but this method can be used to control which webview is in front if your scene contains multiple 2D webviews.`,
      example: `await canvasWebViewPrefab.WaitUntilInitialized();
var native2DWebView = canvasWebViewPrefab.WebView as IWithNative2DMode;
native2DWebView?.BringToFront();`,
      warnings: ['This method is currently not supported on UWP.']
    },
    {
      name: 'InitInNative2DMode',
      type: 'method',
      returns: 'Task',
      description: `Initializes the webview in Native 2D Mode. This method is to be used instead of [IWebView.Init()](/webview/IWebView#Init) for initialization.`,
      arguments: [
        {
          name: 'rect',
          type: 'Rect'
        }
      ]
    },
    {
      name: 'SetNativeZoomEnabled',
      type: 'method',
      returns: 'void',
      description: "Sets whether the native 2D webview's pinch-to-zoom behavior is enabled. The default is `true`.",
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      example: `await canvasWebViewPrefab.WaitUntilInitialized();
var native2DWebView = canvasWebViewPrefab.WebView as IWithNative2DMode;
native2DWebView?.SetNativeZoomEnabled(false);`
    },
    {
      name: 'SetRect',
      type: 'method',
      returns: 'void',
      description: `Sets the native 2D webview's rect on the screen, in pixels.`,
      arguments: [
        {
          name: 'rect',
          type: 'Rect'
        }
      ],
      seeAlso: [
        '[IWithNative2DMode.Rect](#Rect)',
      ]
    },
    {
      name: 'SetVisible',
      type: 'method',
      returns: 'void',
      description: `Sets the native 2D webview's rect on the screen, in pixels.`,
      arguments: [
        {
          name: 'visible',
          type: 'bool'
        }
      ],
      seeAlso: [
        '[CanvasWebViewPrefab.Visible](/webview/CanvasWebViewPrefab#Visible)',
        '[IWithNative2DMode.SetVisible](#SetVisible)',
      ]
    },
  ]

};
export default definition;
