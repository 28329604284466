import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'DownloadChangedEventArgs',
  type: 'class',
  description: 'Event args for [IWithDownloads.DownloadProgressChanged](/webview/IWithDownloads#DownloadProgressChanged).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'ContentType',
      returns: 'string?',
      type: 'property',
      description: 'The mime type indicated by the Content-Type response header, or `null` if no content type was specified.'
    },
    {
      name: 'FilePath',
      returns: 'string',
      type: 'property',
      description: 'The full file path of the downloaded file. Files are downloaded to [Application.temporaryCachePath](https://docs.unity3d.com/ScriptReference/Application-temporaryCachePath.html), but you can move them to a different location after they finish downloading.'
    },
    {
      name: 'Id',
      returns: 'string',
      type: 'property',
      description: "An identifier for the file, which can be used to track the file's download progress across multiple invocations of the DownloadProgressChanged event."
    },
    {
      name: 'Progress',
      returns: 'float',
      type: 'property',
      description: 'The estimated download progress, normalized to a float between 0 and 1. Note that not all platforms support intermediate progress updates.'
    },
    {
      name: 'Type',
      returns: 'ProgressChangeType',
      type: 'property',
      description: 'The download progress event type. Note that not all platforms support the [Updated](/webview/ProgressChangeType#Updated) event type.'
    },
    {
      name: 'Url',
      returns: 'string',
      type: 'property',
      description: 'The URL from which the file was downloaded.'
    },
  ]
};
export default definition;

