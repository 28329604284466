import React from 'react';
import { render } from 'react-snapshot';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
import cs from 'highlight.js/lib/languages/csharp';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
// the monokai-sublime theme is also good
import '../node_modules/highlight.js/styles/mono-blue.css';
import './index.scss';

const app = process.env.REACT_APP_LOCAL_DOCS ? <HashRouter><App/></HashRouter> : <BrowserRouter><App/></BrowserRouter>

render(app, document.getElementById('root'));

smoothscroll.polyfill();
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('cs', cs);
hljs.initHighlightingOnLoad();
hljs.configure({ languages: ['javascript', 'cs'] });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

