import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithKeyDownAndUp',
  type: 'interface',
  description: "An interface implemented by a webview if it supports separate `KeyDown()` and `KeyUp()` methods.",
  example: `// Dispatch ctrl + shift + right arrow
await webViewPrefab.WaitUntilInitialized();
var webViewWithKeyDownAndUp = webViewPrefab.WebView as IWithKeyDownAndUp;
if (webViewWithKeyDownAndUp != null) {
    webViewWithKeyDownAndUp.KeyDown("ArrowRight", KeyModifier.Control | KeyModifier.Shift);
    webViewWithKeyDownAndUp.KeyUp("ArrowRight", KeyModifier.Control | KeyModifier.Shift);
}`,
  members: [
    {
      name: 'KeyDown',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'key'
        },
        {
          type: 'KeyModifier',
          name: 'modifiers'
        },
      ],
      description: 'Dispatches a key down event to the webview.\n\nA key can either be a single character representing a unicode character (e.g. "A", "b", "?") or a [JavaScript Key value](https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values) (e.g. "ArrowUp", "Enter").'
    },
    {
      name: 'KeyUp',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'key'
        },
        {
          type: 'KeyModifier',
          name: 'modifiers'
        },
      ],
      description: 'Dispatches a key up event to the webview.'
    }
  ]
};
export default definition;
