import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithCursorType',
  type: 'interface',
  description: 'An interface implemented by a webview if it supports indicating when the mouse cursor icon should change.',
  seeAlso: '[WebViewPrefab.CursorIconsEnabled](/webview/WebViewPrefab#CursorIconsEnabled)',
  members: [
    {
      name: 'CursorTypeChanged',
      type: 'event',
      returns: 'EventHandler<EventArgs<string>>',
      description: 'Indicates that the mouse cursor icon should change. The EventArgs.Value is a string indicating the [CSS cursor type value](https://developer.mozilla.org/en-US/docs/Web/CSS/cursor) (e.g. `"default"`, `"pointer"`, `"wait"`, etc.).',
      example: `await webViewPrefab.WaitUntilInitialized();
var webViewWithCursorType = webViewPrefab.WebView as IWithCursorType;
webViewWithCursorType.CursorTypeChanged += (sender, eventArgs) => {
    // Note: Here's where the application could use Unity's Cursor.SetCursor() API to set
    //       a custom cursor icon depending on the cursor type indicated by eventArgs.Value.
    Debug.Log("Cursor type changed: " + eventArgs.Value);
};`
    },
  ]

};
export default definition;
