import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'EventArgs',
  type: 'class',
  description: 'A generic version of [System.EventArgs](https://docs.microsoft.com/en-us/dotnet/api/system.eventargs).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Value',
      returns: 'T',
      type: 'property',
      description: 'The wrapped value.'
    }
  ]
};
export default definition;

