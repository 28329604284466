import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'StandalonePdfOptions',
  type: 'class',
  description: "Optional PDF formatting settings that can be passed to [StandaloneWebView.CreatePdf()](/webview/StandaloneWebView#CreatePdf).",
  members: [
    {
      name: 'Landscape',
      returns: 'bool',
      type: 'property',
      description: "Set to true for landscape mode or false for portrait mode. The default is false."
    },
    {
      name: 'MarginBottom',
      returns: 'float',
      type: 'property',
      description: "The bottom margin in inches. Only used if [MarginType](#MarginType) is set to [StandalonePdfMarginType.Custom](/webview/StandalonePdfMarginType#Custom)."
    },
    {
      name: 'MarginLeft',
      returns: 'float',
      type: 'property',
      description: "The left margin in inches. Only used if [MarginType](#MarginType) is set to [StandalonePdfMarginType.Custom](/webview/StandalonePdfMarginType#Custom)."
    },
    {
      name: 'MarginRight',
      returns: 'float',
      type: 'property',
      description: "The right margin in inches. Only used if [MarginType](#MarginType) is set to [StandalonePdfMarginType.Custom](/webview/StandalonePdfMarginType#Custom)."
    },
    {
      name: 'MarginTop',
      returns: 'float',
      type: 'property',
      description: "The top margin in inches. Only used if [MarginType](#MarginType) is set to [StandalonePdfMarginType.Custom](/webview/StandalonePdfMarginType#Custom)."
    },
    {
      name: 'MarginType',
      returns: 'StandalonePdfMarginType',
      type: 'property',
      description: "The margin type. The default is [StandalonePdfMarginType.Default](/webview/StandalonePdfMarginType#Default)."
    },
    {
      name: 'PageRanges',
      returns: 'string',
      type: 'property',
      description: `Paper ranges to print, one based, e.g., "1-5, 8, 11-13". Pages are printed in the document order, not in the order specified, and no more than once. Defaults to empty string, which implies the entire document is printed. The page numbers are quietly capped to actual page count of the document, and ranges beyond the end of the document are ignored. If this results in no pages to print, an error is reported. It is an error to specify a range with start greater than end.`
    },
    {
      name: 'PaperHeight',
      returns: 'float',
      type: 'property',
      description: "Output paper height in inches. If either PaperWidth or PaperHeight is less than or equal to zero, then the default paper size (letter, 8.5 x 11 inches) will be used."
    },
    {
      name: 'PaperWidth',
      returns: 'float',
      type: 'property',
      description: "Output paper width in inches. If either PaperWidth or PaperHeight is less than or equal to zero, then the default paper size (letter, 8.5 x 11 inches) will be used."
    },
    {
      name: 'PreferCssPageSize',
      returns: 'bool',
      type: 'property',
      description: "Set to true to prefer page size as defined by CSS. Defaults to false, in which case the content will be scaled to fit the paper size."
    },
    {
      name: 'PrintBackground',
      returns: 'bool',
      type: 'property',
      description: "Set to true to print background graphics. The default is false."
    },
    {
      name: 'Scale',
      returns: 'float',
      type: 'property',
      description: "The percentage to scale the PDF by before printing (e.g. .5 is 50%). If this value is less than or equal to zero the default value of 1.0 will be used."
    },
  ]
};
export default definition;
