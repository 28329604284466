import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'FileSelectionEventArgs',
  type: 'class',
  description: 'Event args for [`FileSelectionRequested`](/webview/IWithFileSelection#FileSelectionRequested). To handle file selection, the application must either call [`Continue()`](#Continue) to select files or call [`Cancel()`](#Cancel) to cancel file selection.',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'AcceptFilters',
      returns: 'string[]',
      type: 'property',
      description: "Filters provided by the page to specify the allowed file types. If the page didn't specify any file types, then this array is empty.",
      seeAlso: "[MDN's documentation for the file input `accept` attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept)."
    },
    {
      name: 'MultipleAllowed',
      returns: 'bool',
      type: 'property',
      description: 'Filters provided by the page to specify the allowed file types.',
      seeAlso: "[MDN's documentation for the file input `multiple` attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#multiple)."
    },
    {
      name: 'Continue',
      returns: 'void',
      type: 'method',
      description: 'To select files, call this callback with an array of one or more absolute file paths.',
      arguments: [
        {
          name: 'filePaths',
          type: 'string[]'
        }
      ]
    },
    {
      name: 'Cancel',
      returns: 'void',
      type: 'method',
      description: 'Call this callback to cancel file selection.'
    },
  ]
}
export default definition;
