import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'UrlChangedEventArgs',
  type: 'class',
  description: 'Event args for [UrlChanged](/webview/IWebView#UrlChanged).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Url',
      returns: 'string',
      type: 'property',
      description: 'The new webpage URL.'
    },
    {
      name: 'Type',
      returns: 'string',
      type: 'property',
      description: 'Indicates the type of URL change (`"LOAD"`, `"PUSH_STATE"`, `"REPLACE_STATE"`, or `"HASH_CHANGE"`).'
    }
  ]
};
export default definition;
