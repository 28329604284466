import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithFallbackVideo',
  type: 'interface',
  description: `Interface used on iOS for the [fallback video implementation](https://support.vuplex.com/articles/fallback-video).`,
  members: [
    {
      name: 'FallbackVideoEnabled',
      type: 'property',
      returns: 'bool',
      propertyAccessors: { get: true },
      description: 'Indicates whether the fallback video implementation is enabled.'
    },
    {
      name: 'VideoTexture',
      type: 'property',
      returns: 'Texture2D?',
      propertyAccessors: { get: true },
      description: 'The video texture used for the fallback video implementation, or `null` if the fallback video implementation is not enabled.'
    },
    {
      name: 'VideoRectChanged',
      type: 'event',
      returns: 'EventHandler<EventArgs<Rect>>',
      description: 'Indicates that the rect of the playing video changed.'
    },
    {
      name: 'CreateVideoMaterial',
      type: 'method',
      returns: 'Material',
      description: 'Returns a Material that can be used for displaying the [VideoTexture](#VideoTexture).'
    },
    {
      name: 'SetFallbackVideoEnabled',
      type: 'method',
      returns: 'void',
      description: 'Sets whether the fallback video implementation is enabled. The default is `false`.',
      arguments: [{ name: 'enabled', type: 'bool' }]
    },
  ]
};
export default definition;
