import FileReferenceDefinition from './FileReferenceDefinition';
import AndroidWebView from './file-references/AndroidWebView-definition';
import AndroidGeckoWebView from './file-references/AndroidGeckoWebView-definition';
import AuthRequestedEventArgs from './file-references/AuthRequestedEventArgs-definition';
import CanvasKeyboard from './file-references/CanvasKeyboard-definition';
import CanvasWebViewPrefab from './file-references/CanvasWebViewPrefab-definition';
import ChromiumLogLevel from './file-references/ChromiumLogLevel-definition';
import ClickedEventArgs from './file-references/ClickedEventArgs-definition';
import ConsoleMessageEventArgs from './file-references/ConsoleMessageEventArgs-definition';
import ConsoleMessageLevel from './file-references/ConsoleMessageLevel-definition';
import Cookie from './file-references/Cookie-definition';
import DownloadChangedEventArgs from './file-references/DownloadChangedEventArgs-definition';
import DragMode from './file-references/DragMode-definition';
import EventArgs from './file-references/EventArgs-definition';
import FileSelectionEventArgs from './file-references/FileSelectionEventArgs-definition';
import FindResult from './file-references/FindResult-definition';
import FocusedInputFieldChangedEventArgs from './file-references/FocusedInputFieldChangedEventArgs-definition';
import FocusedInputFieldType from './file-references/FocusedInputFieldType-definition';
import ForceDark from './file-references/ForceDark-definition';
import iOSWebView from './file-references/iOSWebView-definition';
import ICookieManager from './file-references/ICookieManager-definition';
import IPointerInputDetector from './file-references/IPointerInputDetector-definition';
import IWebView from './file-references/IWebView-definition';
import IWithCursorType from './file-references/IWithCursorType-definition';
import IWithDeepLinking from './file-references/IWithDeepLinking-definition';
import IWithDownloads from './file-references/IWithDownloads-definition';
import IWithFallbackTextureData from './file-references/IWithFallbackTextureData-definition';
import IWithFallbackVideo from './file-references/IWithFallbackVideo-definition';
import IWithFileSelection from './file-references/IWithFileSelection-definition';
import IWithFind from './file-references/IWithFind-definition';
import IWithAuth from './file-references/IWithAuth-definition';
import IWithIme from './file-references/IWithIme-definition';
import IWithKeyDownAndUp from './file-references/IWithKeyDownAndUp-definition';
import IWithMovablePointer from './file-references/IWithMovablePointer-definition';
import IWithMutableAudio from './file-references/IWithMutableAudio-definition';
import IWithNative2DMode from './file-references/IWithNative2DMode-definition';
import IWithNativeJavaScriptDialogs from './file-references/IWithNativeJavaScriptDialogs-definition';
import IWithNativeOnScreenKeyboard from './file-references/IWithNativeOnScreenKeyboard-definition';
import IWithPdfCreation from './file-references/IWithPdfCreation-definition';
import IWithPixelDensity from './file-references/IWithPixelDensity-definition';
import IWithPointerDownAndUp from './file-references/IWithPointerDownAndUp-definition';
import IWithPopups from './file-references/IWithPopups-definition';
import IWithSettableUserAgent from './file-references/IWithSettableUserAgent-definition';
import IWithTouch from './file-references/IWithTouch-definition';
import KeyModifier from './file-references/KeyModifier-definition';
import Keyboard from './file-references/Keyboard-definition';
import LoadFailedEventArgs from './file-references/LoadFailedEventArgs-definition';
import MixedContentMode from './file-references/MixedContentMode-definition';
import MouseButton from './file-references/MouseButton-definition';
import PointerEventArgs from './file-references/PointerEventArgs-definition';
import PointerOptions from './file-references/PointerOptions-definition';
import PopupMode from './file-references/PopupMode-definition';
import PopupRequestedEventArgs from './file-references/PopupRequestedEventArgs-definition';
import ProgressChangedEventArgs from './file-references/ProgressChangedEventArgs-definition';
import ProgressChangeType from './file-references/ProgressChangeType-definition';
import ScriptDialogEventArgs from './file-references/ScriptDialogEventArgs-definition';
import ScrolledEventArgs from './file-references/ScrolledEventArgs-definition';
import StandaloneWebView from './file-references/StandaloneWebView-definition';
import StandaloneClientCertificateRequestedEventArgs from './file-references/StandaloneClientCertificateRequestedEventArgs-definition';
import StandaloneX509Certificate from './file-references/StandaloneX509Certificate-definition';
import StandaloneX509CertificatePrincipal from './file-references/StandaloneX509CertificatePrincipal-definition';
import StandalonePdfMarginType from './file-references/StandalonePdfMarginType-definition';
import StandalonePdfOptions from './file-references/StandalonePdfOptions-definition';
import TerminatedEventArgs from './file-references/TerminatedEventArgs-definition';
import TerminationType from './file-references/TerminationType-definition';
import TouchEvent from './file-references/TouchEvent-definition';
import TouchEventType from './file-references/TouchEventType-definition';
import UrlChangedEventArgs from './file-references/UrlChangedEventArgs-definition';
import UwpWebView from './file-references/UwpWebView-definition';
import ViewportMaterialView from './file-references/ViewportMaterialView-definition';
import VisionOSWebView from './file-references/VisionOSWebView-definition';
import VisionOSWindow from './file-references/VisionOSWindow-definition';
import VisionOSWindowOptions from './file-references/VisionOSWindowOptions-definition';
import VisionOSWindowPosition from './file-references/VisionOSWindowPosition-definition';
import VisionOSWindowPositionType from './file-references/VisionOSWindowPositionType-definition';
import WebGLWebView from './file-references/WebGLWebView-definition';
import WebPluginType from './file-references/WebPluginType-definition';
import WebViewOptions from './file-references/WebViewOptions-definition';
import WebViewPrefab from './file-references/WebViewPrefab-definition';
import Web from './file-references/Web-definition';

export default <FileReferenceDefinition[]> [
  AndroidWebView,
  AndroidGeckoWebView,
  AuthRequestedEventArgs,
  CanvasKeyboard,
  CanvasWebViewPrefab,
  ChromiumLogLevel,
  ClickedEventArgs,
  ConsoleMessageEventArgs,
  ConsoleMessageLevel,
  Cookie,
  DownloadChangedEventArgs,
  DragMode,
  EventArgs,
  FileSelectionEventArgs,
  FindResult,
  FocusedInputFieldChangedEventArgs,
  FocusedInputFieldType,
  ForceDark,
  iOSWebView,
  ICookieManager,
  IPointerInputDetector,
  IWebView,
  IWithCursorType,
  IWithDeepLinking,
  IWithDownloads,
  IWithFallbackTextureData,
  IWithFallbackVideo,
  IWithFind,
  IWithIme,
  IWithAuth,
  IWithFileSelection,
  IWithKeyDownAndUp,
  IWithMovablePointer,
  IWithMutableAudio,
  IWithNative2DMode,
  IWithNativeJavaScriptDialogs,
  IWithNativeOnScreenKeyboard,
  IWithPdfCreation,
  IWithPixelDensity,
  IWithPointerDownAndUp,
  IWithPopups,
  IWithSettableUserAgent,
  IWithTouch,
  KeyModifier,
  Keyboard,
  MixedContentMode,
  LoadFailedEventArgs,
  MouseButton,
  PointerEventArgs,
  PointerOptions,
  PopupMode,
  PopupRequestedEventArgs,
  ProgressChangedEventArgs,
  ProgressChangeType,
  ScriptDialogEventArgs,
  ScrolledEventArgs,
  StandalonePdfMarginType,
  StandalonePdfOptions,
  StandaloneWebView,
  StandaloneClientCertificateRequestedEventArgs,
  StandaloneX509Certificate,
  StandaloneX509CertificatePrincipal,
  TerminatedEventArgs,
  TerminationType,
  TouchEvent,
  TouchEventType,
  UrlChangedEventArgs,
  UwpWebView,
  ViewportMaterialView,
  VisionOSWebView,
  VisionOSWindow,
  VisionOSWindowOptions,
  VisionOSWindowPosition,
  VisionOSWindowPositionType,
  WebGLWebView,
  WebPluginType,
  WebViewOptions,
  WebViewPrefab,
  Web,
  // Unity external classes
  {
    name: 'AndroidJavaObject',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.unity3d.com/ScriptReference/AndroidJavaObject.html'
  },
  {
    name: 'Collider',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.unity3d.com/ScriptReference/Collider.html'
  },
  {
    name: 'Material',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.unity3d.com/ScriptReference/Material.html'
  },
  {
    name: 'MonoBehaviour',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.unity3d.com/ScriptReference/MonoBehaviour.html'
  },
  {
    name: 'Rect',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.unity3d.com/ScriptReference/Rect.html'
  },
  {
    name: 'Texture2D',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.unity3d.com/ScriptReference/Texture2D.html'
  },
  {
    name: 'Vector2',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.unity3d.com/ScriptReference/Vector2.html'
  },
  {
    name: 'Vector2Int',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.unity3d.com/ScriptReference/Vector2Int.html'
  },
  {
    name: 'Vector3',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.unity3d.com/ScriptReference/Vector3.html'
  },
  // .NET external classes
  {
    name: 'Action',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.microsoft.com/en-us/dotnet/api/system.action-1'
  },
  {
    name: 'DateTime',
    type: 'struct',
    description: '',
    externalLinkUrl: 'https://learn.microsoft.com/en-us/dotnet/api/system.datetime'
  },
  {
    name: 'Dictionary',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.microsoft.com/en-us/dotnet/api/system.collections.generic.dictionary-2'
  },
  {
    name: 'EventHandler',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.microsoft.com/en-us/dotnet/api/system.eventhandler-1'
  },
  {
    name: 'System.EventArgs',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.microsoft.com/en-us/dotnet/api/system.eventargs'
  },
  {
    name: 'IntPtr',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.microsoft.com/en-us/dotnet/api/system.intptr'
  },
  {
    name: 'List',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.microsoft.com/en-us/dotnet/api/system.collections.generic.list-1'
  },
  {
    name: 'Task',
    type: 'class',
    description: '',
    externalLinkUrl: 'https://docs.microsoft.com/en-us/dotnet/api/system.threading.tasks.task'
  },
];
