import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithTouch',
  type: 'interface',
  description: 'An interface implemented by a webview if it supports dispatching touch events.',
  members: [
    {
      name: 'SendTouchEvent',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'touchEvent',
          type: 'TouchEvent'
        }
      ],
      description: 'Dispatches a touch event (i.e. touchstart, touchend, touchmove, touchcancel) in the webview. This can be used, for example, to implement multitouch interactions.',
      example: `var webViewWithTouch = webViewPrefab.WebView as IWithTouch;
if (webViewWithTouch == null) {
    Debug.Log("This 3D WebView plugin doesn't yet support IWithTouch: " + webViewPrefab.WebView.PluginType);
    return;
}
// Start and end a first touch at (250px, 100px) in the web page.
var normalizedPoint1 = webViewPrefab.WebView.PointToNormalized(250, 100);
webViewWithTouch.SendTouchEvent(new TouchEvent {
    TouchID = 1,
    Point = normalizedPoint1,
    Type = TouchEventType.Start
});

webViewWithTouch.SendTouchEvent(new TouchEvent {
    TouchID = 1,
    Point = normalizedPoint1,
    Type = TouchEventType.End
});

// Start a second touch at (400px, 100px), move it to (410px, 100), and release it.
var normalizedPoint2 = webViewPrefab.WebView.PointToNormalized(400, 100);
webViewWithTouch.SendTouchEvent(new TouchEvent {
    TouchID = 2,
    Point = normalizedPoint2,
    Type = TouchEventType.Start
});

var normalizedPoint3 = webViewPrefab.WebView.PointToNormalized(410, 100);
webViewWithTouch.SendTouchEvent(new TouchEvent {
    TouchID = 2,
    Point = normalizedPoint3,
    Type = TouchEventType.Move
});

webViewWithTouch.SendTouchEvent(new TouchEvent {
    TouchID = 2,
    Point = normalizedPoint3,
    Type = TouchEventType.End
});`
    },
  ]

};
export default definition;
