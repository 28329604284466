import hljs from 'highlight.js';

export default function highlightCode(element: HTMLElement|null) {

  if (element) {
    const nodes = element.querySelectorAll('pre code');
    for (let i = 0; i < nodes.length; i++) {
      hljs.highlightBlock(nodes[i] as HTMLElement)
    }
  }
}
