import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'WebViewOptions',
  type: 'struct',
  description: "Options that can be passed to [WebViewPrefab.Instantiate()](/webview/WebViewPrefab#Instantiate) or [SetOptionsForInitialization()](/webview/WebViewPrefab#SetOptionsForInitialization) to alter the webview that the prefab creates during initialization.",
  members: [
    {
      name: 'clickWithoutStealingFocus',
      returns: 'bool',
      type: 'property',
      description: "If set to `true`, makes it so that clicking on the webview doesn't automatically focus it."
    },
    {
      name: 'disableVideo',
      returns: 'bool',
      type: 'property',
      description: "Sets whether the [fallback video implementation for iOS](https://support.vuplex.com/articles/fallback-video) is disabled. This option is ignored on non-iOS platforms or when [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode) is enabled."
    },
    {
      name: 'preferredPlugins',
      returns: 'WebPluginType[]',
      type: 'property',
      description: `
3D WebView automatically selects which native plugin to use based on the build platform and which plugins are installed in the project. However, if you have multiple plugins installed for a single platform, this option can be used to specify which plugin to use in order to override the default behavior.

Currently, Android is the only platform that supports multiple 3D WebView plugins: \`WebPluginType.Android\` and \`WebPluginType.AndroidGecko\`. If both plugins are installed in the same project, \`WebPluginType.AndroidGecko\` will be used by default. However, you can override this to force \`WebPluginType.Android\` to be used instead by specifying \`preferredPlugins = new WebPluginType[] { WebPluginType.Android }\`.
      `
    }
  ]
};
export default definition;
