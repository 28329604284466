import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'StandalonePdfMarginType',
  type: 'enum',
  description: 'Margin type enum for [StandalonePdfOptions.MarginType](/webview/StandalonePdfOptions#MarginType).',
  members: [
    {
      name: 'Default',
      type: 'value',
      description: 'Default margins of 1cm (~0.4 inches).'
    },
    {
      name: 'None',
      type: 'value',
      description: 'No margins.'
    },
    {
      name: 'Custom',
      type: 'value',
      description: 'Custom margins specified with [StandalonePdfOptions.MarginTop](/webview/StandalonePdfOptions#MarginTop), [MarginRight](/webview/StandalonePdfOptions#MarginRight), [MarginBottom](/webview/StandalonePdfOptions#MarginBottom), and [MarginLeft](/webview/StandalonePdfOptions#MarginLeft).'
    },
  ]
};
export default definition;
