import Stage from '../models/Stage';

declare var location: any;
declare var process: any;

export default class StageHelper {

  static isStaging() {

    // This goes before checking for a process object because Create React App exposes
    // a process.env object to client code.
    if (typeof location === 'object' && typeof location.host === 'string') {
      const { host } = location;
      // Exclude higher port numbers because react-snapshot uses higher port numbers,
      // and we don't want react-snapshot to take snapshots of staging pages.
      return (location.hostname === 'localhost' && location.port.length <= 4) ||
             host.includes('192.168') ||
             host.includes('staging');
    }

    if (typeof process === 'object' && process.env) {
      // This is not only used for the backend Node services but also
      // for prerendering the Gatsby front-ends with Node. For Gatsby,
      // GATSBY_STAGE must be used because Gatsby only exposes env vars
      // that have the prefix GATSBY_
      return (process.env.STAGE || process.env.GATSBY_STAGE) === Stage.Staging;
    }

    return false;
  }
}
