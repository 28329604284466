import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ScriptDialogEventArgs',
  type: 'class',
  description: 'Generic event args for script dialog events like `ScriptConfirmRequested`. The type parameter determines the data type that must be passed to the `Continue` callback to continue script execution.',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Message',
      returns: 'string',
      type: 'property',
      description: "The event's message."
    },
    {
      name: 'Continue',
      returns: 'Action<T>',
      type: 'property',
      description: "The callback to invoke to continue script execution."
    },
  ]
};
export default definition;
