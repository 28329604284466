import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ProgressChangedEventArgs',
  type: 'class',
  description: 'Event args for [LoadProgressChanged](/webview/IWebView#LoadProgressChanged).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Progress',
      returns: 'float',
      type: 'property',
      description: `The estimated load progress normalized to a float between 0 and 1.`
    },
    {
      name: 'Type',
      returns: 'ProgressChangeType',
      type: 'property',
      description: `The load progress event type.`
    },
  ]
};
export default definition;
