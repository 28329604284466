import React, { Component, ReactNode } from 'react';
import caretImage from '../../assets/caret.svg';
import './styles.scss';

interface CarouselProps {
  quantityVisible?: number;
  /** In seconds. */
  autoPlayInterval?: number;
}

export default class Carousel extends Component<CarouselProps> {

  state = {
    leftCustomerIndex: 0,
    containerWidth: 0,
    height: 0,
    carouselHasMoved: false
  };

  componentDidMount() {

    this._containerCheckIntervalId = setInterval(this._checkContainerDimensions, 500) as unknown as number;
    if (this.props.autoPlayInterval) {
      this._autoPlayIntervalId = setInterval(this._moveRight, this.props.autoPlayInterval * 1000) as unknown as number;
    }
  }

  componentWillUnmount() {

    clearInterval(this._containerCheckIntervalId);
    this._disableAutoPlayIfNeeded();
  }

  render() {
    return (
      <div className="carousel-component">
        <button onClick={this._handleLeftButtonClick}>
          <img src={caretImage} alt="caret pointing left"/>
        </button>
        <div style={{ height: this.state.height }}>
          <div ref={this._setContainerRect}>
            {this._getItems().map(this._renderItem)}
          </div>
        </div>
        <button onClick={this._handleRightButtonClick}>
          <img src={caretImage} alt="caret pointing right" />
        </button>
      </div>
    );
  }

  private _containerRef = null as HTMLDivElement|null;
  private _itemElements = [] as HTMLDivElement[];
  private _containerCheckIntervalId?: number;
  private _autoPlayIntervalId?: number;

  /**
   * Detects if the container's size changed.
   */
  private _checkContainerDimensions = () => {

    if (!this._containerRef) {
      return;
    }
    const { width } = this._containerRef.getBoundingClientRect();
    if (this.state.containerWidth !== width) {
      this.setState({ containerWidth: width });
    }
    this._updateHeightIfNeeded();
  }

  private _disableAutoPlayIfNeeded() {

    if (this._autoPlayIntervalId) {
      clearInterval(this._autoPlayIntervalId);
      this._autoPlayIntervalId = undefined;
    }
  }

  private _getItems() {

    const items = React.Children.toArray(this.props.children);
    // At least quantityVisible + 4 items are needed for this design to work,
    // so if there aren't that many, simply create copies as a workaround.
    while (items.length < (this._quantityVisible + 4)) {
      items.push(...React.Children.toArray(this.props.children));
    }
    return items;
  }

  private _handleLeftButtonClick = () => {

    this._disableAutoPlayIfNeeded();
    this.setState({
      leftCustomerIndex: this.state.leftCustomerIndex === 0 ? this._getItems().length - 1 : this.state.leftCustomerIndex - 1,
      carouselHasMoved: true
    });
  };

  private _handleRightButtonClick = () => {

    this._disableAutoPlayIfNeeded();
    this._moveRight();
  };

  private _moveRight = () => {
    this.setState({
      leftCustomerIndex: this.state.leftCustomerIndex === this._getItems().length - 1 ? 0 : this.state.leftCustomerIndex + 1,
      carouselHasMoved: true
    });
  }

  private _setContainerRect = (container: HTMLDivElement) => {

    this._containerRef = container;
    if (container) {
      const rect = container.getBoundingClientRect();
      this.setState({ containerWidth: rect.width });
    }
  };

  private _getItemStyle (index: number) {

    const numberOfItems = this._getItems().length;
    const { containerWidth, leftCustomerIndex } = this.state;
    const sectionWidth = this._quantityVisible === 1 ? 1 : (1 / this._quantityVisible) - 0.03;
    const width = containerWidth * sectionWidth
    const margin = this._quantityVisible === 1 ? 0 : (containerWidth - (width * this._quantityVisible)) / (this._quantityVisible - 1);
    // The indices of the customers visible in the carousel
    const visibleIndices = [] as number[];
    for (let i = 0; i < this._quantityVisible; i++) {
      let visibleIndex = leftCustomerIndex + i;
      if (visibleIndex > numberOfItems - 1) {
        visibleIndex = visibleIndex - numberOfItems;
      }
      visibleIndices.push(visibleIndex);
    }

    let left = 0;
    // The customer to the left that is ready to become active.
    const leftOnDeckIndex = leftCustomerIndex === 0 ? numberOfItems - 1 : leftCustomerIndex - 1;
    // Another customer on the left that is ready either go on-deck or switch over to the right side.
    const otherLeftIndex = leftOnDeckIndex === 0 ? numberOfItems - 1 : leftOnDeckIndex - 1;

    const rightCustomerIndex = visibleIndices[visibleIndices.length - 1];
    // The customer to the right that is ready to become active.
    const rightOnDeckIndex = rightCustomerIndex === numberOfItems - 1 ? 0 : rightCustomerIndex + 1;

    if (visibleIndices.includes(index)) {
      const indexInCarousel = visibleIndices.indexOf(index);
      // place the item in the carousel, in view
      left = indexInCarousel * (width + margin);
    } else if (index === leftOnDeckIndex || index === otherLeftIndex) {
      // place the item out of view on the left
      left = -width - margin;
    } else {
      // place the item out of view on the right
      left = containerWidth + margin;
    }

    const transitionShouldBeAnimated = visibleIndices.includes(index) || index === leftOnDeckIndex || index === rightOnDeckIndex;
    // Don't apply a transition if the carousel hasn't moved, because that causes
    // the transition to show when the page initially loads.
    const transition = transitionShouldBeAnimated && this.state.carouselHasMoved ? 'left 0.5s ease-in-out' : 'none';
    return {
      width,
      left,
      transition
    };
  }

  private get _quantityVisible() {

    return this.props.quantityVisible ? this.props.quantityVisible : 1;
  }

  private _renderItem = (item: ReactNode, index: number) => (
    <div ref={this._saveItemRef} className="carousel-component-item" key={index} style={this._getItemStyle(index)}>
      {item}
    </div>
  )

  private _saveItemRef = (itemElement) => {

    if (itemElement) {
      this._itemElements.push(itemElement)
      this._updateHeightIfNeeded();
    }
  };

  private _updateHeightIfNeeded() {

    const itemHeights = this._itemElements.map(e => e.offsetHeight);
    const targetHeight = Math.max(0, ...itemHeights);
    if (targetHeight !== this.state.height) {
      this.setState({ height: targetHeight });
    }
  }
}
