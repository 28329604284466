import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import './styles.scss';

export default class WarningBox extends Component<{ warnings: string[] }> {

  render() {

    const { warnings } = this.props;
    if (!(warnings && warnings.length)) {
      return;
    }

    if (warnings.length === 1) {
      return (
        <div className="warning-box">
          <h4>Important note</h4>
          <ReactMarkdown source={warnings[0]} />
        </div>
      );
    }
    return (
      <div className="warning-box">
        <h4>Important notes</h4>
        <ul>
          {warnings.map(warning => <li key={warning.substr(0, 10)}><ReactMarkdown source={warning} /></li>)}
        </ul>
      </div>
    )
  }
}
