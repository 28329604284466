import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithNativeJavaScriptDialogs',
  type: 'interface',
  description: 'An interface implemented by a webview if it supports showing native popups triggered by JavaScript dialog APIs like [window.alert()](https://developer.mozilla.org/en-US/docs/Web/API/Window/alert) and [confirm()](https://developer.mozilla.org/en-US/docs/Web/API/Window/confirm).',
  example: `await webViewPrefab.WaitUntilInitialized;
var webViewWithNativeDialogs = webViewPrefab.WebView as IWithNativeJavaScriptDialogs;
if (webViewWithNativeDialogs == null) {
    Debug.Log("This 3D WebView plugin doesn't yet support IWithNativeJavaScriptDialogs: " + webViewPrefab.WebView.PluginType);
    return;
}
webViewWithNativeDialogs.SetNativeJavaScriptDialogsEnabled(false);`,
  members: [
    {
      name: 'SetNativeJavaScriptDialogsEnabled',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      description: 'Native JavaScript dialog popups are enabled by default but can be disabled using this method.'
    },
  ]

};
export default definition;
