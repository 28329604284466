import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'FocusedInputFieldType',
  type: 'enum',
  description: 'Indicates the type of input field focused for [IWebView.FocusedInputFieldChanged](/webview/IWebView#FocusedInputFieldChanged).',
  members: [
    {
      name: 'Text',
      type: 'value',
      description: 'Indicates that a text input field is focused. Examples of a text input field include an [<input>](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input) element, a [<textarea>](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea) element, and an element with a [contenteditable](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/contenteditable) attribute.'
    },
    {
      name: 'None',
      type: 'value',
      description: 'Indicates that no input field is focused.'
    },
    {
      name: 'IFrame',
      type: 'value',
      description: "Indicates that an [<iframe>](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe) element is focused. This is its own type because the FocusedInputFieldChanged event's implementation is unable detect the type of element that is focused inside an <iframe>."
    },
  ]
};
export default definition;

