import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithPixelDensity',
  type: 'interface',
  description: "An interface implemented by a webview if it supports changing its pixel density, which is its number of physical pixels per logical pixel. The default pixel density is `1`, but increasing it to `2` can make web content appear sharper or less blurry on high DPI displays.",
  example: `await webViewPrefab.WaitUntilInitialized();
var webViewWithPixelDensity = webViewPrefab.WebView as IWithPixelDensity;
if (webViewWithPixelDensity == null) {
    Debug.Log("This 3D WebView plugin doesn't yet support IWithPixelDensity: " + webViewPrefab.WebView.PluginType);
} else {
    webViewWithPixelDensity.SetPixelDensity(2);
}`,
  seeAlso: '[WebViewPrefab.PixelDensity](/webview/WebViewPrefab#PixelDensity)',
  members: [
    {
      name: 'PixelDensity',
      returns: 'float',
      type: 'property',
      propertyAccessors: { get: true },
      description: 'Gets the current pixel density.'
    },
    {
      name: 'SetPixelDensity',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'pixelDensity',
          type: 'float'
        }
      ],
      description: 'Sets the pixel density. The value must be between `0` and `10`, and the default is `1`.'
    },
  ]

};
export default definition;
