import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ConsoleMessageLevel',
  type: 'enum',
  description: 'Levels for messages logged to the JavaScript console.',
  members: [
    {
      name: 'Debug',
      type: 'value',
      description: ''
    },
    {
      name: 'Error',
      type: 'value',
      description: ''
    },
    {
      name: 'Log',
      type: 'value',
      description: ''
    },
    {
      name: 'Warning',
      type: 'value',
      description: ''
    }
  ]
};
export default definition;

