import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithDeepLinking',
  type: 'interface',
  description: 'An interface implemented by a webview if it supports [deep linking](https://en.wikipedia.org/wiki/Mobile_deep_linking).',
  example: `await webViewPrefab.WaitUntilInitialized();
var webViewWithDeepLinking = webViewPrefab.WebView as IWithDeepLinking;
if (webViewWithDeepLinking == null) {
    Debug.Log("This 3D WebView plugin doesn't support IWithDeepLinking: " + webViewPrefab.WebView.PluginType);
    return;
}
webViewWithDeepLinking.SetDeepLinkingEnabled(true);
// Load a page with a link that opens the YouTube app.
webViewPrefab.WebView.LoadHtml("<a href='vnd.youtube://grP0iDrSjso'>Click to launch YouTube</a>");`,
  warnings: [
    `On iOS, in order to open a link with a custom URI scheme, that scheme must also be listed i the app's Info.plist using the key [LSApplicationQueriesSchemes](https://developer.apple.com/library/archive/documentation/General/Reference/InfoPlistKeyReference/Articles/LaunchServicesKeys.html) otherwise iOS will block the custom URI scheme from being loaded Example Info.plist entry:
\`\`\`
<key>LSApplicationQueriesSchemes</key>
<array>
    <string>vnd.youtube</string>
</array>
\`\`\`
`,
    "On Android, deep linking is only triggered by a URL with a custom scheme (e.g. my-app://path). A URL with an http:// or https:// scheme will always open the web page in the browser rather than deep link to an external app."
  ],
    members: [
    {
      name: 'SetDeepLinkingEnabled',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
        description: 'Sets whether deep links are enabled. The default is `false`.'
    },
  ]

};
export default definition;
