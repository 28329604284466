import React from 'react';
import HashLink from '../../components/HashLink';
import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'PopupRequestedEventArgs',
  type: 'class',
  description: 'Event args for [PopupRequested](/webview/IWithPopups#PopupRequested).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Url',
      returns: 'string',
      type: 'property',
      description: 'The URL for the popup.',
      warnings: [
        'Note that with 3D WebView for Android, the `Url` field may be empty in some cases when using `PopupMode.LoadInNewWebView`.'
      ]
    },
    {
      name: 'WebView',
      returns: 'IWebView',
      type: 'property',
      description: (
        <div>
          The new webview that was created for the popup if the webview's popup mode is set
          to <HashLink to="/webview/PopupMode#LoadInNewWebView"><code>PopupMode.LoadInNewWebView</code></HashLink>, or
          null if the popup mode is set to <HashLink to="/webview/PopupMode#NotifyWithoutLoading"><code>PopupMode.NotifyWithoutLoading</code></HashLink>.
        </div>
      )
    },
  ]
};
export default definition;
