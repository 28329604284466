
/**
 * Indicate that the action couldn't be completed because the user
 * isn't logged in and that the page is in the process of redirecting
 * to the login page.
 */
export default class LoginRedirectError extends Error {

  constructor(message='The user is not authenticated; navigating to login...') {
    super(message);
  }
}
