import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'TerminationType',
  type: 'enum',
  description: 'Termination types for the [IWebView.Terminated](/webview/IWebView#Terminated) event.',
  members: [
    {
      name: 'Crashed',
      type: 'value',
      description: 'Indicates that the web content process terminated because it crashed. This can happen, for example, due to a bug in the browser engine or due to the application running out of memory.'
    },
    {
      name: 'Killed',
      type: 'value',
      description: "Indicates that the web content process terminated because it was killed by the operating system. This can happen on Android because it terminates application processes when the device is low on memory or CPU resources."
    },
    {
      name: 'Unknown',
      type: 'value',
      description: "Indicates that the reason for the termination is unknown. This value is used on iOS because iOS's [webViewWebContentProcessDidTerminate](https://developer.apple.com/documentation/webkit/wknavigationdelegate/1455639-webviewwebcontentprocessdidtermi?language=objc) callback doesn't indicate the reason for the termination. The actual reason is either that the web content process crashed or that the operating system killed it due to resource constraints, but it's not known which of these is the case."
    },
  ]
};
export default definition;
