import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'PointerEventArgs',
  type: 'class',
  description: 'Event args to indicate a pointer event (i.e. mouse event).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Button',
      returns: 'MouseButton',
      type: 'property',
      description: "The button for the event. The default is `MouseButton.Left`."
    },
    {
      name: 'ClickCount',
      returns: 'int',
      type: 'property',
      description: 'The number of clicks for the event. For example, for a double click, set this value to `2`. The default is `1`.'
    },
    {
      name: 'Point',
      returns: 'Vector2',
      type: 'property',
      description: "The [normalized point](https://support.vuplex.com/articles/normalized-points) passed to [IWebView.Click()](/webview/IWebView#Click)."
    },
    {
      name: 'ToPointerOptions',
      returns: 'PointerOptions',
      type: 'method',
      description: 'Converts the event args into PointerOptions that can be passed to methods like [PointerUp()](/webview/IWithPointerDownAndUp#PointerUp) and [PointerDown()](/webview/IWithPointerDownAndUp#PointerDown).'
    }
  ]
};
export default definition;
