import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ForceDark',
  type: 'enum',
  description: `Values for [AndroidWebView.SetForceDark()](/webview/AndroidWebView#SetForceDark).`,
  members: [
    {
      name: 'Off',
      type: 'value',
      description: "Disable force dark, irrespective of the force dark mode of the webview parent. In this mode, webview content will always be rendered as-is, regardless of whether native views are being automatically darkened."
    },
    {
      name: 'Auto',
      type: 'value',
      description: "Enable force dark dependent on the state of the webview parent view. If the webview parent view is being automatically force darkened (see: [View.setForceDarkAllowed()](https://developer.android.com/reference/android/view/View#setForceDarkAllowed(boolean))), then webview content will be rendered so as to emulate a dark theme. Webviews that are not attached to the view hierarchy will not be inverted."
    },
    {
      name: 'On',
      type: 'value',
      description: "Unconditionally enable force dark. In this mode webview content will always be rendered so as to emulate a dark theme."
    }
  ]
};
export default definition;
