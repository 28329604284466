import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'LoadFailedEventArgs',
  type: 'class',
  description: 'Event args for [IWebView.LoadFailed](/webview/IWebView#LoadFailed).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'NativeErrorCode',
      returns: 'string',
      type: 'property',
      description: 'A platform-specific error code indicating why the load failed.'
    },
    {
      name: 'Url',
      returns: 'string',
      type: 'property',
      description: 'The URL for which the load failed.'
    }
  ]
};
export default definition;
