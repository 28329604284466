import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'Cookie',
  type: 'class',
  description: 'An HTTP [cookie](https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies).',
  members: [
    {
      name: 'Name',
      returns: 'string',
      type: 'property',
      description: 'The name of the cookie.'
    },
    {
      name: 'Value',
      returns: 'string',
      type: 'property',
      description: 'The value of the cookie.'
    },
    {
      name: 'Domain',
      returns: 'string',
      type: 'property',
      description: 'The domain to which the cookie belongs (e.g. "www.vuplex.com", "example.com").'
    },
    {
      name: 'Path',
      returns: 'string',
      type: 'property',
      description: 'The URL path of the cookie (e.g. "/", "/products/1234").'
    },
    {
      name: 'ExpirationDate',
      returns: 'int',
      type: 'property',
      description: 'A number representing the expiration date of the cookie as the number of seconds since the UNIX epoch, or 0 if there is no expiration date. An expiration date is not provided for session cookies.'
    },
    {
      name: 'HttpOnly',
      returns: 'bool',
      type: 'property',
      description: 'Indicates whether the cookie is marked as HttpOnly (i.e. the cookie is inaccessible to client-side scripts).'
    },
    {
      name: 'Secure',
      returns: 'bool',
      type: 'property',
      description: 'Indicates whether cookie is marked as secure (i.e. its scope is limited to secure channels, typically HTTPS).'
    },
    {
      name: 'IsValid',
      returns: 'bool',
      type: 'property',
      description: 'Indicates whether the cookie is valid.'
    },
  ]
};
export default definition;

