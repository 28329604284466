import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'WebPluginType',
  type: 'enum',
  description: '',
  members: [
    {
      name: 'Android',
      type: 'value',
      description: ''
    },
    {
      name: 'AndroidGecko',
      type: 'value',
      description: ''
    },
    {
      name: 'iOS',
      type: 'value',
      description: ''
    },
    {
      name: 'Mac',
      type: 'value',
      description: ''
    },
    {
      name: 'Mock',
      type: 'value',
      description: ''
    },
    {
      name: 'Windows',
      type: 'value',
      description: ''
    },
    {
      name: 'UniversalWindowsPlatform',
      type: 'value',
      description: ''
    },
  ]
};
export default definition;
