import React, { Component } from 'react';
import { Switch, Redirect, Route, Link, RouteComponentProps } from 'react-router-dom';
import Navbar from '../../web-common/components/Navbar';
import Sidebar from '../../web-common/components/Sidebar';
import fileReferenceDefinitions from '../../models/fileReferenceDefinitions';
import FileReferenceDefinition from '../../models/FileReferenceDefinition';
import AdditionalInterfacesPage from '../AdditionalInterfacesPage';
import DefaultHead from '../DefaultHead';
import WebViewOverviewPage from '../WebViewOverviewPage';
import GettingStartedPage from '../GettingStartedPage';
import FileReferencePage from '../FileReferencePage';
import ReleasesPage from '../ReleasesPage';
import ScrollToTop from '../ScrollToTop';
import TroubleshootingPage from '../TroubleshootingPage';
import './styles.scss';
import PlatformSpecificPage from '../PlatformSpecificPage';

const RedirectToWebViewOverview = () => <Redirect to="/webview/overview"/>;

const redirectToUrl = (url: string) => {
  return class extends Component<RouteComponentProps> {
    componentDidMount() {
      location.href = url;
    }
    render() { return null; }
  }
}

const additionalInterfaceNameMap = fileReferenceDefinitions.reduce((accumulator, reference) => {
  const { additionalInterfaces } = reference;
  if (additionalInterfaces) {
    for (const interfaceName of additionalInterfaces) {
      accumulator[interfaceName] = true;
    }
  }
  return accumulator;
}, {});
const additionalInterfaceNames = Object.keys(additionalInterfaceNameMap);
additionalInterfaceNames.sort();

export default class App extends Component {

  state = {
    mobileSidebarOpen: false
  }

  render() {

    const platformFileReferences = fileReferenceDefinitions.filter(f => f.sidebarSection === 'platform');

    return (
      <div className="app">
        <ScrollToTop/>
        <DefaultHead/>
        <Navbar
          siteName="developers"
          className="developers-navbar"
          mobileMenuButton={{ onClick: this._handleMenuClick, menuOpen: this.state.mobileSidebarOpen }}
          search={true}
          centerLinks={[
            <a key="store" href="https://store.vuplex.com">store</a>,
            <a key="support" href="https://support.vuplex.com">support</a>,
            <a key="blog" href="https://blog.vuplex.com">blog</a>,
          ]}
        />
        <div className="app-container">
          <Sidebar mobileSidebarOpen={this.state.mobileSidebarOpen} onMobileBackdropClick={this._handleMenuClick}>
            <Link to="/webview/overview" onClick={this._handleSidebarLinkClick}>Overview</Link>
            <Link to="/webview/getting-started" onClick={this._handleSidebarLinkClick}>Getting started</Link>
            <a href="https://support.vuplex.com" onClick={this._handleSidebarLinkClick}>Support articles / FAQ</a>
            <Link to="/webview/troubleshooting" onClick={this._handleSidebarLinkClick}>Troubleshooting</Link>
            <Link to="/webview/releases" onClick={this._handleSidebarLinkClick}>Release notes</Link>
            <div>
              <h2>API reference:</h2>
              <ul className="file-section-list">
                <li>
                  <h3>Core:</h3>
                  <ul>
                    <li><Link to="/webview/IWebView" onClick={this._handleSidebarLinkClick}>IWebView</Link></li>
                    <li><Link to="/webview/WebViewPrefab" onClick={this._handleSidebarLinkClick}>WebViewPrefab</Link></li>
                    <li><Link to="/webview/CanvasWebViewPrefab" onClick={this._handleSidebarLinkClick}>CanvasWebViewPrefab</Link></li>
                    <li><Link to="/webview/Web" onClick={this._handleSidebarLinkClick}>Web (static methods)</Link></li>
                    <li><Link to="/webview/Keyboard" onClick={this._handleSidebarLinkClick}>Keyboard</Link></li>
                    <li><Link to="/webview/CanvasKeyboard" onClick={this._handleSidebarLinkClick}>CanvasKeyboard</Link></li>
                    <li><Link to="/webview/additional-interfaces" onClick={this._handleSidebarLinkClick}>Advanced features</Link></li>
                  </ul>
                </li>
                <li>
                  <h3>Platform-specific:</h3>
                  <ul>
                    {platformFileReferences.map(this._renderFileReference)}
                  </ul>
                </li>
              </ul>
            </div>
            <a href="https://vuplex.com/contact" className="mobile-only" onClick={this._handleSidebarLinkClick}>Contact</a>
          </Sidebar>
          <div className="page-container">
            <Switch>
              <Route exact path="/" component={RedirectToWebViewOverview}/>
              <Route exact path="/webview" component={RedirectToWebViewOverview}/>
              <Route
                exact
                path="/webview/additional-interfaces"
                render={(props) => <AdditionalInterfacesPage additionalInterfaceNames={additionalInterfaceNames} {...props}/>} />
              <Route exact path="/webview/getting-started" component={GettingStartedPage} />
              <Route exact path="/webview/overview" component={WebViewOverviewPage} />
              <Route exact path="/webview/troubleshooting" component={TroubleshootingPage} />
              <Route exact path="/webview/releases" component={ReleasesPage} />
              <Route exact path="/webview/platform-specific" component={PlatformSpecificPage} />
              {fileReferenceDefinitions.filter(f => !f.externalLinkUrl).map(this._renderFileReferenceRoute)}
              {/*
                The last three routes were originally on vuplex.com, and now that vuplex.com redirects to
                developer.vuplex.com, we must redirect them to support.vuplex.com.
              */}
              <Route exact path="/commercial-library-license" component={redirectToUrl('https://support.vuplex.com/articles/commercial-library-license')} />
              <Route exact path="/contact" component={redirectToUrl('https://support.vuplex.com/contact')} />
              <Route exact path="/support" component={redirectToUrl('https://support.vuplex.com/contact')} />
              <Route exact path="/webview/android-comparison" component={redirectToUrl('https://support.vuplex.com/articles/android-comparison')} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }

  private _handleMenuClick = () => this.setState({ mobileSidebarOpen: !this.state.mobileSidebarOpen });

  private _handleSidebarLinkClick = () => {

    if (this.state.mobileSidebarOpen) {
      this.setState({ mobileSidebarOpen: false });
    }
  }

  private _renderFileReference = (fileReferenceDefinition: FileReferenceDefinition) => (
    <li key={fileReferenceDefinition.name}>
      <Link to={`/webview/${fileReferenceDefinition.name}`} onClick={this._handleSidebarLinkClick}>
        {fileReferenceDefinition.name}
      </Link>
    </li>
  );

  private _renderFileReferenceRoute = (fileReferenceDefinition) => {

    return (
      <Route key={fileReferenceDefinition.name}
             exact
             path={`/webview/${fileReferenceDefinition.name}`}
             render={(props) => <FileReferencePage fileReferenceDefinitions={fileReferenceDefinitions} {...props} {...fileReferenceDefinition}/>}/>
    );
  }
}
