import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'FocusedInputFieldChangedEventArgs',
  type: 'class',
  description: 'Event args for [FocusedInputFieldChanged](/webview/IWebView#FocusedInputFieldChanged).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Type',
      returns: 'FocusedInputFieldType',
      type: 'property',
      description: 'The type of input field focused.'
    }
  ]
};
export default definition;

