import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ICookieManager',
  type: 'interface',
  plainTextDescription: 'Provides methods for getting, setting, and deleting HTTP cookies. You can access the ICookieManager via Web.CookieManager.',
  description: `Provides methods for getting, setting, and deleting [HTTP cookies](https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies). You can access the ICookieManager via [Web.CookieManager](/webview/Web#CookieManager).

When developing code that interacts with cookies, it may also be helpful to view a webview's cookies using [remote debugging](https://support.vuplex.com/articles/how-to-debug-web-content).`,
  members: [
    {
      name: 'DeleteCookies',
      returns: 'Task<bool>',
      type: 'method',
      arguments: [
        {
          name: 'url',
          type: 'string'
        },
        {
          name: 'cookieName',
          type: 'string',
          defaultValue: 'null'
        },
      ],
      description: `Deletes all of the cookies that match the given URL and returns a
Task&lt;bool&gt; indicating whether the deletion succeeded. A \`cookieName\`
can be optionally passed as a second parameter to further filter
to a specific cookie.
If a deletion fails, it could be because the URL was invalid.
For more details regarding a failure, check the Unity logs.`,
      example: `if (Web.CookieManager == null) {
    Debug.Log("Web.CookieManager isn't supported on this platform.");
    return;
}
// Delete all the cookies for this cookie test page, which will reset the test.
var succeeded = await Web.CookieManager.DeleteCookies("http://www.whatarecookies.com/cookietest.asp");
Debug.Log("Cookie deletion succeeded: " + succeeded);`,
      warnings: [
        'On Windows and macOS, if this method is called without a `cookieName` it only deletes cookies that were set without an explicit Domain attribute.',
        'On versions of iOS older than iOS 11, session cookies are excluded because WKHTTPCookieStore is only supported in iOS 11 and newer.'
      ],
    },
    {
      name: 'GetCookies',
      returns: 'Task<Cookie[]>',
      type: 'method',
      arguments: [
        {
          name: 'url',
          type: 'string'
        },
        {
          name: 'cookieName',
          type: 'string',
          defaultValue: 'null'
        },
      ],
      description: 'Gets all of the cookies that match the given URL. A `cookieName` can be optionally passed as a second parameter to further filter results to a specific cookie.',
      example: `if (Web.CookieManager == null) {
    Debug.Log("Web.CookieManager isn't supported on this platform.");
    return;
}
// Get the cookie named "NID" set by google.com.
var cookies = await Web.CookieManager.GetCookies("https://www.google.com", "NID");
if (cookies.Length > 0) {
    Debug.Log("Cookie: " + cookies[0]);
} else {
    Debug.Log("Cookie not found.");
}`,
      warnings: [
        "On Android, the cookies returned only have their Name and Value fields set. The other fields (e.g. Domain, Path) are set to their default values because Android doesn't provide a way to access those values.",
        'On versions of iOS older than iOS 11, session cookies are excluded because WKHTTPCookieStore is only supported in iOS 11 and newer.'
      ],
    },
    {
      name: 'SetCookie',
      returns: 'Task<bool>',
      type: 'method',
      arguments: [
        {
          name: 'cookie',
          type: 'Cookie'
        },
      ],
      description: 'Sets the given cookie and returns a Task&lt;bool&gt; indicating whether the cookie was set successfully. If setting the cookie fails, it could be because the data in the provided Cookie was malformed. For more details regarding a failure, please check the Unity logs.',
      example: `if (Web.CookieManager == null) {
    Debug.Log("Web.CookieManager isn't supported on this platform.");
    return;
}
var success = await Web.CookieManager.SetCookie(new Cookie {
    Domain = "vuplex.com",
    Path = "/",
    Name = "example_name",
    Value = "example_value",
    Secure = true,
    // Expire one day from now
    ExpirationDate = (int)DateTimeOffset.Now.ToUnixTimeSeconds() + 60 * 60 * 24
});`,
    },
  ]
};
export default definition;
