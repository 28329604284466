import React from 'react';
import { Link } from 'react-router-dom';
import FileReferenceDefinition from '../models/FileReferenceDefinition';

export default function getFileReferenceLink(fileReferenceDefinitions: FileReferenceDefinition[], typeName: string, linkText?: string) {

  const matchingFileReference = fileReferenceDefinitions.find(file => file.name === typeName);
  if (matchingFileReference) {
    if (matchingFileReference.externalLinkUrl) {
      return <a key={typeName} href={matchingFileReference.externalLinkUrl}>{linkText || typeName}</a>;
    }
    return <Link key={typeName} to={`/webview/${typeName}`}>{linkText || typeName}</Link>;
  }
  return null;
}
