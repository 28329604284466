import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'KeyModifier',
  type: 'enum',

  description: 'Flags that indicate modifier keys for [IWithKeyDownAndUp](/webview/IWithKeyDownAndUp).',
  members: [
    {
      name: 'None',
      type: 'value',
      description: ''
    },
    {
      name: 'Shift',
      type: 'value',
      description: ''
    },
    {
      name: 'Control',
      type: 'value',
      description: ''
    },
    {
      name: 'Alt',
      type: 'value',
      description: ''
    },
    {
      name: 'Meta',
      type: 'value',
      description: ''
    }
  ]
};
export default definition;
