import Products from '../../web-common/models/products/Products';
import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'iOSWebView',
  type: 'class',
  description: `iOSWebView is the [IWebView](/webview/IWebView) implementation used by ${Products.iOS.markdownLink}. It also includes additional APIs for iOS-specific functionality.`,
  inheritsFrom: 'IWebView',
  additionalInterfaces: [
    'IWithDeepLinking',
    'IWithDownloads',
    'IWithFallbackVideo',
    'IWithMovablePointer',
    'IWithNative2DMode',
    'IWithNativeJavaScriptDialogs',
    'IWithNativeOnScreenKeyboard',
    'IWithPdfCreation',
    'IWithPointerDownAndUp',
    'IWithPopups',
    'IWithSettableUserAgent'
  ],
  sidebarSection: 'platform',
  members: [
    {
      name: 'GetNativeWebView',
      type: 'method',
      returns: 'IntPtr',
      description: "Returns a pointer to the instance's native Objective-C [WKWebView](https://developer.apple.com/documentation/webkit/wkwebview?language=objc).",
      warnings: ["Adding code that interacts with the native WKWebView directly may interfere with 3D WebView's functionality and vice versa. So, it's highly recommended to stick to 3D WebView's C# APIs whenever possible and only use GetNativeWebView() if truly necessary. If 3D WebView is missing an API that you need, feel free to [contact us](https://vuplex.com/contact)."],
      example: [
        {
          title: 'Objective-C Example',
          code: `// Example of defining a native Objective-C function that sets WKWebView.allowsLinkPreview.
// Place this in a .m file in your project, like Assets/Plugins/WebViewCustom.m
#import <Foundation/Foundation.h>
#import <WebKit/WebKit.h>

void WebViewCustom_SetAllowsLinkPreview(WKWebView *webView, BOOL allowsLinkPreview) {

    webView.allowsLinkPreview = allowsLinkPreview;
}`
        },
        {
          title: 'C# Example',
          code: `// Example of calling the native Objective-C function from C#.
async void EnableLinkPreviews(WebViewPrefab webViewPrefab) {

    await webViewPrefab.WaitUntilInitialized();
    #if UNITY_IOS && !UNITY_EDITOR
        var wkWebViewPtr = (webViewPrefab.WebView as iOSWebView).GetNativeWebView();
        WebViewCustom_SetAllowsLinkPreview(wkWebViewPtr, true);
    #endif
}

[System.Runtime.InteropServices.DllImport("__Internal")]
static extern void WebViewCustom_SetAllowsLinkPreview(System.IntPtr webViewPtr, bool allowsLinkPreview);`
        }
      ]
    },
    {
      name: 'SetAllowsBackForwardNavigationGestures',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'allow',
          type: 'bool'
        }
      ],
      description: "Sets whether horizontal swipe gestures trigger backward and forward page navigation. The default is `false`.",
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_IOS && !UNITY_EDITOR
    var iOSWebViewInstance = webViewPrefab.Webview as iOSWebView;
    iOSWebViewInstance.SetAllowsBackForwardNavigationGestures(true);
#endif`,
      seeAlso: '[WKWebView.allowsBackForwardNavigationGestures](https://developer.apple.com/documentation/webkit/wkwebview/1414995-allowsbackforwardnavigationgestu)'
    },
    {
      name: 'SetAllowsInlineMediaPlayback',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          name: 'allow',
          type: 'bool'
        }
      ],
      description: "Sets whether HTML5 videos play inline or use the native full-screen controller. The default is `true`. This method is static because the WKWebView's configuration cannot be modified at runtime after the webview is created.",
      example: `#if UNITY_IOS && !UNITY_EDITOR
    iOSWebView.SetAllowsInlineMediaPlayback(false);
#endif`,
      seeAlso: '[WKWebViewConfiguration.allowsInlineMediaPlayback](https://developer.apple.com/documentation/webkit/wkwebviewconfiguration/1614793-allowsinlinemediaplayback)'
    },
    {
      name: 'SetCameraEnabled',
      returns: 'void',
      static: true,
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "Like [Web.SetCameraAndMicrophoneEnabled()](/webview/Web#SetCameraAndMicrophoneEnabled), but enables only the camera without enabling the microphone. In addition to calling this method, you must also complete the additional steps described [here](https://support.vuplex.com/articles/webrtc#ios) in order to successfully enable the camera.",
      example: `void Awake() {
    #if UNITY_IOS && !UNITY_EDITOR
        iOSWebView.SetCameraEnabled(true);
    #endif
}`
    },
    {
      name: 'SetFullscreenEnabled',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "When [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode) is enabled, this method sets whether web pages can use the [JavaScript Fullscreen API](https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API) to make an HTML element occupy the device's entire screen. The default is `true`, meaning that the JavaScript Fullscreen API is enabled by default. When Native 2D Mode is disabled, this method has no effect because the JavaScript Fullscreen API is only supported in Native 2D Mode.",
      example: `#if UNITY_IOS && !UNITY_EDITOR
    await canvasWebViewPrefab.WaitUntilInitialized();
    var iOSWebViewInstance = canvasWebViewPrefab.WebView as iOSWebView;
    // Disable the JavaScript Fullscreen API.
    iOSWebViewInstance.SetFullscreenEnabled(false);
#endif`,
      seeAlso: '[Fullscreen support in 3D WebView](https://support.vuplex.com/articles/fullscreen)'
    },
    {
      name: 'SetLongPressGesturesEnabled',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      description: "When [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode) is enabled, this method sets whether long press gestures are enabled. The default is `true`. When Native 2D Mode is not enabled, this method has no effect.",
      example: `await canvasWebViewPrefab.WaitUntilInitialized();
#if UNITY_IOS && !UNITY_EDITOR
    var iOSWebViewInstance = canvasWebViewPrefab.WebView as iOSWebView;
    iOSWebViewInstance.SetLongPressGesturesEnabled(false);
#endif`,
    },
    {
      name: 'SetMicrophoneEnabled',
      returns: 'void',
      static: true,
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "Like [Web.SetCameraAndMicrophoneEnabled()](/webview/Web#SetCameraAndMicrophoneEnabled), but enables only the microphone without enabling the camera. In addition to calling this method, you must also complete the additional steps described [here](https://support.vuplex.com/articles/webrtc#ios) in order to successfully enable the microphone.",
      example: `void Awake() {
    #if UNITY_IOS && !UNITY_EDITOR
        iOSWebView.SetMicrophoneEnabled(true);
    #endif
}`
    },
    {
      name: 'SetNativeParentView',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          name: 'nativeParentView',
          type: 'IntPtr'
        }
      ],
      description: "3D WebView for iOS is powered by native [WKWebView](https://developer.apple.com/documentation/webkit/wkwebview?language=objc) instances, and it must add those instances to the native iOS view hierarchy in order for them to work correctly. By default, 3D WebView adds the native WKWebView instances as children of the Unity view controller's view. However, you can call this method at the start of the app to override the native UIView to which 3D WebView adds the native WKWebView instances. For example, you may need to do this if your app embeds Unity as a library. The `nativeParentView` parameter is a pointer to an Objective-C [UIView](https://developer.apple.com/documentation/uikit/uiview?language=objc) (`UIView *`).",
      example: `void Awake() {
    #if UNITY_IOS && !UNITY_EDITOR
        iOSWebView.SetNativeParentView(nativeParentView);
    #endif
}`,
    },
    {
      name: 'SetScrollViewBounces',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'bounces',
          type: 'bool'
        }
      ],
      description: "When [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode) is enabled, this method sets whether the scroll view bounces past the edge of content and back again. The default is `true`. When Native 2D Mode is not enabled, this method has no effect.",
      example: `await canvasWebViewPrefab.WaitUntilInitialized();
#if UNITY_IOS && !UNITY_EDITOR
    var iOSWebViewInstance = canvasWebViewPrefab.WebView as iOSWebView;
    iOSWebViewInstance.SetScrollViewBounces(false);
#endif`,
      seeAlso: '[UIScrollView.bounces](https://developer.apple.com/documentation/uikit/uiscrollview/1619420-bounces)'
    },
    {
      name: 'SetTargetFrameRate',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'targetFrameRate',
          type: 'uint'
        }
      ],
      description: "Sets the target web frame rate. The default is `30`, which is also the maximum value. This method can be used to lower the target web frame rate in order to decrease energy and CPU usage. 3D WebView's rendering speed is limited by the speed of the underlying iOS APIs, so the actual web frame rate achieved is always lower than the default target of 30 FPS. This method is only used for the default render mode and is ignored when [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode) is enabled.",
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_IOS && !UNITY_EDITOR
    var iOSWebViewInstance = webViewPrefab.WebView as iOSWebView;
    iOSWebViewInstance.SetTargetFrameRate(15);
#endif`,
    },
  ]
};
export default definition;

