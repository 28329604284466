import Products from '../../web-common/models/products/Products';
import FileReferenceDefinition from '../FileReferenceDefinition';

const STANDALONE_AFTER_INIT_WARNING = "On Windows and macOS, this method cannot be executed while the Chromium browser process is running. So, you will likely need to call it from Awake() to ensure that it's executed before Chromium is started. Alternatively, you can manually terminate Chromium prior to calling this method using [StandaloneWebView.TerminateBrowserProcess()](/webview/StandaloneWebView#TerminateBrowserProcess)."

const definition: FileReferenceDefinition = {
  name: 'Web',
  type: 'class',
  static: true,
  description: "The Web static class contains APIs that globally impact the behavior of all [IWebView](/webview/IWebView) instances, such as configuration options and global actions. It also contains the [CreateWebView()](#CreateWebView) method for creating an IWebView directly.",
  seeAlso: [
    '[WebViewPrefab](/webview/WebViewPrefab)',
    '[CanvasWebViewPrefab](/webview/CanvasWebViewPrefab)',
    '[IWebView](/webview/IWebView)',
  ],
  warnings: [
    "The Web class's APIs cannot be used before [Awake()](https://docs.unity3d.com/ScriptReference/MonoBehaviour.Awake.html) is first called for the app. Attempting to use a Web API prior to Awake() will result in an [InvalidOperationException](https://learn.microsoft.com/en-us/dotnet/api/system.invalidoperationexception)."
  ],
  members: [
    {
      name: 'DefaultPluginType',
      returns: 'void',
      type: 'property',
      propertyAccessors: { get: true },
      static: true,
      description: 'Gets the default 3D WebView plugin type among those installed for the current platform.',
    },
    {
      name: 'CookieManager',
      returns: 'ICookieManager?',
      type: 'property',
      propertyAccessors: { get: true },
      static: true,
      description: `Returns the ICookieManager for managing HTTP cookies, or \`null\` if ICookieManager isn't supported on the current platform.

ICookieManager is supported by all of the 3D WebView packages except for:
- [3D WebView for Android with Gecko Engine](${Products.androidGecko.storeUrl})
- [2D WebView for WebGL](${Products.webGL.storeUrl})`,
    },
    {
      name: 'ClearAllData',
      returns: 'void',
      type: 'method',
      static: true,
      description: 'Clears all data that persists between webview instances, like cookies, storage, and cached resources.',
      example: `void Awake() {
    Web.ClearAllData();
}`,
      warnings: [
        STANDALONE_AFTER_INIT_WARNING,
        "On Universal Windows Platform, this method is unable to clear cookies due to a UWP limitation."
      ]
    },
    {
      name: 'CreateWebView',
      returns: 'IWebView',
      type: 'method',
      static: true,
      description: "Creates a new webview in a platform-agnostic way. After the [IWebView](/webview/IWebView) is created, it must be initialized by calling [Init()](/webview/IWebView#Init) method.",
      warnings: [
        "[WebViewPrefab](/webview/WebViewPrefab) and [CanvasWebViewPrefab](/webview/CanvasWebViewPrefab) take care of creating and managing an [IWebView](/webview/IWebView) instance for you, so you only need to call this method directly if you need to create a webview outside of a prefab (for example, to connect it to your own custom GameObject)."
      ],
      example: `var webView = Web.CreateWebView();
// Initialize the webview to 600px x 300px.
await webView.Init(600, 300);
webView.LoadUrl("https://vuplex.com");
// Set the Material attached to this GameObject to display the webview.
GetComponent<Renderer>().material = webView.CreateMaterial();`
    },
    {
      name: 'EnableRemoteDebugging',
      returns: 'void',
      type: 'method',
      static: true,
      description: 'Enables [remote debugging](https://support.vuplex.com/articles/how-to-debug-web-content).',
      example: `void Awake() {
    Web.EnableRemoteDebugging();
}`,
      warnings: [
        STANDALONE_AFTER_INIT_WARNING,
      ],
      seeAlso: '[WebViewPrefab.RemoteDebuggingEnabled](/webview/WebViewPrefab#RemoteDebuggingEnabled)'
    },
    {
      name: 'SetAutoplayEnabled',
      returns: 'void',
      type: 'method',
      static: true,
      arguments: [
        {
          name: 'enabled',
          type: 'bool',
        }
      ],
      description: "By default, browsers block sites from autoplaying video with audio, but this method can be used to enable autoplay.",
      example: `void Awake() {
    Web.SetAutoplayEnabled(true);
}`,
      warnings: [
        STANDALONE_AFTER_INIT_WARNING,
        `This method works for every package except for [3D WebView for UWP](${Products.uwp.storeUrl}), because the underlying UWP WebView control doesn't allow autoplaying video with audio.`
      ]
    },
    {
      name: 'SetCameraAndMicrophoneEnabled',
      returns: 'void',
      type: 'method',
      static: true,
      arguments: [
        {
          name: 'enabled',
          type: 'bool',
        }
      ],
      description: "By default, web pages cannot access the device's camera or microphone via JavaScript, but this method can be used to grant **all web pages** access to the camera and microphone. This is useful, for example, to enable WebRTC support. Note that on macOS, Android, iOS, and UWP, [additional project configuration is needed in order to enable permission for the camera and microphone](https://support.vuplex.com/articles/webrtc). Camera and microphone permissions are enabled together with a single method because on some platforms (Windows, macOS, UWP), these permissions can only be enabled together and cannot be enabled separately.",
      example: `void Awake() {
    Web.SetCameraAndMicrophoneEnabled(true);
}`,
      warnings: [
        STANDALONE_AFTER_INIT_WARNING,
        'On iOS, enabling the camera and microphone is only supported in iOS 14.3 or newer and is only supported in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).'
      ]
    },
    {
      name: 'SetIgnoreCertificateErrors',
      returns: 'void',
      type: 'method',
      static: true,
      arguments: [
        {
          name: 'ignore',
          type: 'bool',
        }
      ],
      description: "By default, browsers block https URLs with invalid SSL certificates from being loaded. However, this method can be used to ignore certificate errors.",
      example: `void Awake() {
    Web.SetIgnoreCertificateErrors(true);
}`,
      warnings: [
        STANDALONE_AFTER_INIT_WARNING,
        `This method works for every package except for [3D WebView for UWP](${Products.uwp.storeUrl}). For UWP, certificates must be [whitelisted in the Package.appxmanifest file](https://www.suchan.cz/2015/10/displaying-https-page-with-invalid-certificate-in-uwp-webview/).`
      ]
    },
    {
      name: 'SetStorageEnabled',
      returns: 'void',
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: 'Controls whether data like cookies, localStorage, and cached resources is persisted between webview instances. The default is `true`, but this can be set to `false` to achieve an "incognito mode".',
      example: `void Awake() {
    Web.SetStorageEnabled(false);
}`,
      warnings: [
        STANDALONE_AFTER_INIT_WARNING,
        "On Universal Windows Platform, this method doesn't disable cookies due to a UWP limitation."
      ]
    },
    {
      name: 'SetUserAgent',
      returns: 'void',
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'bool',
          name: 'mobile'
        }
      ],
      description: `Globally configures all webviews to use a mobile or desktop
[User-Agent](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent).
By default, webviews use the browser engine's default User-Agent, but you
can force them to use a mobile User-Agent by calling \`Web.SetUserAgent(true)\` or a
desktop User-Agent with \`Web.SetUserAgent(false)\`.`,
      example: `void Awake() {
    // Use a desktop User-Agent
    Web.SetUserAgent(false);
}`,
      seeAlso: '[IWithSettableUserAgent](/webview/IWithSettableUserAgent)'
    },
    {
      name: 'SetUserAgent',
      returns: 'void',
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'string',
          name: 'userAgent'
        }
      ],
      description: 'Globally configures all webviews to use a custom [User-Agent](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent).',
      example: `void Awake() {
    // Use FireFox's User-Agent.
    Web.SetUserAgent("Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:91.0) Gecko/20100101 Firefox/91.0");
}`,
      seeAlso: '[IWithSettableUserAgent](/webview/IWithSettableUserAgent)'
    }
  ]
};
export default definition;
