import FileReferenceDefinition from '../FileReferenceDefinition';

const WEBGL_CROSS_ORIGIN_LIMITATION_WARNING = "For [2D WebView for WebGL](https://store.vuplex.com/webview/webgl), this API is often disabled due to browser limitations. For more information, please see [this article](https://support.vuplex.com/articles/webgl-limitations).";

const WEBGL_NOT_SUPPORTED_WARNING = "[2D WebView for WebGL](https://store.vuplex.com/webview/webgl) doesn't support this API due to browser limitations. For more information, please see [this article](https://support.vuplex.com/articles/webgl-limitations).";


const definition: FileReferenceDefinition = {
  name: 'IWebView',
  type: 'interface',
  plainTextDescription: 'IWebView is the primary interface for loading and interacting with web content. It contains methods and properties for common browser-related functionality, like LoadUrl(), GoBack(), Reload(), and ExecuteJavaScript().',
  description: `IWebView is the primary interface for loading and interacting with web content. It contains methods and properties for common browser-related functionality, like [LoadUrl()](#LoadUrl), [GoBack()](#GoBack), [Reload()](#Reload), and [ExecuteJavaScript()](#ExecuteJavaScript).

To create an IWebView, instantiate a [WebViewPrefab](/webview/WebViewPrefab) or [CanvasWebViewPrefab](/webview/CanvasWebViewPrefab). After the prefab is [initialized](/webview/WebViewPrefab#WaitUntilInitialized), you can access its IWebView via the [WebViewPrefab.WebView](/webview/WebViewPrefab#WebView) property. If your use case requires a high degree of customization, you can instead create an IWebView outside of a prefab (to connect to your own custom GameObject) by using [Web.CreateWebView()](/webview/Web#CreateWebView).

For additional functionality, you can cast an IWebView to an [interface for a specific feature](/webview/additional-interfaces), like [IWithDownloads](/webview/IWithDownloads) or [IWithPopups](IWithPopups). For a list of additional feature interfaces and information about how to use them, see [this page](/webview/additional-interfaces).
  `,
  seeAlso: [
    '[WebViewPrefab](/webview/WebViewPrefab)',
    '[CanvasWebViewPrefab](/webview/CanvasWebViewPrefab)',
    '[Web (static methods)](/webview/Web)'
  ],
  members: [
    {
      name: 'CloseRequested',
      returns: 'EventHandler',
      type: 'event',
      description: "Indicates that JavaScript in the page has called [window.close()](https://developer.mozilla.org/en-US/docs/Web/API/Window/close). Calling window.close() doesn't automatically close a webview, but the application can listen to this CloseRequested event to detect when window.close() is called and then choose whether to [destroy the webview](https://developer.vuplex.com/webview/WebViewPrefab#Destroy) in response to it.",
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.CloseRequested += (sender, eventArgs) => {
        Debug.Log("Destroying the WebViewPrefab because window.close() was called.");
        webViewPrefab.Destroy();
};`,
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'ConsoleMessageLogged',
      returns: 'EventHandler<ConsoleMessageEventArgs>',
      type: 'event',
      description: "Indicates that a message was logged to the JavaScript console.",
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.ConsoleMessageLogged += (sender, eventArgs) => {
    Debug.Log($"Console message logged: [{eventArgs.Level}] {eventArgs.Message}");
};`,
      seeAlso: '[WebViewPrefab.LogConsoleMessages](/webview/WebViewPrefab#LogConsoleMessages)',
      warnings: [
        `The 3D WebView packages for Android with Gecko, iOS, and UWP have the following limitations:
- Only messages explicitly passed to a console method like console.log() are included, and other messages like uncaught errors or network errors aren't automatically included.
- Messages from iframes aren't included.
- Messages logged early when the page starts loading may be missed.`,
        'For Android Gecko, an alternative that avoids these limitations is to call [AndroidGeckoWebView.SetConsoleOutputEnabled()](/webview/AndroidGeckoWebView#SetConsoleOutputEnabled).',
        WEBGL_CROSS_ORIGIN_LIMITATION_WARNING
      ],
    },
    {
      name: 'FocusChanged',
      returns: 'EventHandler<EventArgs<bool>>',
      type: 'event',
      description: `Raised whenever [SetFocused()](#SetFocused) is called. This event primarily exists because 3D WebView internally uses it to to implement keyboard focus handling.`,
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.FocusChanged += (sender, eventArgs) => {
    Debug.Log("SetFocused() was called with value: " + eventArgs.Value);
};`,
    },
    {
      name: 'FocusedInputFieldChanged',
      returns: 'EventHandler<FocusedInputFieldChangedEventArgs>',
      type: 'event',
      description: `Indicates when an input field has been focused or unfocused. This can be used, for example, to determine when to show or hide an on-screen keyboard. This event is also raised when a focused input field is clicked subsequent times.`,
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.FocusedInputFieldChanged += (sender, eventArgs) => {
    Debug.Log("Focused input field changed. Text input is focused: " + (eventArgs.Type == FocusedInputFieldType.Text));
};`,
      warnings: [
        "When an [<iframe>](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe) element is focused, the event the event specifies the type [FocusedInputFieldType.IFrame](https://developer.vuplex.com/webview/FocusedInputFieldType#IFrame). This is because the event's implementation is unable detect the type of element that is focused inside an <iframe>.",
        WEBGL_CROSS_ORIGIN_LIMITATION_WARNING
      ],
    },
    {
      name: 'LoadFailed',
      returns: 'EventHandler<LoadFailedEventArgs>',
      type: 'event',
      description: `Indicates that the page failed to load due to an error. This can happen, for example, if DNS is unable to resolve the hostname.`,
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.LoadFailed += (sender, eventArgs) => {
    Debug.Log($"Load failed. Error code: {eventArgs.NativeErrorCode}, URL: {eventArgs.Url}");
};`,
      warnings: [WEBGL_NOT_SUPPORTED_WARNING],
    },
    {
      name: 'LoadProgressChanged',
      returns: 'EventHandler<ProgressChangedEventArgs>',
      type: 'event',
      description: `Indicates changes in the loading status of a web page. This event can be used, for example, to detect when a page finishes loading or to implement a load progress bar. This event indicates the following types of load events:
- \`Started\`: a new page started loading.
- \`Updated\`: the load progress percentage was updated.
- \`Finished\`: a page finished loading.
- \`Failed\`: a page failed to load.`,
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.LoadProgressChanged += (sender, eventArgs) => {
    Debug.Log($"Load progress changed: {eventArgs.Type}, {eventArgs.Progress}");
    if (eventArgs.Type == ProgressChangeType.Finished) {
        Debug.Log("The page finished loading");
    }
};`,
      warnings: [
        "For [2D WebView for WebGL](https://store.vuplex.com/webview/webgl), LoadProgressChanged only indicates the ProgressChangeType.Started and Finished events, and it's unable to indicate the Failed or Updated events."
      ],
      seeAlso: '[WaitForNextPageLoadToFinish()](#WaitForNextPageLoadToFinish)'
    },
    {
      name: 'MessageEmitted',
      returns: 'EventHandler<EventArgs<string>>',
      type: 'event',
      description: `Indicates that JavaScript running in the page used the \`window.vuplex.postMessage\` JavaScript API to emit a message to the Unity application. For more details, please see [this support article](https://support.vuplex.com/articles/how-to-send-messages-from-javascript-to-c-sharp).`,
      example: `await webViewPrefab.WaitUntilInitialized();
// Add JavaScript to the page that sends a message.
webViewPrefab.WebView.PageLoadScripts.Add(@"
    window.vuplex.postMessage('Hello from JavaScript!');
");
webViewPrefab.WebView.MessageEmitted += (sender, eventArgs) => {
    Debug.Log("Message received from JavaScript: " + eventArgs.Value);
};`,
      seeAlso: [
        '[ExecuteJavaScript](#ExecuteJavaScript)',
        '[PageLoadScripts](#PageLoadScripts)',
      ]
    },
    {
      name: 'Terminated',
      returns: 'EventHandler<TerminatedEventArgs>',
      type: 'event',
      description: `Indicates that the browser engine reported that its process for the webview terminated unexpectedly, either because the web process crashed or because it was killed by the operating system. The webview cannot be used after it has been terminated, so if this event occurs, the webview must be destroyed, and then the application can optionally create a new webview to replace it. 3D WebView is only aware that the browser engine reported that its process terminated, and 3D WebView doesn't have any additional insights into what caused the termination. For more details, you can visit the documentation for the native platform APIs that 3D WebView uses to detect termination:

- Windows and macOS: [OnRenderProcessTerminated()](https://magpcss.org/ceforum/apidocs3/projects/(default)/CefRequestHandler.html#OnRenderProcessTerminated(CefRefPtr%3CCefBrowser%3E,TerminationStatus))
- Android: [onRenderProcessGone()](https://developer.android.com/reference/android/webkit/WebViewClient#onRenderProcessGone(android.webkit.WebView,%20android.webkit.RenderProcessGoneDetail))
- Android Gecko: [onCrash()](https://mozilla.github.io/geckoview/javadoc/mozilla-central/org/mozilla/geckoview/GeckoSession.ContentDelegate.html#onCrash(org.mozilla.geckoview.GeckoSession)) and [onKill()](https://mozilla.github.io/geckoview/javadoc/mozilla-central/org/mozilla/geckoview/GeckoSession.ContentDelegate.html#onKill(org.mozilla.geckoview.GeckoSession))
- iOS and visionOS: [webViewWebContentProcessDidTerminate](https://developer.apple.com/documentation/webkit/wknavigationdelegate/1455639-webviewwebcontentprocessdidtermi?language=objc)`,
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.Terminated += (sender, eventArgs) => {
    Debug.Log("The web content process was terminated. Reason: " + eventArgs.Type);
    // Destroy the webview because it can't be used any more.
    webViewPrefab.Destroy();
    // TODO: If the application still needs a webview, it can create a new one with WebViewPrefab.Instantiate().
};`,
    },
    {
      name: 'TitleChanged',
      returns: 'EventHandler<EventArgs<string>>',
      type: 'event',
      description: `Indicates that the page's title changed.`,
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.TitleChanged += (sender, eventArgs) => {
    Debug.Log("Page title changed: " + eventArgs.Value);
};`,
      seeAlso: '[Title](#Title)',
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'UrlChanged',
      returns: 'EventHandler<UrlChangedEventArgs>',
      type: 'event',
      description: `Indicates that the URL of the webview changed, either due to user interaction or JavaScript.`,
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.UrlChanged += (sender, eventArgs) => {
    Debug.Log("URL changed: " + eventArgs.Url);
};`,
      seeAlso: '[Url](#Url)',
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'IsDisposed',
      returns: 'bool',
      type: 'property',
      propertyAccessors: { get: true },
      description: "Gets a value indicating whether the instance has been disposed via [Dispose()](#Dispose)."
    },
    {
      name: 'IsInitialized',
      returns: 'bool',
      type: 'property',
      propertyAccessors: { get: true },
      description: "Gets a value indicating whether the instance has been initialized via [Init()](#Init)."
    },
    {
      name: 'PageLoadScripts',
      returns: 'List<string>',
      type: 'property',
      propertyAccessors: { get: true },
      description: `Gets a list of JavaScript scripts that are automatically executed in every new page that is loaded.

This list is empty by default, but the application can add scripts. When used in conjunction with 3D WebView's [message passing API](https://support.vuplex.com/articles/how-to-send-messages-from-javascript-to-c-sharp), it's possible to modify the browser's behavior in significant ways, similar to creating browser extensions.`,
      example: `
// Add a script that automatically hides all scrollbars.
await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.PageLoadScripts.Add(@"
    var styleElement = document.createElement('style');
    styleElement.innerText = 'body::-webkit-scrollbar { display: none; }';
    document.head.appendChild(styleElement);
");
`,
      seeAlso: [
        '[ExecuteJavaScript](#ExecuteJavaScript)',
        '[JS-to-C# message passing](https://support.vuplex.com/articles/how-to-send-messages-from-javascript-to-c-sharp)'
      ],
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'PluginType',
      returns: 'WebPluginType',
      type: 'property',
      propertyAccessors: { get: true },
      example: `await webViewPrefab.WaitUntilInitialized();
Debug.Log("Plugin type: " + webViewPrefab.WebView.PluginType);`,
      description: `Gets the instance's plugin type.`
    },
    {
      name: 'Size',
      returns: 'Vector2Int',
      type: 'property',
      propertyAccessors: { get: true },
      description: `Gets the webview's size in pixels.`,
      example: `await webViewPrefab.WaitUntilInitialized();
Debug.Log("Size: " + webViewPrefab.WebView.Size);`,
      seeAlso: [
        '[Resize](#Resize)',
      ]
    },
    {
      name: 'Texture',
      returns: 'Texture2D?',
      type: 'property',
      propertyAccessors: { get: true },
      description: `
Gets the texture for the webview's web content, or \`null\` if running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode). In order to render the texture, the application must use a Material created with [CreateMaterial()](#CreateMaterial). Note that the Material returned by CreateMaterial() already has its \`mainTexture\` set to this Texture property.

This texture is an "external texture" created with [Texture2D.CreateExternalTexture()](https://docs.unity3d.com/ScriptReference/Texture2D.CreateExternalTexture.html). An undocumented characteristic of external textures in Unity is that not all Texture2D methods work for them. For example, [Texture2D.GetRawTextureData()](https://docs.unity3d.com/ScriptReference/Texture2D.GetRawTextureData.html) and [ImageConversion.EncodeToPNG()](https://docs.unity3d.com/ScriptReference/ImageConversion.EncodeToPNG.html) fail for external textures. To compensate, the IWebView interface includes ts own [GetRawTextureData()](#GetRawTextureData) and [CaptureScreenshot()](#CaptureScreenshot) methods to replace them.

Another quirk of this texture is that Unity always reports its size as 1300px × 1300px in the editor. In reality, 3D WebView resizes the texture in native code to match the dimensions of the webview, but Unity doesn't provide an API to notify the engine that an external texture's size has changed. So, Unity always reports its size as the initial size that was passed to Texture2D.CreateExternalTexture(), which in 3D WebView's case is 1300px × 1300px.
`,
      example: `await webViewPrefab.WaitUntilInitialized();
var material = webViewPrefab.WebView.CreateMaterial();
// Note: the material returned by CreateMaterial() already
// has its mainTexture set to IWebView.Texture, so setting
// it explicitly like this is really only needed if you are
// switching a material from one webview's texture to another.
material.mainTexture = webViewPrefab.WebView.Texture;`
    },
    {
      name: 'Title',
      returns: 'string',
      type: 'property',
      propertyAccessors: { get: true },
      description: `Gets the current web page title.`,
      example: `// Get the page's title after it finishes loading.
await webViewPrefab.WaitUntilInitialized();
await webViewPrefab.WebView.WaitForNextPageLoadToFinish();
Debug.Log("Page title: " + webViewPrefab.WebView.Title);`,
      seeAlso: '[TitleChanged](#TitleChanged)',
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'Url',
      returns: 'string',
      type: 'property',
      propertyAccessors: { get: true },
      description: `Gets the current URL.`,
      example: `// Get the page's URL after it finishes loading.
await webViewPrefab.WaitUntilInitialized();
await webViewPrefab.WebView.WaitForNextPageLoadToFinish();
Debug.Log("Page URL: " + webViewPrefab.WebView.Url);`,
      seeAlso: '[UrlChanged](#UrlChanged)',
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'CanGoBack',
      returns: 'Task<bool>',
      type: 'method',
      description: "Checks whether the webview can go back with a call to [GoBack()](#GoBack).",
      example: `var canGoBack = await webViewPrefab.CanGoBack();`,
      seeAlso: '[GoBack()](#GoBack)',
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING]
    },
    {
      name: 'CanGoForward',
      returns: 'Task<bool>',
      type: 'method',
      description: "Checks whether the webview can go forward with a call to [GoForward()](#GoForward).",
      example: `var canGoForward = await webViewPrefab.CanGoForward();`,
      seeAlso: '[GoForward()](#GoForward)',
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING]
    },
    {
      name: 'CaptureScreenshot',
      returns: 'Task<bytes[]>',
      type: 'method',
      description: "Returns a PNG image of the content visible in the webview.",
      example: `// Get a screenshot and write it to a file.
var screenshotBytes = await webViewPrefab.WebView.CaptureScreenshot();
var filePath = Path.Combine(Application.persistentDataPath, "screenshot.png");
File.WriteAllBytes(filePath, screenshotBytes);`,
      seeAlso: [
        '[ImageConversion.LoadImage()](https://docs.unity3d.com/ScriptReference/ImageConversion.LoadImage.html)',
        '[GetRawTextureData()](#GetRawTextureData)'
      ],
      warnings: [
        'On iOS, screenshots do not include video content, which appears black.',
        WEBGL_NOT_SUPPORTED_WARNING
      ],
    },
    {
      name: 'Click',
      returns: 'void',
      type: 'method',
      arguments: [
        { name: 'xInPixels', type: 'int' },
        { name: 'yInPixels', type: 'int' },
        { name: 'preventStealingFocus', type: 'bool', defaultValue: 'false' },

      ],
      description: `Clicks at the given coordinates in pixels in the web page, dispatching both a mouse down and a mouse up event.`,
      example: `// Click at (250px, 100px).
webViewPrefab.WebView.Click(250, 100);

// Click at (50px, 150px) and prevent stealing focus from another webview.
webViewPrefab.WebView.Click(50, 150, true);`,
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'Click',
      returns: 'void',
      type: 'method',
      arguments: [
        { name: 'normalizedPoint', type: 'Vector2' },
        { name: 'preventStealingFocus', type: 'bool', defaultValue: 'false' },
      ],
      description: `Like [Click(int, int, bool?)](#Click), except it takes a [normalized point](https://support.vuplex.com/articles/normalized-points) instead of pixel coordinates.`,
      example: `// Click in the exact center of the page.
webViewPrefab.WebView.Click(new Vector2(0.5f, 0.5f));

// Click in the upper right quadrant of the page
// and prevent stealing focus from another webview.
webViewPrefab.WebView.Click(new Vector2(0.75f, 0.25f), true);`,
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'Copy',
      returns: 'void',
      type: 'method',
      description: `Copies the selected text to the clipboard.`,
      example: `webViewPrefab.WebView.Copy();`,
      seeAlso: [
        '[Cut()](#Cut)',
        '[Paste()](#Paste)',
        '[SelectAll()](#SelectAll)',
      ],
      warnings: [WEBGL_NOT_SUPPORTED_WARNING],
    },
    {
      name: 'CreateMaterial',
      returns: 'Material',
      type: 'method',
      description: `Creates a Material that can be used to display the webview, or \`null\` if running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode). The returned material already has the webview's Texture set as its [mainTexture](https://docs.unity3d.com/ScriptReference/Material-mainTexture.html).

Note that [WebViewPrefab](https://developer.vuplex.com/webview/WebViewPrefab) and [CanvasWebViewPrefab](https://developer.vuplex.com/webview/CanvasWebViewPrefab) take care of material creation for you, so you only need to call this method directly if you need to create an IWebView instance outside of a prefab with [Web.CreateWebView()](/webview/Web#CreateWebView).`,
      example: `GetComponent<Renderer>().material = webView.CreateMaterial();`,
    },
    {
      name: 'Cut',
      returns: 'void',
      type: 'method',
      description: `Copies the selected text to the clipboard and removes it.`,
      example: `webViewPrefab.WebView.Cut();`,
      seeAlso: [
        '[Copy()](#Copy)',
        '[Paste()](#Paste)',
        '[SelectAll()](#SelectAll)',
      ],
      warnings: [WEBGL_NOT_SUPPORTED_WARNING],
    },
    {
      name: 'Dispose',
      returns: 'void',
      type: 'method',
      description: 'Destroys the webview, releasing all of its resources.',
      warnings: [
        "If you're using a [WebViewPrefab](/webview/WebViewPrefab) or [CanvasWebViewPrefab](/webview/CanvasWebViewPrefab), please call [Destroy()](/webview/WebViewPrefab#Destroy) on the prefab instead of calling IWebView.Dispose(). Calling IWebView.Dispose() while the prefab is still using the webview can cause issues."
      ]
    },
    {
      name: 'ExecuteJavaScript',
      returns: 'Task<string>',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'javaScript'
        }
      ],
      description: `
Executes the given JavaScript in the context of the page and returns the result.

In order to run JavaScript, a web page must first be loaded. You can use [WaitForNextPageLoadToFinish()](#WaitForNextPageLoadToFinish) or the [LoadProgressChanged](#LoadProgressChanged) event to run JavaScript after a page loads.
`,
      example: `await webViewPrefab.WaitUntilInitialized();
await webViewPrefab.WebView.WaitForNextPageLoadToFinish();

// Example 1: Get the text of the first <h1> element on the page.
var headerText = await webViewPrefab.WebView.ExecuteJavaScript("document.getElementsByTagName('h1')[0].innerText");
Debug.Log("H1 text: " + headerText);

// Example 2: Set the web page title to text input by the user.
webViewPrefab.WebView.ExecuteJavaScript(@"
    // Enclose in a block to prevent titleText from being a global variable.
    {
        let titleText = window.prompt('What do you want to set the web page title to?');
        document.title = titleText;
    }
");

// Example 3: Get the URL of the image at the point (500px, 350px).
Vector2Int pointInPixels = new Vector2Int(500, 350);
var imageUrl = await webViewPrefab.WebView.ExecuteJavaScript($@"
    (function() {{
        const element = document.elementFromPoint({pointInPixels.x}, {pointInPixels.y});
        if (element instanceof HTMLImageElement) {{
            return element.currentSrc;
        }}
        return '';
    }})()
");
Debug.Log("Image URL: " + imageUrl);
`,
      seeAlso: [
        '[PageLoadScripts](#PageLoadScripts)',
        '[How to get ExecuteJavaScript() to return the result of a Promise?](https://support.vuplex.com/articles/javascript-promise-result)',
        '[JS-to-C# message passing](https://support.vuplex.com/articles/how-to-send-messages-from-javascript-to-c-sharp)',
        '[How to get HTML, text, images, or other info from a web page?](https://support.vuplex.com/articles/how-to-get-html)',
      ],
      warnings: [
        `For Android, iOS, and UWP, **JavaScript variables not enclosed in a [block](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/block) become global variables**. Global variables can be assigned to again in subsequent calls to ExecuteJavaScript(), but they cannot be declared again (i.e. using \`let\` or \`var\`) because doing so will result in a JavaScript error like the following:
\`\`\`
Uncaught SyntaxError: Identifier '{variable_name}' has already been declared
\`\`\`
If your JavaScript declares variables, it is recommended to enclose the JavaScript in a block like shown in example #2 above. This behavior is only exhibited by the 3D WebView packages for Android, iOS, and UWP. For the other 3D WebView packages (Windows, macOS, Android Gecko, and WebGL), JavaScript variables are not declared in the global scope.`,
        WEBGL_CROSS_ORIGIN_LIMITATION_WARNING
      ],
    },
    {
      name: 'ExecuteJavaScript',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'javaScript'
        },
        {
          type: 'Action<string>',
          name: 'callback'
        }
      ],
      description: "Like the other version of ExecuteJavaScript(), except it uses a callback instead of a Task to return the result. If you don't need the result from executing the JavaScript, you can improve the method's efficiency by passing `null` as the callback argument."
    },
    {
      name: 'GetRawTextureData',
      returns: 'Task<byte[]>',
      type: 'method',
      description: `
A replacement for [Texture2D.GetRawTextureData()](https://docs.unity3d.com/ScriptReference/Texture2D.GetRawTextureData.html) for [IWebView.Texture](#Texture).

Unity's [Texture2D.GetRawTextureData()](https://docs.unity3d.com/ScriptReference/Texture2D.GetRawTextureData.html) method currently does not work for textures created with [Texture2D.CreateExternalTexture()](https://docs.unity3d.com/ScriptReference/Texture2D.CreateExternalTexture.html). So, this method serves as a replacement by providing the equivalent functionality. You can load the bytes returned by this method into another texture using [Texture2D.LoadRawTextureData()](https://docs.unity3d.com/ScriptReference/Texture2D.LoadRawTextureData.html).`,
      example: `var webView = webViewPrefab.WebView;
var textureData = await webView.GetRawTextureData();
var texture = new Texture2D(
    webView.Size.x,
    webView.Size.y,
    TextureFormat.RGBA32,
    false,
    false
);
texture.LoadRawTextureData(textureData);
texture.Apply();`,
      seeAlso: [
        '[Texture2D.LoadRawTextureData()](https://docs.unity3d.com/ScriptReference/Texture2D.LoadRawTextureData.html)',
        '[CaptureScreenshot()](#CaptureScreenshot)'
      ],
      warnings: [
        'On iOS, the texture data excludes video content, which appears black.',
        WEBGL_NOT_SUPPORTED_WARNING
      ],
    },
    {
      name: 'GoBack',
      returns: 'void',
      type: 'method',
      description: "Navigates back to the previous page in the webview's history.",
      example: `webViewPrefab.WebView.GoBack();`,
      seeAlso: '[CanGoBack()](#CanGoBack)',
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING]
    },
    {
      name: 'GoForward',
      returns: 'void',
      type: 'method',
      description: "Navigates forward to the next page in the webview's history.",
      example: `webViewPrefab.WebView.GoForward();`,
      seeAlso: '[GoForward()](#GoForward)',
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING]
    },
    {
      name: 'HandleKeyboardInput',
      returns: 'void',
      type: 'method',
      arguments: [{ type: 'string', name: 'key' }],
      description: '(Deprecated) Renamed to [SendKey()](#SendKey) in 3D WebView v4.0.'
    },
    {
      name: 'Init',
      returns: 'Task',
      type: 'method',
      arguments: [
        {
          type: 'int',
          name: 'width'
        },
        {
          type: 'int',
          name: 'height'
        },
      ],
      description: `Asynchronously initializes the webview with the given the dimensions in pixels.

Note that you don't need to call this method if you're using one of the prefabs, like WebViewPrefab. You only need to call Init() if you create an IWebView directly with [Web.CreateWebView()](/webview/Web#CreateWebView). Also, this method's signature was [updated in 3D WebView v4](https://support.vuplex.com/articles/v4-changes#init).`,

    },
    {
      name: 'LoadHtml',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'html'
        }
      ],
      description: `Loads the webpage contained in the given HTML string. Note that HTML loaded via this method cannot load subresources (e.g. images, CSS, JavaScript) from the local file system (i.e. via file:// URLs). If you need to load subresources from the local file system, please use one of the approaches described in [this article](https://support.vuplex.com/articles/how-to-load-local-files) instead.`,
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.LoadHtml(@"
  <!DOCTYPE html>
  <html>
    <head>
      <title>Test Page</title>
      <style>
        h1 {
          font-family: Helvetica, Arial, Sans-Serif;
        }
      </style>
    </head>
    <body>
      <h1>LoadHtml Example</h1>
      <script>
        console.log('This page was loaded!');
      </script>
    </body>
  </html>"
);`,
      seeAlso: '[LoadUrl()](#LoadUrl)',
      warnings: [
        "For [2D WebView for WebGL](https://store.vuplex.com/webview/webgl), please see [this article](https://support.vuplex.com/articles/webgl-limitations#cross-origin-limitation) that describes how this API is impacted by browser limitations."
      ],
    },
    {
      name: 'LoadUrl',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'url'
        }
      ],
      description: `
Loads the given URL. Supported URL schemes:
- \`http://\`, \`https://\` - loads a remote page over HTTP
- \`streaming-assets://\` - loads a local page from [StreamingAssets](https://docs.unity3d.com/Manual/StreamingAssets.html)
    (equivalent to \`"file://" + Application.streamingAssetsPath + path\`)
- \`file://\` - some platforms support loading arbitrary file URLs
`,
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.LoadUrl("https://vuplex.com");`,
      seeAlso: [
        '[How to load local files](https://support.vuplex.com/articles/how-to-load-local-files)',
        '[LoadHtml()](#LoadHtml)',
        '[WebViewPrefab.InitialUrl](/webview/WebViewPrefab#InitialUrl)'
      ]
    },
    {
      name: 'LoadUrl',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'url'
        },
        {
          type: 'Dictionary<string, string>',
          name: 'additionalHttpHeaders'
        }
      ],
      description: "Like [LoadUrl(string url)](#LoadUrl), but also sends the given additional HTTP request headers when loading the URL. The headers are sent for the initial page load request but are not sent for requests for subsequent resources, like linked JavaScript or CSS files.",
      example: `await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.LoadUrl("https://vuplex.com", new Dictionary<string, string> {
    ["Authorization"] = "Basic YWxhZGRpbjpvcGVuc2VzYW1l",
    ["Cookie"] = "foo=bar"
});`,
      warnings: [
        `For Windows, macOS, and Android Gecko, this method cannot be used to set the browser's [Accept-Language](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language) header. For more details, please see the following resources:
- [How to change the Accept-Language header on Windows and macOS](https://support.vuplex.com/articles/how-to-change-accept-language-header)
- [AndroidGeckoWebView.SetLocales()](/webview/AndroidGeckoWebView#SetLocales)`,
        "For [2D WebView for WebGL](https://store.vuplex.com/webview/webgl), this method is unable to send additional headers due to browser limitations, so it loads the URL without additional headers."
      ]
    },
    {
      name: 'NormalizedToPoint',
      returns: 'Vector2Int',
      type: 'method',
      arguments: [
        { name: 'normalizedPoint', type: 'Vector2' },
      ],
      description: `Returns a point in pixels for the given [normalized point](https://support.vuplex.com/articles/normalized-points).`,
      example: `webViewPrefab.Clicked += (sender, eventArgs) => {
    var pointInPixels = webViewPrefab.WebView.NormalizedToPoint(eventArgs.Point);
    Debug.Log("Point in pixels: " + pointInPixels);
};`,
      seeAlso: ['[PointToNormalized()](#NormalizedToPoint)']
    },
    {
      name: 'Paste',
      returns: 'void',
      type: 'method',
      description: `Pastes text from the clipboard.`,
      example: `webViewPrefab.WebView.Paste();`,
      seeAlso: [
        '[Copy()](#Copy)',
        '[Cut()](#Cut)',
      ],
      warnings: [WEBGL_NOT_SUPPORTED_WARNING],
    },
    {
      name: 'PointToNormalized',
      returns: 'Vector2',
      type: 'method',
      arguments: [
        { name: 'xInPixels', type: 'int' },
        { name: 'yInPixels', type: 'int' },
      ],
      description: `Returns a [normalized point](https://support.vuplex.com/articles/normalized-points) for the given x and y coordinates in pixels. This can be used to create normalized points for APIs that accept them, like [MovePointer()](https://developer.vuplex.com/webview/IWithMovablePointer#MovePointer).`,
      example: `var webView = webViewPrefab.WebView;
// Scroll to the right by 50 pixels at (100px, 1300px).
webView.Scroll(
    webView.PointToNormalized(50, 0),
    webView.PointToNormalized(100, 1300)
);`,
    seeAlso: ['[NormalizedToPoint()](#NormalizedToPoint)']
    },
    {
      name: 'PostMessage',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'data'
        }
      ],
      description: `
Posts a message that JavaScript within the webview can listen for using \`window.vuplex.addEventListener('message', function(message) {})\`. The provided data string is passed as the data property of the message object.

For more details, please see [this support article](https://support.vuplex.com/articles/how-to-send-messages-from-javascript-to-c-sharp).`,
      example: `await webViewPrefab.WebView.WaitUntilInitialized();
// Add some JavaScript to the page to receive the message.
webViewPrefab.WebView.PageLoadScripts(@"
    window.vuplex.addEventListener('message', function(event) {
        console.log('Message received from C#: ' + event.data);
    });
");
// When the page finishes loading, send a message from C# to JavaScript.
await webViewPrefab.WebView.WaitForNextPageLoadToFinish();
webViewPrefab.WebView.PostMessage("Hello from C#");`,
    },
    {
      name: 'Reload',
      returns: 'void',
      type: 'method',
      description: `Reloads the current page.`,
      example: `webViewPrefab.WebView.Reload();`
    },
    {
      name: 'Resize',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'int',
          name: 'width'
        },
        {
          type: 'int',
          name: 'height'
        }

      ],
      description: 'Resizes the webview to the given dimensions in pixels.',
      warnings: [
        "If you're using [WebViewPrefab](#WebViewPrefab), you should call [WebViewPrefab.Resize()](/webview/WebViewPrefab#Resize) instead.",
        "On visionOS, if the webview was created with [VisionOSWebView.CreateInWindow()](/webview/VisionOSWebView#CreateInWindow), then calling Resize() has no effect because only the user can resize the webview using visionOS's native windowing controls."
      ],
      seeAlso: [
        '[Size](#Size)',
      ]
    },
    {
      name: 'Scroll',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'int',
          name: 'scrollDeltaXInPixels'
        },
        {
          type: 'int',
          name: 'scrollDeltaYInPixels'
        }
      ],
      description: `Scrolls the top-level document by the given delta in pixels. This method works by calling window.scrollBy(), which works for simple web pages but not for all pages. An alternative is to instead use [Scroll(Vector2, Vector2)](#Scroll) to scroll at a specific location in the page.`,
      example: `// Scroll down by 50 pixels.
webViewPrefab.WebView.Scroll(0, 50);

// Scroll to the left by 20 pixels.
webViewPrefab.WebView.Scroll(-20, 0);`,
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'Scroll',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'Vector2',
          name: 'scrollDelta'
        }
      ],
      description: `Like [Scroll(int, int)](#Scroll), but accepts a [normalized scroll delta](https://support.vuplex.com/articles/normalized-points) instead of values in pixels.`,
      example: `// Scroll down one quarter of the page.
webViewPrefab.WebView.Scroll(new Vector2(0, 0.25f));`,
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'Scroll',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'Vector2',
          name: 'scrollDelta'
        },
        {
          type: 'Vector2',
          name: 'point'
        }
      ],
      description: `Scrolls by the given [normalized scroll delta](https://support.vuplex.com/articles/normalized-points) at the given [normalized pointer position](https://support.vuplex.com/articles/normalized-points).`,
      example: `var webView = webViewPrefab.WebView;
// Scroll down by a quarter of the page in the center of the page
webView.Scroll(new Vector2(0, 0.25f), new Vector2(0.5f, 0.5f));

// Scroll to the right by 50 pixels at (100px, 1300px).
webView.Scroll(
    webView.PointToNormalized(50, 0),
    webView.PointToNormalized(100, 1300)
);`,
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'SelectAll',
      returns: 'void',
      type: 'method',
      description: `Selects all text, depending on the page's focused element.`,
      example: `webViewPrefab.WebView.SelectAll();`,
      seeAlso: [
        '[Copy()](#Copy)'
      ],
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'SendKey',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'key'
        }
      ],
      description: `Dispatches the given keyboard key to the webview.

A key can either be a single character representing a unicode character (e.g. "A", "b", "?") or a [JavaScript key value](https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values) (e.g. "ArrowUp", "Enter", "Backspace", "Delete"). If you need to trigger a modifier key (e.g. control, shift) or trigger keydown and keyup events separately, use the [IWithKeyDownAndUp](/webview/IWithKeyDownAndUp) interface instead.`,
      example: `// Type "Hi!" and then submit the Enter key.
webViewPrefab.WebView.SendKey("H");
webViewPrefab.WebView.SendKey("i");
webViewPrefab.WebView.SendKey("!");
webViewPrefab.WebView.SendKey("Enter");`,
      seeAlso: '[IWithKeyDownAndUp](/webview/IWithKeyDownAndUp)',
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'SetDefaultBackgroundEnabled',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: `By default, if a web page doesn't specify a background, 3D WebView sets the page's background to white because that's what web browsers typically do. However, an application can use this method to disable the default white background so that pages that don't set a background will instead have a transparent background. Note that this method must be called before a web page is loaded in order for it to take effect for that page.`,
      warnings: [`Nearly all of the 3D WebView packages support transparent webviews, but there are two exceptions:
- [3D WebView for Android with Gecko Engine](https://store.vuplex.com/webview/android-gecko) doesn't support transparency because the mobile Gecko browser engine [doesn't currently support it](https://bugzilla.mozilla.org/show_bug.cgi?id=1549943).
- [3D WebView for UWP](https://store.vuplex.com/webview/uwp) doesn't support transparency on mixed reality headsets like Hololens.`],
      example: `await webViewPrefab.WaitUntilInitialized();
// Disable the default white background so the page can be transparent.
webViewPrefab.WebView.SetDefaultBackgroundEnabled(false);`,
      seeAlso: '[How to make a webview transparent?](https://support.vuplex.com/articles/how-to-make-a-webview-transparent)'
    },
    {
      name: 'SetFocused',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'focused'
        }
      ],
      description: `Makes the webview take or relinquish focus.`,
      example: `webViewPrefab.WebView.SetFocused(true);`,
      warnings: [WEBGL_CROSS_ORIGIN_LIMITATION_WARNING],
    },
    {
      name: 'SetRenderingEnabled',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: `Enables or disables the webview's ability to render to its texture. By default, a webview renders web content to its texture, but you can use this method to disable or re-enable rendering.`,
      example: `webViewPrefab.WebView.SetRenderingEnabled(false);`,
      warnings: ['This method is ignored when running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).']
    },
    {
      name: 'StopLoad',
      returns: 'void',
      type: 'method',
      description: `Stops the current page load if one is in progress.`,
      example: `webViewPrefab.WebView.StopLoad();`,
    },
    {
      name: 'WaitForNextPageLoadToFinish',
      returns: 'Task',
      type: 'method',
      description: 'Returns a task that completes when the next page load finishes loading, or throws a PageLoadFailedException if the page load fails.',
      example: `await webViewPrefab.WaitUntilInitialized();
await webViewPrefab.WebView.WaitForNextPageLoadToFinish();
Debug.Log("The web page finished loading.");`,
      seeAlso: ['[LoadProgressChanged](#LoadProgressChanged)']
    },
    {
      name: 'ZoomIn',
      returns: 'void',
      type: 'method',
      description: `Zooms into the currently loaded web content. Note that the zoom level gets reset when a new page is loaded.`,
      example: `// Zoom in after the page finishes loading.
await webViewPrefab.WaitUntilInitialized();
await webViewPrefab.WebView.WaitForNextPageLoadToFinish();
webViewPrefab.WebView.ZoomIn();`,
      warnings: [
        'On Windows and macOS, adjusting the zoom also affects other webviews viewing the same site, similar to how tabs behave in a desktop browser.',
        WEBGL_NOT_SUPPORTED_WARNING
      ]
    },
    {
      name: 'ZoomOut',
      returns: 'void',
      type: 'method',
      description: "Zooms back out after a previous call to `ZoomIn()`. Note that the zoom level gets reset when a new page is loaded.",
      example: `// Zoom out after the page finishes loading.
await webViewPrefab.WaitUntilInitialized();
await webViewPrefab.WebView.WaitForNextPageLoadToFinish();
webViewPrefab.WebView.ZoomOut();`,
      warnings: [
        'On Windows and macOS, adjusting the zoom also affects other webviews viewing the same site, similar to how tabs behave in a desktop browser.',
        WEBGL_NOT_SUPPORTED_WARNING
      ]
    }
  ]
};
export default definition;
