import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'VisionOSWindowOptions',
  type: 'class',
  description: `Options that can be passed to [VisionOSWebView.CreateInWindow()](/webview/VisionOSWebView#CreateInWindow) or [VisionOSWindow.Open()](/webview/VisionOSWindow#Open) to customize the window's size and/or position.`,
  warnings: [
    `The VisionOSWindowsOptions.Size and Position options require visionOS 2.0. On visionOS 1.x, the Size and Position options are ignored, and a window always uses the system's default size and position.`,
    `visionOS 2.x has a bug where if the specified window position is relative to a bounded scene (e.g. RelativeWindowID = UnityBoundedSceneID), the window may not appear if the specified size is significantly smaller than the default size. For example, the window will appear using the default size or a size of 1000 x 1000 px, but may not appear using a size of 600 x 600 px. If you encounter an issue where the window isn't appearing, try using the default size or a larger size instead.`
  ],
  members: [
    {
      name: 'Size',
      returns: 'Vector2Int',
      type: 'property',
      description: `The window's initial size, in pixels. The default value is [Vector2Int.zero](https://docs.unity3d.com/ScriptReference/Vector2Int-zero.html), which causes the system's default window size (1280 x 720 px) to be used.`,
    },
    {
      name: 'Position',
      returns: 'VisionOSWindowPosition',
      type: 'property',
      description: `The window's initial position. The default value is \`null\`, which causes the system's default window position (in front of the user) to be used.`,
    },
  ]
};
export default definition;

