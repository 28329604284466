import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithFileSelection',
  type: 'interface',
  description: `An interface implemented by a webview if it supports file selection. This interface doesn't cause a file selection UI to automatically be shown, but the application can use this interface's [FileSelectionRequested](#FileSelectionRequested) event to detect when file selection is requested and then use a system API or third party asset to present the user with a file selection UI.`,
  example: `await webViewPrefab.WaitUntilInitialized();
var webViewWithFileSelection = webViewPrefab.WebView as IWithFileSelection;
if (webViewWithFileSelection != null) {
    webViewWithFileSelection.FileSelectionRequested += (sender, eventArgs) => {
        // Note: Here's where the application could use a system API or third party
        //       asset to show a file selection UI and then pass the selected file(s) to
        //       the Continue() callback.
        var filePaths = new string[] { "C:\\\\Users\\\\YourUser\\\\Desktop\\\\selected-file.txt" };
        eventArgs.Continue(filePaths);
    };
}`,
  members: [
    {
      name: 'FileSelectionRequested',
      type: 'event',
      returns: 'EventHandler<FileSelectionEventArgs>',
      description: "Indicates that the page requested a file selection dialog. This can happen, for example, when a file input is activated. Call the event args' [Continue(filePaths)](/webview/FileSelectionEventArgs#Continue) callback to provide a file selection or call [Cancel()](/webview/FileSelectionEventArgs#Continue) to cancel file selection."
    }
  ]
};
export default definition;
