import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ConsoleMessageEventArgs',
  type: 'class',
  description: 'Event args for [ConsoleMessageLogged](/webview/IWebView#ConsoleMessageLogged).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Level',
      returns: 'ConsoleMessageLevel',
      type: 'property',
      description: "The message's log level."
    },
    {
      name: 'Message',
      returns: 'string',
      type: 'property',
      description: 'The message logged to the JavaScript console.'
    },
    {
      name: 'Source',
      returns: 'string?',
      type: 'property',
      description: "The name of the file from which the message was logged, or `null` if the source is unknown."
    },
    {
      name: 'Line',
      returns: 'int',
      type: 'property',
      description: 'The line number of the file from which the message was logged, or `0` if the source is unknown.'
    }
  ]
};
export default definition;

