import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'VisionOSWindowPositionType',
  type: 'enum',
  description: `The position type for [VisionOSWindowPosition](/webview/VisionOSWindowPosition). This API mimics the native SwiftUI [WindowPlacement.Position](https://developer.apple.com/documentation/swiftui/windowplacement/position) structure with which it is implemented.`,
  members: [
    {
      name: 'UtilityPanel',
      type: 'value',
      description: `Utility panel window position. Utility panel window placement brings the window close, generally within direct touch range. This is the only VisionOSWindowPositionType for which a [RelativeWindowID](/webview/VisionOSWindowPosition#RelativeWindowID) value is not required.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow(
        new VisionOSWindowOptions {
            Size = new Vector2Int(600, 400),
            Position = new VisionOSWindowPosition(
                VisionOSWindowPositionType.UtilityPanel
            )
        }
    );
#endif`
    },
    {
      name: 'Above',
      type: 'value',
      description: `Window position relative to the top edge of another window or scene, specified via the [VisionOSWindowPosition constructor's](/webview/VisionOSWindowPosition#VisionOSWindowPosition) \`relativeWindowID\` parameter.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow(
        new VisionOSWindowOptions {
            Size = new Vector2Int(1000, 1000),
            Position = new VisionOSWindowPosition(
                VisionOSWindowPositionType.Above,
                VisionOSWindowPosition.UnityBoundedSceneID
            )
        }
    );
#endif`
    },
    {
      name: 'Below',
      type: 'value',
      description: `Window position relative to the bottom edge of another window or scene, specified via the [VisionOSWindowPosition constructor's](/webview/VisionOSWindowPosition#VisionOSWindowPosition) \`relativeWindowID\` parameter.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow(
        new VisionOSWindowOptions {
            Size = new Vector2Int(1000, 1000),
            Position = new VisionOSWindowPosition(
                VisionOSWindowPositionType.Below,
                VisionOSWindowPosition.UnityBoundedSceneID
            )
        }
    );
#endif`
    },
    {
      name: 'Leading',
      type: 'value',
      description: `Window position relative to the leading (left) edge of another window or scene, specified via the [VisionOSWindowPosition constructor's](/webview/VisionOSWindowPosition#VisionOSWindowPosition) \`relativeWindowID\` parameter.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow(
        new VisionOSWindowOptions {
            Size = new Vector2Int(1000, 1000),
            Position = new VisionOSWindowPosition(
                VisionOSWindowPositionType.Leading,
                VisionOSWindowPosition.UnityBoundedSceneID
            )
        }
    );
#endif`
    },
    {
      name: 'Trailing',
      type: 'value',
      description: `Window position relative to the trailing (right) edge of another window or scene, specified via the [VisionOSWindowPosition constructor's](/webview/VisionOSWindowPosition#VisionOSWindowPosition) \`relativeWindowID\` parameter.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow(
        new VisionOSWindowOptions {
            Size = new Vector2Int(1000, 1000),
            Position = new VisionOSWindowPosition(
                VisionOSWindowPositionType.Trailing,
                VisionOSWindowPosition.UnityBoundedSceneID
            )
        }
    );
#endif`
    },
  ]
};
export default definition;

