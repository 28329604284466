import React, { Component } from 'react';
import './styles.scss';

interface SpinnerProps {
  color?: string;
  size?: string;
  className?: string;
  style?: React.CSSProperties;
}

export default class Spinner extends Component<SpinnerProps> {

  render() {

    const color = this.props.color || '#12bae9';
    const outerSize = this.props.size || '80px';
    const innerSize = `calc(${outerSize} * 0.8)`;

    const innerDivStyles = {
      width: innerSize,
      height: innerSize,
      border: `calc(${outerSize} / 10) solid ${color}`,
      borderColor: `${color} transparent transparent transparent`,
      margin: `calc(${outerSize} / 10)`
    }

    return (
      <div className={`spinner ${this.props.className || ''}`} style={{ width: outerSize, height: outerSize, ...this.props.style }}>
        <div style={innerDivStyles}/>
        <div style={innerDivStyles}/>
        <div style={innerDivStyles}/>
        <div style={innerDivStyles}/>
      </div>
    );
  }
}
