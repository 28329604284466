import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ChromiumLogLevel',
  type: 'enum',
  description: 'Chromium log levels for [StandaloneWebView.SetChromiumLogLevel()](/webview/StandaloneWebView#SetChromiumLogLevel).',
  members: [
    {
      name: 'Verbose',
      type: 'value',
      description: ''
    },
    {
      name: 'Info',
      type: 'value',
      description: ''
    },
    {
      name: 'Warning',
      type: 'value',
      description: ''
    },
    {
      name: 'Error',
      type: 'value',
      description: ''
    },
    {
      name: 'Fatal',
      type: 'value',
      description: ''
    },
    {
      name: 'Disabled',
      type: 'value',
      description: ''
    },
  ]
};
export default definition;

