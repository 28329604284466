import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'StandaloneX509CertificatePrincipal',
  type: 'class',
  description: "The Issuer or Subject field of [StandaloneX509Certificate](/webview/StandaloneX509Certificate).",
  members: [
    {
      name: 'DisplayName',
      returns: 'string',
      type: 'property',
      description: "A name that can be used to represent the issuer. Chromium tries in this order: Common Name (CN), Organization Name (O) and Organizational Unit Name (OU) and returns the first non-empty one found."
    },
    {
      name: 'CommonName',
      returns: 'string',
      type: 'property',
      description: "The Common Name (CN) of the issuer or subject."
    },
    {
      name: 'LocalityName',
      returns: 'string',
      type: 'property',
      description: "The locality name."
    },
    {
      name: 'StateOrProvinceName',
      returns: 'string',
      type: 'property',
      description: "The state or province name."
    },
    {
      name: 'CountryName',
      returns: 'string',
      type: 'property',
      description: "The country name."
    },
  ]
};
export default definition;
