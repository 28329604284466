import Product from './Product';
import ProductId from './ProductId';

const storeBaseUrl = 'https://store.vuplex.com';

const getLink = (product: Product) => `[${product.name}](${product.storeUrl})`

export default class Products {

  static standalone: Product = {
    id: ProductId.UnityWebViewStandalone,
    name: '3D WebView for Windows and macOS',
    price: '149.99',
    nickname: 'Windows and macOS',
    storeUrl: `${storeBaseUrl}/webview/windows-mac`,
    get markdownLink() { return getLink(this); }
  };

  static android: Product = {
    id: ProductId.UnityWebViewAndroid,
    name: '3D WebView for Android',
    price: '179.99',
    nickname: 'Android',
    storeUrl: `${storeBaseUrl}/webview/android`,
    get markdownLink() { return getLink(this); }
  };

  static androidGecko: Product = {
    id: ProductId.UnityWebViewAndroidGecko,
    name: '3D WebView for Android with Gecko Engine',
    price: '179.99',
    nickname: 'Android Gecko',
    storeUrl: `${storeBaseUrl}/webview/android-gecko`,
    get markdownLink() { return getLink(this); }
  };

  static iOS: Product = {
    id: ProductId.UnityWebViewiOS,
    name: '3D WebView for iOS',
    price: '179.99',
    nickname: 'iOS',
    storeUrl: `${storeBaseUrl}/webview/ios`,
    get markdownLink() { return getLink(this); }
  };

  static uwp: Product = {
    id: ProductId.UnityWebViewUwp,
    name: '3D WebView for UWP / Hololens',
    price: '229.99',
    nickname: 'UWP',
    storeUrl: `${storeBaseUrl}/webview/uwp`,
    get markdownLink() { return getLink(this); }
  };

  static visionOS: Product = {
    id: ProductId.UnityWebViewVisionOS,
    name: '3D WebView for visionOS',
    price: '239.99',
    nickname: 'visionOS',
    storeUrl: `${storeBaseUrl}/webview/visionos`,
    get markdownLink() { return getLink(this); }
  };

  static webGL: Product = {
    id: ProductId.UnityWebViewWebGL,
    name: '2D WebView for WebGL',
    price: '119.99',
    nickname: 'WebGL',
    storeUrl: `${storeBaseUrl}/webview/webgl`,
    get markdownLink() { return getLink(this); }
  };

  static all: Product[] = [
    Products.standalone,
    Products.android,
    Products.androidGecko,
    Products.iOS,
    Products.visionOS,
    Products.uwp,
    Products.webGL
  ];

  static get(productId: ProductId | string): Product | null {

    return productMap[productId] || null;
  }
}

const productMap = Products.all.reduce((accumulator, product) => {
  accumulator[product.id] = product;
  return accumulator;
}, {});

/**
 * Resolves an Asset Store product name to an array of product IDs.
 * This method doesn't try to match an exact name, because I've changed
 * the product names over time (e.g. adding "(Web Browser)") to the end
 * of each product name.
 */
export function getProductIdsForAssetStoreProductName(productName: string): ProductId[] {

  if (productName.includes('3D WebView')) {
    if (productName.includes('Windows') && productName.includes('macOS')) {
      return [ProductId.UnityWebViewStandalone];
    }

    if (productName.includes('Android') && productName.includes('iOS')) {
      return [ProductId.UnityWebViewAndroid, ProductId.UnityWebViewiOS];
    }

    if (productName.includes('Android')) {
      if (productName.includes('Gecko')) {
        return [ProductId.UnityWebViewAndroidGecko];
      }
      return [ProductId.UnityWebViewAndroid];
    }

    if (productName.includes('iOS')) {
      return [ProductId.UnityWebViewiOS];
    }

    if (productName.includes('UWP')) {
      return [ProductId.UnityWebViewUwp];
    }

    if (productName.includes('visionOS')) {
      return [ProductId.UnityWebViewVisionOS];
    }

  } else if (productName.includes('2D WebView for WebGL')) {
    return [ProductId.UnityWebViewWebGL];
  }
  throw new Error(`Cannot determine the product ID for an unrecognized product name: ${productName}`);
}
