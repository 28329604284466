import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Products from '../../web-common/models/products/Products';
import '../../shared-styles/page.scss';
import './styles.scss';

export default class GettingStartedPage extends Component {

  render() {
    return (
      <div className="page getting-started">
        <Helmet>
          <title>Getting Started | 3D WebView</title>
          <meta name="description" content="How to get started with Vuplex 3D WebView for Unity."/>
        </Helmet>
        <h1>Getting started</h1>
        <ol>
          <li>
            First, purchase and import one or more 3D WebView packages:
            <ul>
              <li><a href={Products.standalone.storeUrl}>Windows and macOS</a></li>
              <li><a href={Products.android.storeUrl}>Android</a></li>
              <li><a href={Products.androidGecko.storeUrl}>Android Gecko</a> (<a href="https://support.vuplex.com/articles/android-comparison">comparison</a>)</li>
              <li><a href={Products.iOS.storeUrl}>iOS</a></li>
              <li><a href={Products.visionOS.storeUrl}>visionOS</a></li>
              <li><a href={Products.webGL.storeUrl}>WebGL</a></li>
              <li><a href={Products.uwp.storeUrl}>Universal Windows Platform (UWP)</a></li>
            </ul>
          </li>
          <li>
            Next, add an example scene to your build:
            <ul>
              <li>
                3D WebView's included demo scenes:
                <ul>
                  <li>SimpleWebViewDemo</li>
                  <li>CanvasWebViewDemo</li>
                  <li>CanvasWorldSpaceDemo</li>
                  <li>AdvancedWebViewDemo</li>
                  <li>PopupDemo</li>
                </ul>
              </li>
              <li>
                <a href="https://github.com/vuplex/oculus-webview-example">
                  Meta Quest example
                </a>
              </li>
              <li>
                <a href="https://github.com/vuplex/xrit-webview-example">
                  XR Interaction Toolkit example
                </a>
              </li>
              <li>
                <a href="https://github.com/vuplex/ar-foundation-webview-example">
                  AR Foundation example
                </a>
              </li>
              <li>
                visionOS examples for <a href="https://github.com/vuplex/visionos-metal-webview-example">Metal</a> and <a href="https://github.com/vuplex/visionos-realitykit-webview-example">RealityKit</a>
              </li>
              <li>
                <a href="https://github.com/vuplex/hololens-webview-example">
                  Hololens example (MRTK2)
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong id="windows">Windows settings:</strong>
            <ol type="a">
              <li>Set "Graphics APIs for Windows" to "Direct3D11".</li>
            </ol>
          </li>
          <li>
            <strong id="mac">macOS settings:</strong>
            <ol type="a">
              <li>Set "Graphics APIs for Mac" to "Metal".</li>
              <li>Verify that "Metal Editor Support" is enabled.</li>
            </ol>
          </li>
          <li>
            <strong id="android">Android settings:</strong>
            <ol type="a">
              <li>Set "Minimum API Level" to "Android 5.0 Lollipop" (or higher).</li>
              <li>Verify that the "Build System" is set to "Gradle" (default).</li>
            </ol>
          </li>
          <li>
            <strong id="ios">iOS settings:</strong> No changes to the Unity player settings are required for iOS.
          </li>
          <li>
            <strong id="visionos">visionOS settings:</strong> No changes to the Unity player settings are required for visionOS.
          </li>
          <li>
            <strong id="webgl">WebGL settings:</strong> No changes to the Unity player settings are required for WebGL.
          </li>
          <li>
            <strong id="uwp">Universal Windows Platform (UWP) settings:</strong>
            <ol type="a">
              <li>Set "Build Type" to "XAML Project" (this is required to support webviews).</li>
              <li>Disable "Auto Graphics API" and set "Graphics APIs" to "Direct3D11".</li>
              <li>If targeting Hololens 2, disable "Graphics Jobs".</li>
              <li>In "Publishing Settings", enable the following capabilities: "InternetClient", "SpatialPerception".</li>
            </ol>
          </li>
          <li>
            Now you can run the example scene in the editor or build and deploy it to your device!
          </li>
        </ol>
        <h2>Next steps</h2>
        <ul>
          <li>
            Check out the documentation for more details on loading and interacting with web content:
            <ul>
              <li>
                <Link to="/webview/IWebView">IWebView</Link>
              </li>
              <li>
                <Link to="/webview/WebViewPrefab">WebViewPrefab</Link>
              </li>
              <li>
                <Link to="/webview/CanvasWebViewPrefab">CanvasWebViewPrefab</Link>
              </li>
              <li>
                <Link to="/webview/Web">Web</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/webview/troubleshooting">Troubleshooting tips</Link>
          </li>
          <li>
            Has 3D WebView been helpful? If so, please take a minute to <a href="https://assetstore.unity.com/packages/tools/gui/3d-webview-for-windows-and-macos-154144#reviews">rate it on the Asset Store</a>! It doesn't have many ratings, so leaving one for me would have a huge impact, and it would mean a lot to me:
            <ul>
              <li>
                <a href="https://assetstore.unity.com/packages/tools/gui/3d-webview-for-windows-and-macos-154144#reviews">Windows and macOS</a>
              </li>
              <li>
                <a href="https://assetstore.unity.com/packages/tools/gui/3d-webview-for-android-137030#reviews">Android</a>
              </li>
              <li>
                <a href="https://assetstore.unity.com/packages/tools/gui/3d-webview-for-android-with-gecko-engine-158778#reviews">Android Gecko</a>
              </li>
              <li>
                <a href="https://assetstore.unity.com/packages/tools/gui/3d-webview-for-android-and-ios-135383#reviews">Android and iOS</a>
              </li>
              <li>
                <a href="https://assetstore.unity.com/packages/tools/gui/3d-webview-for-ios-137040#reviews">iOS</a>
              </li>
              <li>
                <a href="https://assetstore.unity.com/packages/tools/gui/2d-webview-for-webgl-web-browser-iframe-202522#reviews">WebGL</a>
              </li>
              <li>
                <a href="https://assetstore.unity.com/packages/tools/gui/3d-webview-for-uwp-hololens-166365#reviews">UWP</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}
