import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithIme',
  type: 'interface',
  description: "An interface implemented by a webview if it supports Input Method Editor (IME) for inputting Chinese, Japanese, and Korean text. On Windows and macOS, if keyboard support is enabled via WebViewPrefab.KeyboardEnabled, then IME is automatically enabled (implemented with this interface). For an example of using this interface, please see 3D WebView's KeyboardManager.cs script.",
  members: [
    {
      name: 'ImeInputFieldPositionChanged',
      type: 'event',
      returns: 'EventHandler<EventArgs<Vector2Int>>',
      description: 'Indicates that the coordinates of the IME text composition within the browser changed.'
    },
    {
      name: 'CancelImeComposition',
      type: 'method',
      returns: 'void',
      description: 'Cancels the current IME composition.'
    },
    {
      name: 'FinishImeComposition',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'text',
          type: 'string'
        }
      ],
      description: 'Finishes the current IME composition with the given text.'
    },
    {
      name: 'SetImeComposition',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'text',
          type: 'string'
        }
      ],
      description: "Updates the current IME composition with the given text, or starts a new composition if one isn't already in progress."
    },
  ]

};
export default definition;
