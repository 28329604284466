import Products from '../../web-common/models/products/Products';
import CodeFileMember from '../CodeFileMember';

export function getSharedPrefabDescription(name: string) {

  return `There are two ways to create a ${name}:

1. By dragging the ${name}.prefab file into your scene via the editor and setting its "Initial URL" property.
2. Or by creating an instance programmatically with [${name}.Instantiate()](#Instantiate), waiting for
it to [initialize](#Initialized), and then calling methods on its [WebView](#WebView) property, like [LoadUrl()](/webview/IWebView#LoadUrl()).

If your use case requires a high degree of customization, you can instead create an IWebView
outside of the prefab with [Web.CreateWebView()](/webview/Web#CreateWebView).`
}

export function getSharedPrefabMembers(canvasPrefab=false): CodeFileMember[] {

  const className = canvasPrefab ? 'CanvasWebViewPrefab' : 'WebViewPrefab';
  const instanceName = canvasPrefab ? 'canvasWebViewPrefab' : 'webViewPrefab';
  return [
    {
      name: 'Clicked',
      returns: 'EventHandler<ClickedEventArgs>',
      type: 'event',
      description: 'Indicates that the prefab was clicked. Note that the prefab automatically calls [IWebView.Click()](/webview/IWebView#Click) for you.',
      example: `${instanceName}.Clicked += (sender, eventArgs) => {
    Debug.Log("WebViewPrefab was clicked at point: " + eventArgs.Point);
};`,
      warnings: canvasPrefab ? ['This event is not supported when running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).'] : undefined
    },
    {
      name: 'Initialized',
      returns: 'EventHandler',
      type: 'event',
      description: "Indicates that the prefab finished initializing, so its [WebView](#WebView) property is ready to use.",
      seeAlso: '[WaitUntilInitialized()](#WaitUntilInitialized)'
    },
    {
      name: 'PointerEntered',
      returns: 'EventHandler',
      type: 'event',
      description: "Indicates that the pointer (e.g. mouse cursor) entered the prefab.",
    },
    {
      name: 'PointerExited',
      returns: 'EventHandler',
      type: 'event',
      description: "Indicates that the pointer (e.g. mouse cursor) exited the prefab.",
    },
    {
      name: 'Scrolled',
      returns: 'EventHandler<ScrolledEventArgs>',
      type: 'event',
      description: 'Indicates that the prefab was scrolled. Note that the prefab automatically calls [IWebView.Scroll()](/webview/IWebView#Scroll) for you.',
      example: `${instanceName}.Scrolled += (sender, eventArgs) => {
    Debug.Log($"WebViewPrefab was scrolled. Point: {eventArgs.Point}, scroll delta: {eventArgs.ScrollDelta}");
};`,
      warnings: canvasPrefab ? ['This event is not supported when running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).'] : undefined
    },
    {
      name: 'ClickingEnabled',
      returns: 'bool',
      type: 'property',
      description: 'Determines whether clicking is enabled. The default is `true`.',
      warnings: canvasPrefab ? ['This property is ignored when running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).'] : undefined
    },
    {
      name: 'CursorIconsEnabled',
      returns: 'bool',
      type: 'property',
      description: 'Determines whether the mouse cursor icon is automatically updated based on interaction with the web page. For example, hovering over a link causes the mouse cursor icon to turn into a pointer hand. The default is `true`. CursorIconsEnabled is currently only supported by 3D WebView for Windows and macOS.',
      seeAlso: '[IWithCursorType](/webview/IWithCursorType)'
    },
    {
      name: 'DragThreshold',
      returns: 'float',
      type: 'property',
      description: `Determines the threshold (in web pixels) for triggering a drag. The default is \`20\`.
- When the prefab's [DragMode](#DragMode) is
set to [DragToScroll](/webview/DragMode#DragToScroll),
this property determines the distance that the pointer must drag before it's no longer considered a click.

- When the prefab's [DragMode](#DragMode) is
set to [DragWithinPage](/webview/DragMode#DragWithinPage),
this property determines the distance that the pointer must drag before it triggers a drag within the page.`,
      warnings: canvasPrefab ? ['This property is ignored when running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).'] : undefined
    },
    {
      name: 'DragMode',
      returns: 'DragMode',
      type: 'property',
      description: 'Determines how the prefab handles drag interactions.',
      warnings: [
        'This property is ignored when running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).',
        'For information on the limitations of drag interactions on iOS and UWP, please see [this article](https://support.vuplex.com/articles/hover-and-drag-limitations).',
        `The [Android Gecko](${Products.androidGecko.storeUrl}) package doesn't support the HTML Drag and Drop API ([GeckoView limitation](https://caniuse.com/dragndrop#:~:text=4-,Firefox%20for%20Android)).`
      ],
      seeAlso: '[When I drag a scrollbar, why does it scroll the wrong way?](https://support.vuplex.com/articles/dragging-scrollbar)'
    },
    {
      name: 'HoveringEnabled',
      returns: 'bool',
      type: 'property',
      description: 'Determines whether hover interactions are enabled. The default is `true`.',
      warnings: [
        'This property is ignored when running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).',
        "For information on the limitations of hovering on iOS and UWP, please see [this article](https://support.vuplex.com/articles/hover-and-drag-limitations)."
      ]
    },
    {
      name: 'InitialUrl',
      returns: 'string',
      type: 'property',
      description: `If you drag a ${className}.prefab into the scene via the editor, you can set this property in the editor to make it so that the instance automatically loads the given URL after it initializes. To load a new URL at runtime, use [IWebView.LoadUrl()](/webview/IWebView#LoadUrl) instead.`,
      seeAlso: '[How to load local files](https://support.vuplex.com/articles/how-to-load-local-files)',
    },
    {
      name: 'KeyboardEnabled',
      returns: 'bool',
      type: 'property',
      description: 'Determines whether the webview automatically receives keyboard input from the native keyboard and the Keyboard prefab. The default is `true`.',
      seeAlso: [
        '[NativeOnScreenKeyboardEnabled](#NativeOnScreenKeyboardEnabled)',
        '[How does keyboard input work?](https://developer.vuplex.com/webview/Keyboard)'
      ]
    },
    {
      name: 'LogConsoleMessages',
      returns: 'bool',
      type: 'property',
      description: 'Determines whether JavaScript console messages from [IWebView.ConsoleMessageLogged](/webview/IWebView#ConsoleMessageLogged) are printed to the Unity logs. The default is `false`.',
    },
    {
      name: 'Material',
      returns: 'Material',
      type: 'property',
      propertyAccessors: { get: true, set: true },
      description: "Gets or sets the prefab's material.",
      warnings: canvasPrefab ? ['This property is unused when running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).'] : undefined
    },
    {
      name: 'NativeOnScreenKeyboardEnabled',
      returns: 'bool',
      type: 'property',
      description: `Determines whether the operating system's native on-screen keyboard is automatically shown when a text input in the webview is focused. The default for ${className} is \`${canvasPrefab ? 'true' : 'false'}\`.`,
      warnings: [
`The native on-screen keyboard is only supported for the following packages:
- ${Products.android.markdownLink} (non-Gecko)
- ${Products.iOS.markdownLink}
- ${Products.visionOS.markdownLink}`,
        `${Products.androidGecko.markdownLink} doesn't support automatically showing the native on-screen keyboard, but you can use Unity's [TouchScreenKeyboard](https://docs.unity3d.com/ScriptReference/TouchScreenKeyboard.html) API to show the keyboard and then send typed characters to the webview like described in [this article](https://support.vuplex.com/articles/how-to-use-a-third-party-keyboard).`,
      ],
      seeAlso: [
        '[IWithNativeOnScreenKeyboard](/webview/IWithNativeOnScreenKeyboard)',
        '[KeyboardEnabled](#KeyboardEnabled)'
      ]
    },
    {
      name: 'PixelDensity',
      returns: 'float',
      type: 'property',
      description: "Sets the webview's pixel density, which is its number of physical pixels per logical pixel. The default value is `1`, but increasing it to `2` can make web content appear sharper or less blurry on high DPI displays. PixelDensity is currently only supported by  3D WebView for Windows and macOS.",
      example: `// Increase the pixel density to 2 for high DPI screens.
${instanceName}.PixelDensity = 2;`,
      seeAlso: '[IWithPixelDensity](/webview/IWithPixelDensity)'
    },
    {
      name: 'RemoteDebuggingEnabled',
      returns: 'bool',
      type: 'property',
      description: 'Determines whether the prefab enables remote debugging by calling [Web.EnableRemoteDebugging()](/webview/Web#EnableRemoteDebugging). The default is `false`.'
    },
    {
      name: 'Resolution',
      returns: 'float',
      type: 'property',
      description: `Gets or sets the prefab's resolution in pixels per Unity unit. You can change the resolution to make web content appear larger or smaller. The default resolution for ${canvasPrefab ? 'CanvasWebViewPrefab is `1`' : 'WebViewPrefab is `1300`'}.

Setting a lower resolution decreases the pixel density, but has the effect of making web content appear larger. Setting a higher resolution increases the pixel density, but has the effect of making content appear smaller. For more information on scaling web content, see [this support article](https://support.vuplex.com/articles/how-to-scale-web-content).`,
      example: `// Set the resolution to ${canvasPrefab ? '2.5' : '1800'}px per Unity unit.
webViewPrefab.Resolution = ${canvasPrefab ? '2.5f' : '1800'};`,
      warnings: canvasPrefab ? ["When running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode), the Resolution field isn't used because the device's native resolution is used instead. So, the Resolution field's value is inaccurate and changes to it are ignored."] : undefined
    },
    {
      name: 'ScrollingEnabled',
      returns: 'bool',
      type: 'property',
      description: 'Determines whether scrolling is enabled. The default is `true`.',
      warnings: canvasPrefab ? ['This property is ignored when running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).'] : undefined
    },
    {
      name: 'ScrollingSensitivity',
      returns: 'float',
      type: 'property',
      description: `Determines the scroll sensitivity. The default sensitivity for ${canvasPrefab ? 'CanvasWebViewPrefab is `15`' : 'WebViewPrefab is `0.005`'}.`,
      warnings: canvasPrefab ? ['This property is ignored when running in [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode).'] : undefined
    },
    {
      name: 'BrowserToScreenPoint',
      returns: 'Vector2',
      type: 'method',
      arguments: [
        { name: 'xInPixels', type: 'int' },
        { name: 'yInPixels', type: 'int' },
      ],
      description: `Converts the given point from web browser viewport coordinates to the corresponding Unity screen space coordinates of where the point is rendered in the application window. Note that web browser coordinates (the input parameters) treat the top left corner of the browser viewport as the (0, 0) origin. In contrast, Unity's screen space coordinates (the return value) treat the bottom left corner of the application's window as the (0, 0) origin.`,
      example: `var screenPoint = webViewPrefab.BrowserToSreenPoint(200, 300);
Debug.Log($"Point (200px, 300px) within the browser window is rendered at point {screenPoint} on the device screen.");`
    },
    {
      name: 'SetPointerInputDetector',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          name: 'pointerInputDetector',
          type: 'IPointerInputDetector'
        }
      ],
      description: "By default, the prefab detects pointer input events like clicks through Unity's event system, but you can use this method to override the way that input events are detected.",
      example: canvasPrefab ? 'canvasWebViewPrefab.SetPointerInputDetector(yourCustomInputDetector);' : `var yourCustomInputDetector = webViewPrefab.Collider.AddComponent<YourCustomInputDetector>();
webViewPrefab.SetPointerInputDetector(yourCustomInputDetector);`
    },
    {
      name: 'SetOptionsForInitialization',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          name: 'options',
          type: 'WebViewOptions'
        }
      ],
      description: "Sets options that can be used to alter the webview that the prefab creates during initialization. This method can only be called prior to when the prefab initializes (i.e. directly after instantiating it or setting it to active).",
      example: `using System;
using UnityEngine;
using Vuplex.WebView;

class SetOptions : MonoBehaviour {

    // IMPORTANT: With this approach, you must set your WebViewPrefab or CanvasWebViewPrefab to inactive
    //            in the scene hierarchy so that this script can manually activate it. Otherwise, this
    //            script won't be able to call SetOptionsForInitialization() before the prefab initializes.
    //
    // TODO: Set this webViewPrefab field to the inactive WebViewPrefab or CanvasWebViewPrefab in your scene
    //       via the Editor's Inspector tab.
    public BaseWebViewPrefab webViewPrefab;

    void Awake() {

        if (webViewPrefab.gameObject.activeInHierarchy) {
            throw new Exception("The WebViewPrefab object is active in the Editor's scene view. Please set it to inactive so that this script can manually activate it.");
        }
        webViewPrefab.gameObject.SetActive(true);

        webViewPrefab.SetOptionsForInitialization(new WebViewOptions {
            preferredPlugins = new WebPluginType[] { WebPluginType.Android }
        });
    }
}`
    },
    {
      name: 'SetWebViewForInitialization',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          name: 'webView',
          type: 'IWebView'
        }
      ],
      description: "By default, the prefab creates a new IWebView during initialization. However, you can call this method before the prefab initializes to pass it an existing, initialized IWebView to use instead. This method can only be called prior to when the prefab initializes (i.e. directly after instantiating it or setting it to active)."
    },
    {
      name: 'Visible',
      returns: 'bool',
      type: 'property',
      propertyAccessors: { get: true, set: true },
      description: "Gets or sets whether the instance is visible and responds to raycasts. The default is `true`. If you want to hide the webview, setting this property to false is often preferable to disabling the prefab's GameObject, because the latter stops the prefab's scripts from running, which may prevent it from initializating, for example."
    },
    {
      name: 'WebView',
      returns: 'IWebView?',
      type: 'property',
      propertyAccessors: { get: true },
      description: "Returns the prefab's IWebView instance, or `null` if the prefab hasn't finished initializing yet. To detect when the WebView property is no longer null,  please use [WaitUntilInitialized()](#WaitUntilInitialized).",
      example: `await webViewPrefab.WaitUntilInitialized();
// Now the WebView property is ready.
webViewPrefab.WebView.LoadUrl("https://vuplex.com");`
    },
    {
      name: 'Destroy',
      returns: 'void',
      type: 'method',
      description: `Destroys the instance and its children. Note that you don't need to call this method if you destroy the instance's parent with [Object.Destroy()](https://docs.unity3d.com/ScriptReference/Object.Destroy.html).`,
      example: `// The webview can no longer be used after it's destroyed.
${instanceName}.Destroy();`
    },
    {
      name: 'Instantiate',
      returns: className,
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'IWebView',
          name: 'webView'
        }
      ],
      description: `Like [Instantiate(${canvasPrefab ? '' : 'float, float'})](#Instantiate), except it initializes the instance with an existing, initialized IWebView instance. This causes the ${className} to use the existing IWebView instance instead of creating a new one. This can be used, for example, to create multiple ${className}s that are connected to the same IWebView, or to create a prefab for an IWebView created by [IWithPopups.PopupRequested](/webview/IWithPopups).`,
      example: `await firstWebViewPrefab.WaitUntilInitialized();
var secondWebViewPrefab = ${className}.Instantiate(firstWebViewPrefab.WebView);
// TODO: Position secondWebViewPrefab to the location where you want to display it.`
    },
    {
      name: 'WaitUntilInitialized',
      returns: 'Task',
      type: 'method',
      description: "Returns a task that completes when the prefab is initialized, which means that its [WebView](#WebView) property is ready for use.",
      example: `await ${instanceName}.WaitUntilInitialized();
// Now the WebView property is ready.
${instanceName}.WebView.LoadUrl("https://vuplex.com");`,
      seeAlso: '[Initialized](#Initialized)',
    },
  ];
}
