import React, { Component } from 'react';
import accountImage from './assets/account.svg';
import '../../../styles/button-reset.scss';
import Urls from '../../../config/Urls';
import SessionStorage from '../../../client/SessionStorage';
import './styles.scss';

export default class AccountButton extends Component {

  state = {
    dropdownOpen: false
  };

  render() {
    return (
      <div onClick={this._handleClick} className="account-btn">
        <button className="button-reset icon-container">
          <img src={accountImage} alt="account"/>
        </button>
        {this._renderDropdown()}
      </div>
    )
  }

  private _handleClick = () => {

    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  private _handleLogOutClicked = () => {

    SessionStorage.clearSession();
    location.reload();
  }

  private _renderDropdown() {

    if (!this.state.dropdownOpen) {
      return;
    }
    return (
      <div className="dropdown">
        <div className="click-detector"/>
        <a href={Urls.Dashboard}>Dashboard</a>
        {SessionStorage.getSession() && <a onClick={this._handleLogOutClicked}>Log out</a>}
      </div>
    )
  }
}
