import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'FindResult',
  type: 'struct',
  description: "The result of a call to [IWithFind.Find()](/webview/IWithFind#Find).",
  members: [
    {
      name: 'CurrentMatchIndex',
      returns: 'int',
      type: 'property',
      description: "The index of the current highlighted match."
    },
    {
      name: 'MatchCount',
      returns: 'int',
      type: 'property',
      description: "The total number of matches for the given text."
    },
  ]
};
export default definition;
