import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'PointerOptions',
  type: 'class',
  description: "Options to alter pointer methods, like [PointerUp()](/webview/IWithPointerDownAndUp#PointerUp) and [PointerDown()](/webview/IWithPointerDownAndUp#PointerDown).",
  members: [
    {
      name: 'Button',
      returns: 'MouseButton',
      type: 'property',
      description: "The button for the event. The default is [MouseButton.Left](/webview/MouseButton#Left)."
    },
    {
      name: 'ClickCount',
      returns: 'int',
      type: 'property',
      description: "The number of clicks for the event. For example, for a double click, set this value to `2`. The default value is `1`."
    },
    {
      name: 'PreventStealingFocus',
      returns: 'bool',
      type: 'property',
      description: "Whether to prevent the click event from stealing focus from the currently focused webview. The default value is `false`."
    },
  ]
};
export default definition;
