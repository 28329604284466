import React, { Component } from 'react';
import { CodeExample } from '../../models/CodeFileMember';
import './styles.scss';

export default class CodeExampleBox extends Component<{ example: string|CodeExample }> {

  render() {

    const { example } = this.props;
    let title;
    let code = '';
    if (typeof example === 'string') {
      code = example.trim();
    } else {
      title = example.title;
      code = example.code.trim();
    }

    return (
      <div className="code-example-box">
        <h4>{title || 'Example'}</h4>
        <pre>
          <code>{code}</code>
        </pre>
      </div>
    );
  }
}
