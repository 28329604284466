import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'TerminatedEventArgs',
  type: 'class',
  description: 'Event args for the [IWebView.Terminated](/webview/IWebView#Terminated) event.',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Type',
      returns: 'TerminationType',
      type: 'property',
      description: 'Indicates the cause of the termination.'
    }
  ]
};
export default definition;

