import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CodeExampleBox from './CodeExampleBox';
import highlightCode from '../utils/highlightCode';

export default class AdditionalInterfacesPage extends Component<{ additionalInterfaceNames: string[] }> {

  componentDidMount() {

    highlightCode(this._componentElement);
  }

  render() {
    return (
      <div className="page" ref={this._saveRef}>
        <Helmet>
          <title>Additional feature interfaces</title>
          <meta name="description" content="Description of 3D WebView's additional feature interfaces."/>
        </Helmet>
        <h1>Advanced feature interfaces</h1>
        <p>
          The <Link to="/webview/IWebView">IWebView</Link> interface contains the base webview functionality that is implemented on all platforms. However, there are also additional interfaces that you can use to access advanced functionality on a subset of platforms:
        </p>
        <ul>
          {this.props.additionalInterfaceNames.map(this._renderInterfaceName)}
        </ul>
        <p>
          Each interface's documentation lists the platforms that support it. To use an interface, cast the IWebView instance to it. If the platform supports the interface, then the cast will succeed and you can call the interface's methods.
        </p>
        <CodeExampleBox example={
`await webViewPrefab.WaitUntilInitialized();
var webViewWithPopups = webViewPrefab.WebView as IWithPopups;
if (webViewWithPopups != null) {
  webViewWithPopups.SetPopupMode(PopupMode.NotifyWithoutLoading);
  webViewWithPopups.PopupRequested += (sender, eventArgs) => {
    Debug.Log("Popup requested: " + eventArgs.Url);
  };
}`}/>
      </div>
    );
  }

  private _componentElement = null as HTMLElement | null;

  private _renderInterfaceName = (interfaceName: string) => (
    <li key={interfaceName}>
      <Link to={`/webview/${interfaceName}`}>{interfaceName}</Link>
    </li>
  );

  private _saveRef = (componentElement) => this._componentElement = componentElement;
}
