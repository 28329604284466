import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithFind',
  type: 'interface',
  description: `An interface implemented by a webview if it supports finding text in the page.`,
  example: `await webViewPrefab.WaitUntilInitialized;
// Search for the word "and" in the page.
var webViewWithFind = webViewPrefab.WebView as IWithFind;
if (webViewWithFind == null) {
    Debug.Log("This 3D WebView plugin doesn't yet support IWithFind: " + webViewPrefab.WebView.PluginType);
    return;
}
var result = await webViewWithFind.Find("and", true);
Debug.Log($"Number of matches: {result.MatchCount}. Index of current match: {result.CurrentMatchIndex}");
if (result.MatchCount > 1) {
    // Later, scroll to the next instance of "and" in the page.
    await webViewWithFind.Find("and", true);
    // Later, go back to the first match.
    await webViewWithFind.Find("and", false);
}
// Later, clear all matches.
webViewWithFind.ClearFindMatches();`,
  members: [
    {
      name: 'ClearFindMatches',
      type: 'method',
      returns: 'void',
      description: "Clears the visual indicator of matches triggered by a previous call to [Find()](#Find)."
    },
    {
      name: 'Find',
      type: 'method',
      returns: 'Task<FindResult>',
      arguments: [
        { name: 'text', type: 'string' },
        { name: 'forward', type: 'bool' },
      ],
      description: "Finds the given text in the page. If the page contains a match for the given text, then the browser scrolls to and highlights that match. If the returned FindResult indicates that there are matches, then the application can call Find() again with the same text to scroll to the next or previous match, as determined by the `forward` parameter. Highlighted matches can be cleared by calling [ClearFindMatches()](#ClearFindMatches)."
    },
  ]

};
export default definition;
