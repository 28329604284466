import FileReferenceDefinition from '../FileReferenceDefinition';
import Products from '../../web-common/models/products/Products';

const definition: FileReferenceDefinition = {
  name: 'IWithNativeOnScreenKeyboard',
  type: 'interface',
  description: 'An interface implemented by a webview if it supports using a native on-screen keyboard.',
  seeAlso: [
    '[CanvasWebViewPrefab.NativeOnScreenKeyboardEnabled](/webview/CanvasWebViewPrefab#NativeOnScreenKeyboardEnabled)',
    '[WebViewPrefab.NativeOnScreenKeyboardEnabled](/webview/WebViewPrefab#NativeOnScreenKeyboardEnabled)',
  ],
  members: [
    {
      name: 'SetNativeOnScreenKeyboardEnabled',
      type: 'method',
      returns: 'void',
      description: 'Enables or disables the native on-screen keyboard.',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      warnings: [
`The native on-screen keyboard is only supported for the following packages:
- [3D WebView for Android](${Products.android.storeUrl})
- [3D WebView for iOS](${Products.iOS.storeUrl})`,
        'On iOS, disabling the keyboard for one webview disables it for all webviews.'
      ]
    }
  ]

};
export default definition;
