import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'MouseButton',
  type: 'enum',
  description: `Enum for the different mouse buttons.

This enum's values are compatible with those from
the UnityEngine.EventSystem [\`InputButton\`](https://docs.unity3d.com/Packages/com.unity.ugui@1.0/api/UnityEngine.EventSystems.PointerEventData.InputButton.html) enum,
so you can safely cast an \`InputButton\` to a \`MouseButton\`.
  `,
  members: [
    {
      name: 'Left',
      type: 'value',
      description: 'The left mouse button (i.e. left click).'
    },
    {
      name: 'Right',
      type: 'value',
      description: 'The right mouse button (i.e. right click).'
    },
    {
      name: 'Middle',
      type: 'value',
      description: 'The center mouse button.'
    }
  ]
};
export default definition;
