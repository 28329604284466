import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'DragMode',
  type: 'enum',
  description: "Options for how drag interactions affect WebViewPrefab and CanvasWebViewPrefab.",
  seeAlso: [
    '[WebViewPrefab.DragMode](/webview/WebViewPrefab#DragMode)',
    '[CanvasWebViewPrefab.DragMode](/webview/CanvasWebViewPrefab#DragMode)',
  ],
  members: [
    {
      name: 'DragToScroll',
      type: 'value',
      description: "Drag interactions trigger scrolling (default). On platforms that support [IWithTouch](/webview/IWithTouch), touch events are used instead of mouse events. On platforms that don't support IWithTouch, mouse events are used, but dragging causes the page to be scrolled with [IWebView.Scroll()](/webview/IWebView#Scroll)."
    },
    {
      name: 'DragWithinPage',
      type: 'value',
      description: 'Drag interactions trigger dragging within the web page via mouse events (e.g. dragging to select text, drag-and-drop).',
      warnings: ["For information on the limitations of drag interactions on iOS and UWP, please see [this page](https://support.vuplex.com/articles/hover-and-drag-limitations)."]
    },
    {
      name: 'Disabled',
      type: 'value',
      description: 'Drag interactions have no effect.'
    },
  ]
};
export default definition;

