import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithPointerDownAndUp',
  type: 'interface',
  description: 'An interface implemented by a webview if it supports [PointerDown()](#PointerDown) and [PointerUp()](#PointerUp), which can be used to implement functionality like drag interactions, double-clicks, and right-clicks.',
  example: `var webViewWithPointerDownAndUp = webViewPrefab.WebView as IWithPointerDownAndUp;
if (webViewWithPointerDownAndUp != null) {
    // Double right click at (250px, 100px) in the web page.
    var normalizedPoint = webViewPrefab.WebView.PointToNormalized(250, 100);
    var pointerOptions = new PointerOptions {
        Button = MouseButton.Right,
        ClickCount = 2
    };
    webViewWithPointerDownAndUp.PointerDown(normalizedPoint, pointerOptions);
    webViewWithPointerDownAndUp.PointerUp(normalizedPoint, pointerOptions);
}`,
  warnings: [
    `For details on the limitations of hover and drag interactions on iOS and UWP, please see [this page](https://support.vuplex.com/articles/hover-and-drag-limitations).`,
  ],
  members: [
    {
      name: 'PointerDown',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'Vector2',
          name: 'point'
        }
      ],
      description: `Dispatches a [pointerdown](https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/pointerdown_event) / [mousedown](https://developer.mozilla.org/en-US/docs/Web/API/Element/mousedown_event) click event at the given [normalized point](https://support.vuplex.com/articles/normalized-points). This can be used in conjunction with [IWithMovablePointer.MovePointer()](/webview/IWithMovablePointer#MovePointer) and [PointerUp()](#PointerUp) to implement drag interactions.`
    },
    {
      name: 'PointerDown',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'Vector2',
          name: 'point'
        },
        {
          type: 'PointerOptions',
          name: 'options'
        }
      ],
      description: 'Like PointerDown(Vector2), except it also accepts a PointerOptions parameter to modify the behavior (e.g. to trigger a right click or a double click).'
    },
    {
      name: 'PointerUp',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'Vector2',
          name: 'point'
        }
      ],
      description: `Dispatches a [pointerup](https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/pointerup_event) / [mouseup](https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseup_event) click event at the given [normalized point](https://support.vuplex.com/articles/normalized-points). This can be used in conjunction with [PointerDown()](#PointerDown) and [IWithMovablePointer.MovePointer()](/webview/IWithMovablePointer#MovePointer) and to implement drag interactions.`
    },
    {
      name: 'PointerUp',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'Vector2',
          name: 'point'
        },
        {
          type: 'PointerOptions',
          name: 'options'
        }
      ],
      description: 'Like PointerUp(Vector2), except it also accepts a `PointerOptions` parameter to modify the behavior (e.g. to trigger a right click or a double click).'
    },
  ]
};
export default definition;
