import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithAuth',
  type: 'interface',
  description: `An interface implemented by a webview if it supports entering credentials for [HTTP authentication](https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication) and/or connecting to a proxy server.`,
  members: [
    {
      name: 'AuthRequested',
      type: 'event',
      returns: 'EventHandler<AuthRequestedEventArgs>',
      description: `Indicates that the browser has requested credentials from the user, either because a server requested [HTTP authentication](https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication) or because credentials are needed for connecting to a proxy server.

If no handler is attached to this event, then the host's authentication request will be ignored and the page will not be paused. If a handler is attached to this event, then the page will be paused until Continue() or Cancel() is called.

You can test basic HTTP auth using [this page](https://jigsaw.w3.org/HTTP/Basic/) with the username "guest" and the password "guest".

This event is not raised for most websites because most sites implement a custom sign-in page instead of using HTTP authentication to show the browser's built-in authentication UI.`,
      example: `await webViewPrefab.WaitUntilInitialized();
var webViewWithAuth = webViewPrefab.WebView as IWithAuth;
if (webViewWithAuth == null) {
    Debug.Log("This 3D WebView plugin doesn't yet support IWithAuth: " + webViewPrefab.WebView.PluginType);
    return;
}
webViewWithAuth.AuthRequested += (sender, eventArgs) => {
    Debug.Log("Auth requested by " + eventArgs.Host);
    eventArgs.Continue("myUsername", "myPassword");
};`,
    }
  ]

};
export default definition;
