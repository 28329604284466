import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithDownloads',
  type: 'interface',
  description: 'An interface implemented by a webview if it supports file downloads. When downloads are enabled enabled, files are downloaded to [Application.temporaryCachePath](https://docs.unity3d.com/ScriptReference/Application-temporaryCachePath.html), but you can move them to a different location after they finish downloading.',
  example: `await webViewPrefab.WaitUntilInitialized();
var webViewWithDownloads = webViewPrefab.WebView as IWithDownloads;
if (webViewWithDownloads == null) {
    Debug.Log("This 3D WebView plugin doesn't yet support IWithDownloads: " + webViewPrefab.WebView.PluginType);
    return;
}
webViewWithDownloads.SetDownloadsEnabled(true);
webViewWithDownloads.DownloadProgressChanged += (sender, eventArgs) => {
    Debug.Log(
        $@"DownloadProgressChanged:
        Type: {eventArgs.Type},
        Url: {eventArgs.Url},
        Progress: {eventArgs.Progress},
        Id: {eventArgs.Id},
        FilePath: {eventArgs.FilePath},
        ContentType: {eventArgs.ContentType}"
    );
    if (eventArgs.Type == ProgressChangeType.Finished) {
        Debug.Log("Download finished");
        // Now that the file has finished downloading, do something with it.
        // For example, you can move it to a different location.
        File.Move(eventArgs.FilePath, someOtherLocation);
    }
};`,
  members: [
    {
      name: 'SetDownloadsEnabled',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      description: 'Sets whether file downloads are enabled. The default is disabled.'
    },
    {
      name: 'DownloadProgressChanged',
      type: 'event',
      returns: 'EventHandler<DownloadChangedEventArgs>',
      description: 'Indicates that the progress of a file download changed.'
    }
  ]

};
export default definition;
