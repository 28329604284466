import React from 'react';
import { Helmet } from 'react-helmet';

const DefaultHead = () => (
  <Helmet>
    <title>3D WebView: cross-platform web browser for Unity (Windows, macOS, Android, iOS, WebGL, and UWP) | Vuplex</title>
    <meta name="description" content="Easily add an interactive web browser to your Unity 3D scenes to display websites and HTML. Works with Windows, macOS, Android, iOS, WebGL, Meta Quest, and Universal Windows Platform (UWP)." />
  </Helmet>
);

export default DefaultHead;
