import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ScrolledEventArgs',
  type: 'class',
  description: 'Event args to indicate that a prefab has been scrolled.',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'ScrollDelta',
      returns: 'Vector2',
      type: 'property',
      description: 'The scroll delta that was passed to `IWebView.Scroll()`.'
    },
    {
      name: 'Point',
      returns: 'Vector2',
      type: 'property',
      description: 'The normalized point that was passed to `IWebView.Scroll()`.'
    }
  ]
};
export default definition;
