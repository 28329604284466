import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IPointerInputDetector',
  type: 'interface',
  plainTextDescription: "An interface that can be passed to WebViewPrefab.SetPointerInputDetector() or CanvasWebViewPrefab.SetPointerInputDetector() to override how the prefab detects pointer input. For example implementations of this interface, please see 3D WebView's DefaultPointerInputDetector.cs and CanvasPointerInputDetector.cs scripts.",
  description: "An interface that can be passed to [WebViewPrefab.SetPointerInputDetector()](/webview/WebViewPrefab#SetPointerInputDetector) or [CanvasWebViewPrefab.SetPointerInputDetector()](/webview/WebViewPrefab#SetPointerInputDetector) to override how the prefab detects pointer input. For example implementations of this interface, please see 3D WebView's DefaultPointerInputDetector.cs and CanvasPointerInputDetector.cs scripts.",
  members: [
    {
      name: 'BeganDrag',
      returns: 'EventHandler<EventArgs<Vector2>>',
      type: 'event',
      description: `Indicates the [normalized point](https://support.vuplex.com/articles/normalized-points) for the beginning of a drag interaction.`
    },
    {
      name: 'Dragged',
      returns: 'EventHandler<EventArgs<Vector2>>',
      type: 'event',
      description: `Indicates the [normalized point](https://support.vuplex.com/articles/normalized-points) for the continuation of a drag interaction.`
    },
    {
      name: 'PointerDown',
      returns: 'EventHandler<PointerEventArgs>',
      type: 'event',
      description: `Indicates a pointer down interaction occurred.`
    },
    {
      name: 'PointerEntered',
      returns: 'EventHandler',
      type: 'event',
      description: `Indicates that the pointer entered.`
    },
    {
      name: 'PointerExited',
      returns: 'EventHandler<EventArgs<Vector2>>',
      type: 'event',
      description: `Indicates the [normalized point](https://support.vuplex.com/articles/normalized-points) where the pointer exited.`
    },
    {
      name: 'PointerMoved',
      returns: 'EventHandler<EventArgs<Vector2>>',
      type: 'event',
      description: `Indicates the [normalized point](https://support.vuplex.com/articles/normalized-points) where the pointer moved.`
    },
    {
      name: 'PointerUp',
      returns: 'EventHandler<PointerEventArgs>',
      type: 'event',
      description: `Indicates a pointer up interaction occurred.`
    },
    {
      name: 'Scrolled',
      returns: 'EventHandler<ScrolledEventArgs>',
      type: 'event',
      description: `Indicates a scroll interaction occurred.`
    },
    {
      name: 'PointerMovedEnabled',
      returns: 'bool',
      type: 'property',
      description: 'The prefab sets this property to indicate whether the [PointerMoved](#PointerMoved) event should be enabled.'
    }
  ]
};
export default definition;

