import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'MixedContentMode',
  type: 'enum',
  description: "Options for the webview's behavior when a secure origin attempts to load a resource from an insecure origin.",
  members: [
    {
      name: 'AlwaysAllow',
      type: 'value',
      description: "In this mode, the webview will allow a secure origin to load content from any other origin, even if that origin is insecure."
    },
    {
      name: 'NeverAllow',
      type: 'value',
      description: "In this mode, the webview will not allow a secure origin to load content from an insecure origin.",
    },
    {
      name: 'CompatibilityMode',
      type: 'value',
      description: "In this mode, the webview will attempt to be compatible with the approach of a modern web browser with regard to mixed content. Some insecure content may be allowed to be loaded by a secure origin and other types of content will be blocked. The types of content are allowed or blocked may change release to release and are not explicitly defined. This mode is intended to be used by apps that are not in control of the content that they render but desire to operate in a reasonably secure environment."
    },
  ]
};
export default definition;

