import PublicApiError from '../models/errors/PublicApiError';

export default async function getApiErrorForResponse(response) {

  let body = {} as { message?: string; userMessage?: string; };
  try {
    body = await response.json();
  } catch (e) {}
  const message = body.message || `API responded with status code ${response.status}`;
  return new PublicApiError(message, body.userMessage, response.status);
}
