import Products from '../../web-common/models/products/Products';
import FileReferenceDefinition from '../FileReferenceDefinition';
import { getSharedPrefabMembers, getSharedPrefabDescription } from './prefab-shared';

const definition: FileReferenceDefinition = {
  name: 'CanvasWebViewPrefab',
  type: 'class',
  plainTextDescription: 'CanvasWebViewPrefab is a prefab that makes it easy to create and interact with web content in a 2D Canvas. It takes care of creating and initializing an IWebView, displaying its texture, and handling click and scroll interactions from the user.',
  description: `CanvasWebViewPrefab is a prefab that makes it easy to view and interact with an [IWebView](/webview/IWebView) in a 2D [Canvas](https://docs.unity3d.com/Packages/com.unity.ugui@1.0/manual/UICanvas.html). It takes care of creating an IWebView, displaying its texture, and handling pointer interactions from the user, like clicking, dragging, and scrolling. So, all you need to do is specify a URL or HTML to load, and then the user can view and interact with it. For use outside of a Canvas, see WebViewPrefab instead. For use outside of a Canvas, see [WebViewPrefab](/webview/WebViewPrefab) instead.

${getSharedPrefabDescription('CanvasWebViewPrefab')}`,
  inheritsFrom: [ 'MonoBehaviour' ],
  seeAlso: [
    '[WebViewPrefab](/webview/WebViewPrefab)',
    '[How clicking and scrolling works](https://support.vuplex.com/articles/clicking)',
    '[IWebView](/webview/IWebView)',
    '[Web (static methods)](/webview/Web)'
  ],
  members: [
    ...getSharedPrefabMembers(true),
    {
      name: 'Native2DModeEnabled',
      returns: 'bool',
      type: 'property',
      description: "Enables or disables [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode), which makes it so that 3D WebView positions a native 2D webview in front of the Unity game view instead of displaying web content as a texture in the Unity scene. The default is `false`. If set to `true` and the 3D WebView package in use doesn't support Native 2D Mode, then the default rendering mode is used instead. This field can only be set prior to initialization (i.e. prior to when Unity calls Start() for the CanvasWebViewPrefab). Native 2D Mode cannot be enabled or disabled after the webview has been initialized, so changing this field's value after initialization has no effect.",
      warnings: [
        `Native 2D Mode is only supported for [Android (non-Gecko)](${Products.android.storeUrl}), [iOS](${Products.iOS.storeUrl}), [WebGL](${Products.webGL.storeUrl}), and [UWP](${Products.uwp.storeUrl}). For the other 3D WebView packages, the default render mode is used instead.`,
        `Native 2D Mode requires that the [canvas's render mode](https://docs.unity3d.com/Packages/com.unity.ugui@1.0/manual/UICanvas.html) be set to "Screen Space - Overlay".`
      ]
    },
    {
      name: 'Instantiate',
      returns: 'CanvasWebViewPrefab',
      type: 'method',
      static: true,
      description: "Creates a new instance. The [WebView](#WebView) property is available after initialization completes, which is indicated by [WaitUntilInitialized()](#WaitUntilInitialized).",
      example: `// Create a CanvasWebViewPrefab
var canvasWebViewPrefab = CanvasWebViewPrefab.Instantiate();
// Position the prefab how we want it
var canvas = GameObject.Find("Canvas");
canvasWebViewPrefab.transform.parent = canvas.transform;
var rectTransform = canvasWebViewPrefab.transform as RectTransform;
rectTransform.anchoredPosition3D = Vector3.zero;
rectTransform.offsetMin = Vector2.zero;
rectTransform.offsetMax = Vector2.zero;
canvasWebViewPrefab.transform.localScale = Vector3.one;
// Load a URL once the prefab finishes initializing
await canvasWebViewPrefab.WaitUntilInitialized();
canvasWebViewPrefab.WebView.LoadUrl("https://vuplex.com");`
    },
    {
      name: 'Instantiate',
      returns: 'CanvasWebViewPrefab',
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'WebViewOptions',
          name: 'options'
        }
      ],
      description: "Like [Instantiate()](#Instantiate), except it also accepts an object of options flags that can be used to alter the generated webview's behavior."
    }
  ]
};
export default definition;

