import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ProgressChangeType',
  description: '',
  type: 'enum',
  members: [
    {
      name: 'Started',
      type: 'value',
      description: ''
    },
    {
      name: 'Finished',
      type: 'value',
      description: ''
    },
    {
      name: 'Failed',
      type: 'value',
      description: ''
    },
    {
      name: 'Updated',
      type: 'value',
      description: ''
    }
  ]
};
export default definition;
