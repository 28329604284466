import FileReferenceDefinition from '../FileReferenceDefinition';
import getSharedAndroidMembers from './android-shared';
import Products from '../../web-common/models/products/Products';

const definition: FileReferenceDefinition = {
  name: 'AndroidWebView',
  type: 'class',
  description: `AndroidWebView is the [IWebView](/webview/IWebView) implementation used by ${Products.android.markdownLink}. It also includes additional APIs for Android-specific functionality.
`,
  inheritsFrom: 'IWebView',
  additionalInterfaces: [
    'IWithAuth',
    'IWithDeepLinking',
    'IWithDownloads',
    'IWithFallbackTextureData',
    'IWithFileSelection',
    'IWithFind',
    'IWithMovablePointer',
    'IWithNative2DMode',
    'IWithNativeJavaScriptDialogs',
    'IWithNativeOnScreenKeyboard',
    'IWithPdfCreation',
    'IWithPointerDownAndUp',
    'IWithPopups',
    'IWithSettableUserAgent'
  ],
  sidebarSection: 'platform',
  members: [
    ...getSharedAndroidMembers(),
    {
      name: 'ClearHistory',
      returns: 'void',
      type: 'method',
      description: "Clears the webview's back / forward navigation history.",
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    androidWebView.ClearHistory();
#endif`
    },
    {
      name: 'ClientCertificateRequested',
      returns: 'EventHandler<EventArgs<AndroidJavaObject>>',
      type: 'event',
      description: `An event that maps directly to the native Android [onReceivedClientCertRequest()](https://developer.android.com/reference/android/webkit/WebViewClient#onReceivedClientCertRequest(android.webkit.WebView,%20android.webkit.ClientCertRequest)) API for handling client certificate requests. EventArgs.Value is an [AndroidJavaObject](https://docs.unity3d.com/ScriptReference/AndroidJavaObject.html) for the native [ClientCertRequest](https://developer.android.com/reference/android/webkit/ClientCertRequest) object. For details on how to handle certificate requests, see Android's documentation for [onReceivedClientCertRequest()](https://developer.android.com/reference/android/webkit/WebViewClient#onReceivedClientCertRequest(android.webkit.WebView,%20android.webkit.ClientCertRequest)) and [ClientCertRequest](https://developer.android.com/reference/android/webkit/ClientCertRequest).`,
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    androidWebView.ClientCertificateRequested += (sender, eventArgs) => {
        var clientCertRequest = eventArgs.Value;
        var host = clientCertRequest.Call<string>("getHost");
        Debug.Log("Client certificate requested for host: " + host);
        // TODO: This example calls ClientCertRequest.ignore() to ignore the request,
        // but you're probably intending to use this event to pass a client certificate,
        // in which case you'll want to use native Android APIs (like KeyChain.getPrivateKey)
        // to look up a private key and call ClientCertRequest.proceed() instead.
        clientCertRequest.Call("ignore");
    };
#endif`
    },
    {
      name: 'IsWebViewAvailable',
      static: true,
      returns: 'bool',
      type: 'method',
      description: `
Indicates whether the Android System WebView package is installed on the system and available.

3D WebView internally depends on Android's System WebView package, which is normally installed as part of the operating system. In rare circumstances, the Android System WebView package may be unavailable. For example, this can happen if the user used developer tools to delete the System WebView package or if [updates to the System WebView package are currently being installed](https://bugs.chromium.org/p/chromium/issues/detail?id=506369).`,
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    Debug.Log("WebView is available: " + AndroidWebView.IsWebViewAvailable());
#endif`
    },
    {
      name: 'GetNativeWebView',
      type: 'method',
      returns: 'AndroidJavaObject',
      description: "Returns the instance's native [android.webkit.WebView](https://developer.android.com/reference/android/webkit/WebView).",
      warnings: ["Warning: Adding code that interacts with the native WebView directly may interfere with 3D WebView's functionality and vice versa. So, it's highly recommended to stick to 3D WebView's C# APIs whenever possible and only use GetNativeWebView() if truly necessary. If 3D WebView is missing an API that you need, feel free to [contact us](https://vuplex.com/contact)."],
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    var nativeWebView = androidWebView.GetNativeWebView();
    // Call the android.webkit.WebView.findAll() method to search for the letter "a".
    // https://developer.android.com/reference/android/webkit/WebView#findAll(java.lang.String)
    // Most native WebView methods must be called on the Android UI thread.
    AndroidWebView.RunOnAndroidUIThread(() => {
        nativeWebView.Call<int>("findAll", "a");
    });
#endif`
    },
    {
      name: 'LoadHtml',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'html'
        },
        {
          type: 'string',
          name: 'baseUrl'
        }
      ],
      description: 'Like [IWebView.LoadHtml()](/webview/IWebView#LoadHtml), but also allows a virtual base URL to be specified. Setting a base URL allows, for example, for additional resources like CSS and JavaScript files to be referenced via a relative path.',
      example: `// Load some HTML that references a javascript.js file
// located in Application.persistentDataPath.
await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    var persistentDataPathFileUrl = "file://" + Application.persistentDataPath;
    androidWebView.LoadHtml(
        @"<div>
            <script src='javascript.js'></script>
            <h1>Hello!</h1>
        </div>",
        persistentDataPathFileUrl
    );
#endif`
    },
    {
      name: 'PauseAll',
      static: true,
      returns: 'void',
      type: 'method',
      description: `Pauses processing, media, and rendering for all webview instances.\n\nThis method is automatically called by the plugin when the application is paused. This method internally calls [android.webkit.WebView.pauseTimers()](https://developer.android.com/reference/android/webkit/WebView#pauseTimers()), which globally affects all native webview instances. So, if your project contains other plugins that use the System WebView (for example, ad SDKs), they can be affected by this method. If you find that 3D WebView is interfering with an ad SDK or other plugin in your project that uses the System WebView, please add the scripting symbol \`VUPLEX_ANDROID_DISABLE_AUTOMATIC_PAUSING\` to your project to prevent 3D WebView from automatically calling this method.`,
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    AndroidWebView.PauseAll();
#endif`
    },
    {
      name: 'PostUrl',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'url'
        },
        {
          type: 'byte[]',
          name: 'data'
        }
      ],
      description: `Loads the given URL using an HTTP POST request and the given application/x-www-form-urlencoded data.`,
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    androidWebView.PostUrl("https://postman-echo.com/post", Encoding.Unicode.GetBytes("foo=bar"));
#endif`
    },
    {
      name: 'SetCameraEnabled',
      returns: 'void',
      static: true,
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "Like [Web.SetCameraAndMicrophoneEnabled()](/webview/Web#SetCameraAndMicrophoneEnabled), but enables only the camera without enabling the microphone. In addition to calling this method, you must also complete the additional steps described [here](https://support.vuplex.com/articles/webrtc#android) in order to successfully enable the camera.",
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidWebView.SetCameraEnabled(true);
    #endif
}`
    },
    {
      name: 'SetForceDark',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'ForceDark',
          name: 'forceDark'
        }
      ],
      description: "Sets the force dark mode for this WebView. Note that this API is only supported on Android API level >= 29 and is ignored in older versions of Android.",
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    androidWebView.SetForceDark(ForceDark.On);
#endif`
    },
    {
      name: 'SetFullscreenEnabled',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "Sets whether web pages can use the [JavaScript Fullscreen API](https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API) to make an HTML element occupy the entire webview in 3D rendering mode or the entire device screen in Native 2D Mode. The default is `true`, meaning that the JavaScript Fullscreen API is enabled by default.",
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    await webViewPrefab.WaitUntilInitialized();
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    androidWebView.SetFullscreenEnabled(false);
#endif`
    },
    {
      name: 'SetInitialScale',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'float',
          name: 'scale'
        }
      ],
      description: "Sets the initial scale for web content, where 1.0 is the default scale.",
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    androidWebView.SetInitialScale(1.75f);
#endif`
    },
    {
      name: 'SetMicrophoneEnabled',
      returns: 'void',
      static: true,
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "Like [Web.SetCameraAndMicrophoneEnabled()](/webview/Web#SetCameraAndMicrophoneEnabled), but enables only the microphone without enabling the camera. In addition to calling this method, you must also complete the additional steps described [here](https://support.vuplex.com/articles/webrtc#android) in order to successfully enable the microphone.",
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidWebView.SetMicrophoneEnabled(true);
    #endif
}`
    },
    {
      name: 'SetMidiSysexEnabled',
      returns: 'void',
      static: true,
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "Enables sysex messages to be sent to or received from MIDI devices. The default is disabled. These messages are privileged operations, e.g. modifying sound libraries and sampling data, or even updating the MIDI device's firmware.",
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidWebView.SetMidiSysexEnabled(true);
    #endif
}`
    },
    {
      name: 'SetMixedContentMode',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'MixedContentMode',
          name: 'mode'
        }
      ],
      description: `Configures the webview's behavior when a secure origin attempts to load a resource from an insecure origin. The default mode is [MixedContentMode.CompatibilityMode.](/webview/MixedContentMode#CompatibilityMode)`,
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    androidWebView.SetMixedContentMode(MixedContentMode.AlwaysAllow);
#endif`
    },
    {
      name: 'SetNativeFileSelectionEnabled',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: `By default, a native file picker is shown for file inputs, but this method can be used to disable it. Note that the screen orientation of the native file picker UI is determined by the "Auto-rotate screen" preference in the device's Settings app.`,
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    androidWebView.SetNativeFileSelectionEnabled(false);
#endif`,
    },
    {
      name: 'SetNativeParentView',
      returns: 'void',
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'AndroidJavaObject',
          name: 'nativeParentView'
        }
      ],
      description: `3D WebView for Android is powered by native [android.webkit.WebView](https://developer.android.com/reference/android/webkit/WebView) instances, and it must
add those instances to the native Android view hierarchy in order for them to work correctly.
By default, 3D WebView adds the native WebView instances as children of
the Unity game's [ViewGroup](https://developer.android.com/reference/android/view/ViewGroup), which is obtained using this approach in Java:

\`\`\`
ViewGroup parentViewGroup = (ViewGroup)UnityPlayer.currentActivity.getWindow().getDecorView().getRootView();
\`\`\`

However, you can call this method at the start of the app to override the ViewGroup to which
3D WebView adds the native WebView instances. For example, you may need to do this if your app
embeds Unity as a library, which may cause \`UnityPlayer.currentActivity\` to return a different
activity than expected.`,
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidWebView.SetNativeParentView(nativeParentView);
    #endif
}`,
    },
    {
      name: 'SetRemoteDebuggingEnabled',
      returns: 'void',
      static: true,
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "When using 3D rendering mode (i.e. when [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode) is disabled), [remote debugging](https://support.vuplex.com/articles/how-to-debug-web-content) is enabled by default on Android because some APIs like MovePointer() and SendKey() work better with remote debugging enabled. However, this method can be used to explicitly disable remote debugging. When running in Native 2D Mode, remote debugging is disabled by default.",
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidWebView.SetRemoteDebuggingEnabled(false);
    #endif
}`,
    seeAlso: [
      '[Web.EnableRemoteDebugging()](/webview/Web#EnableRemoteDebugging)',
      '[WebViewPrefab.RemoteDebuggingEnabled](/webview/WebViewPrefab#RemoteDebuggingEnabled)'
    ]
    },
    {
      name: 'SetScrollbarsEnabled',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "When [Native 2D Mode](https://support.vuplex.com/articles/native-2d-mode/) is enabled, this method sets whether scrollbars are enabled. The default is `true`. When Native 2D Mode is not enabled, this method has no effect.",
      example: `await canvasWebViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = canvasWebViewPrefab.WebView as AndroidWebView;
    androidWebView.SetScrollbarsEnabled(false);
#endif`,
    },
    {
      name: 'SetTextZoom',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'int',
          name: 'textZoom'
        }
      ],
      description: `Sets the text zoom of the page in percent. For example, the browser engine automatically adjusts the size of web pages' text by default based on the "Font size" preference in the device's Settings app, but you can use \`SetTextZoom(100)\` to force that system font size preference to be ignored.`,
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidWebView = webViewPrefab.WebView as AndroidWebView;
    androidWebView.SetTextZoom(100);
#endif`,
      seeAlso: '[android.webkit.WebSettings.setTextZoom()](https://developer.android.com/reference/android/webkit/WebSettings#setTextZoom(int))'
    },
    {
      name: 'ZoomBy',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'float',
          name: 'zoomFactor'
        }
      ],
      description: "Zooms in or out by the given factor, which is multiplied by the current zoom level to reach the new zoom level. `zoomFactor` must be in the range from 0.01 to 100.0. Note that the zoom level gets reset when a new page is loaded.",
      example: `// Zoom by 1.75 after the page finishes loading.
await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.LoadProgressChanged += (sender, eventArgs) => {
    if (eventArgs.Type == ProgressChangeType.Finished) {
        #if UNITY_ANDROID && !UNITY_EDITOR
            var androidWebView = webViewPrefab.WebView as AndroidWebView;
            androidWebView.ZoomBy(1.75f);
        #endif
    }
};`
    },
  ]
};
export default definition;
