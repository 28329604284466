import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import MemberSection from './MemberSection';
import MemberTable from './MemberTable';
import FileReferenceDefinition from '../../models/FileReferenceDefinition';
import stripMarkdown from '../../web-common/utils/stripMarkdown';
import getFileReferenceLink from '../../utils/getFileReferenceLink';
import highlightCode from '../../utils/highlightCode';
import AdditionalInterfacesInfo from './AdditionalInterfacesInfo';
import './styles.scss';
import WarningBox from '../WarningBox';
import CodeExampleBox from '../CodeExampleBox';
import CodeFileMember from '../../models/CodeFileMember';

interface FileReferencePageProps extends FileReferenceDefinition {
  fileReferenceDefinitions: FileReferenceDefinition[];
}

export default class FileReferencePage extends Component<FileReferencePageProps> {

  componentDidMount() {

    highlightCode(this._componentElement);
  }

  componentDidUpdate() {

      highlightCode(this._componentElement);
  }

  render() {

    const members = this.props.members || [];
    const constructors = members.filter(m => m.type === 'constructor').sort(this._sortByName);
    const events = members.filter(m => m.type === 'event').sort(this._sortByName);
    const methods = members.filter(m => m.type === 'method').sort(this._sortByName);
    const properties = members.filter(m => m.type === 'property').sort(this._sortByName);
    const values = members.filter(m => m.type === 'value').sort(this._sortByName);
    const valuesHaveDescriptions = !!values.find(v => !!v.description);
    const metaDescription = this.props.plainTextDescription || (typeof this.props.description === 'string' ? stripMarkdown(this.props.description) : `3D WebView documentation for ${this.props.name}`);
    return (
      <div className="file-reference" ref={this._saveRef}>
        <Helmet>
          <title>{this.props.name} | 3D WebView Documentation</title>
          <meta name="description" content={metaDescription}/>
        </Helmet>
        <div>
          <h1>{this.props.name}</h1>
          <h2>{this.props.static ? 'static ' : ''}{this.props.type}{this._renderBaseClasses()}</h2>
          <h3>Namespace: Vuplex.WebView</h3>
        </div>
        {this._renderAdditionalInterfaceInfo()}
        {this._renderDescription()}
        {this.props.example && <CodeExampleBox example={this.props.example}/>}
        {this.props.warnings && <WarningBox warnings={this.props.warnings}/>}
        {this._renderSeeAlso()}
        <section className="member-summary">
          {!!members.length && <h2>Summary</h2>}
          {!!constructors.length && <MemberTable title="Public constructors" urlFragment="constructors-summary" members={constructors} />}
          {!!properties.length && <MemberTable title="Public properties" urlFragment="properties-summary" members={properties}/>}
          {!!methods.length && <MemberTable title="Public methods" urlFragment="methods-summary" members={methods}/>}
          {!!events.length && <MemberTable title="Public events" urlFragment="events-summary" members={events} />}
          {!!values.length && <MemberTable title="Values" members={values} urlFragment="values-summary" omitLinks={!valuesHaveDescriptions}/>}
        </section>
        {!!constructors.length && <MemberSection title="Public constructors" members={constructors} fileReferenceDefinitions={this.props.fileReferenceDefinitions} />}
        {!!properties.length && <MemberSection title="Public properties" members={properties} fileReferenceDefinitions={this.props.fileReferenceDefinitions}/>}
        {!!methods.length && <MemberSection title="Public methods" members={methods} fileReferenceDefinitions={this.props.fileReferenceDefinitions}/>}
        {!!events.length && <MemberSection title="Public events" members={events} fileReferenceDefinitions={this.props.fileReferenceDefinitions} />}
        {!!valuesHaveDescriptions && <MemberSection title="Values" members={values} fileReferenceDefinitions={this.props.fileReferenceDefinitions}/>}
      </div>
    );
  }

  private _componentElement = null as HTMLElement | null;

  private _renderAdditionalInterfaceInfo() {

    const { additionalInterfaces } = this.props;
    if (additionalInterfaces && additionalInterfaces.length) {
      // This is a class with additional interfaces, so list the interfaces.
      return (
        <AdditionalInterfacesInfo
          fileReferenceDefinitions={this.props.fileReferenceDefinitions}
          fileReferenceNames={additionalInterfaces}/>
      );
    }

    if (this.props.type !== 'interface') {
      return;
    }
    const relatedClassNames = this.props.fileReferenceDefinitions.reduce((accumulator, reference) => {
      const { additionalInterfaces } = reference;
      if (additionalInterfaces && additionalInterfaces.indexOf(this.props.name) !== -1) {
        accumulator.push(reference.name);
      }
      return accumulator;
    }, [] as string[]);

    if (relatedClassNames.length) {
      // This is an interface implemented by classes, so list the classes.
      return (
        <AdditionalInterfacesInfo
          title="Implemented by"
          fileReferenceDefinitions={this.props.fileReferenceDefinitions}
          fileReferenceNames={relatedClassNames} />
      );
    }
  }

  private _renderBaseClasses() {

    const { inheritsFrom } = this.props
    if (!(inheritsFrom && inheritsFrom.length)) {
      return;
    }
    let baseClasses: string[] = typeof this.props.inheritsFrom === 'string' ? [ inheritsFrom as string ] : inheritsFrom as string[];

    const baseClassElements = baseClasses.reduce((accumulator: any[], className: string, index) => {
      if (index) {
        accumulator.push(', ');
      }
      const fileReferenceLink = getFileReferenceLink(this.props.fileReferenceDefinitions, className);
      accumulator.push(fileReferenceLink || className);
      return accumulator;
    }, []);

    return <span> : {baseClassElements}</span>;
  }

  private _renderDescription() {

    const { description } = this.props;
    if (typeof description === 'string') {
      return <ReactMarkdown source={description} />
    }
    return description;
  }

  private _renderSeeAlso() {

    const { seeAlso } = this.props;
    if (!seeAlso || !seeAlso.length) {
      return;
    }
    if (Array.isArray(seeAlso)) {
      return (
        <div className="seealso">
          <strong>See also:</strong>
          <ul>
            {seeAlso.map(item => <li key={item}><ReactMarkdown source={item} /></li>)}
          </ul>
        </div>
      );
    }
    return <ReactMarkdown className="seealso" source={`**See also**: ${seeAlso}`} />;
  }

  private _saveRef = (componentElement) => this._componentElement = componentElement;

  private _sortByName = (a: CodeFileMember, b: CodeFileMember) => a.name > b.name ? 1 : -1;
}
