import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface ClassInfo {
  name: string,
  description?: string
}

const CLASSES: ClassInfo[] = [
  { name: 'StandaloneWebView', description: '(Windows and macOS)' },
  { name: 'AndroidWebView' },
  { name: 'AndroidGeckoWebView' },
  { name: 'iOSWebView' },
  { name: 'VisionOSWebView' },
  { name: 'WebGLWebView' },
  { name: 'UwpWebView' },
];

export default class PlatformSpecificPage extends Component {

  render() {
    return (
      <div className="page">
        <Helmet>
          <title>Platform-specific APIs</title>
          <meta name="description" content="List of 3D WebView's platform-specific classes."/>
        </Helmet>
        <h1>Platform-specific APIs</h1>
        <p style={{ margin: '40px 0'}}>
          The webview class for each platform also includes additional platform-specific APIs:
        </p>
        <ul>
          {CLASSES.map(this._renderClassName)}
        </ul>
      </div>
    );
  }


  private _renderClassName = (classInfo: ClassInfo) => (
    <li key={classInfo.name}>
      <Link to={`/webview/${classInfo.name}`}>{classInfo.name}</Link> {classInfo.description}
    </li>
  );

}
