import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import ExternalProductRelease from '../../../web-common/models/products/ExternalProductRelease';
import formatDate from '../../../web-common/utils/formatDate';
import './styles.scss';

export default class ReleaseView extends Component<{ release: ExternalProductRelease }> {

  render() {

    const { release } = this.props;
    return (
      <div className="release-view">
        <h2 id={release.version}>
          <strong>{release.version}</strong>
          <time dateTime={release.dateTime}>{formatDate(release.dateTime)}</time>
        </h2>
        <hr/>
        <ReactMarkdown className="release-notes" source={release.releaseNotesMarkdown}/>
      </div>
    );
  }
}
