
export default class PublicApiError extends Error {

  constructor(message?: string, userMessage?: string|boolean, statusCode=500) {
    super(message);
    this._statusCode = statusCode;
    if (userMessage) {
      this.userMessage = typeof userMessage === 'string' ? userMessage : message;
    }
  }

  userMessage?: string;

  /** @virtual */
  get statusCode() {

    return this._statusCode;
  }

  toJson(): string {

    return JSON.stringify({
      message: this.message,
      userMessage: this.userMessage
    });
  }

  private _statusCode: number;
}
