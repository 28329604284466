import Products from '../../web-common/models/products/Products';
import FileReferenceDefinition from '../FileReferenceDefinition';
import sharedKeyboardMembers from './keyboard-shared';

const definition: FileReferenceDefinition = {
  name: 'CanvasKeyboard',
  type: 'class',
  description: `Like the [Keyboard](/webview/Keyboard) prefab, except optimized for use in a [Canvas](https://docs.unity3d.com/2020.1/Documentation/Manual/UICanvas.html).
You can add a CanvasKeyboard to your scene either by dragging the CanvasKeyboard.prefab file
into a Canvas via the editor or by programmatically calling [CanvasKeyboard.Instantiate()](#Instantiate).
For an example, please see 3D WebView's CanvasWorldSpaceDemo scene.`,
  warnings: [
    `${Products.webGL.markdownLink} doesn't support CanvasKeyboard due to a browser limitation where clicking on the keyboard causes it to steal focus from webviews.`
  ],
  inheritsFrom: [ 'MonoBehaviour' ],
  seeAlso: [
    '[Keyboard](/webview/Keyboard)',
    '[How does keyboard input work?](https://support.vuplex.com/articles/keyboard)'
  ],
  members: [
    ...sharedKeyboardMembers,
    {
      name: 'WebViewPrefab',
      returns: 'CanvasWebViewPrefab?',
      type: 'property',
      propertyAccessors: { get: true },
      description: "Gets the CanvasWebViewPrefab used for the keyboard UI, or `null` if the keyboard hasn't finished initializing yet. You can use [WaitUntilInitialized()](#WaitUntilInitialized) to detect when the WebViewPrefab property is ready to use.",
      example: `await keyboard.WaitUntilInitialized();
keyboard.WebViewPrefab.Clicked += (sender, eventArgs) => {
    Debug.Log("Keyboard was clicked");
};`
    },
    {
      name: 'Instantiate',
      returns: 'Keyboard',
      type: 'method',
      static: true,
      description: 'Creates a new instance',
      example: `// Create a CanvasKeyboard.
var keyboard = CanvasKeyboard.Instantiate();
keyboard.transform.SetParent(canvas.transform, false);
var rectTransform = keyboard.transform as RectTransform;
rectTransform.anchoredPosition3D = Vector3.zero;
rectTransform.offsetMin = Vector2.zero;
rectTransform.offsetMax = Vector2.zero;
rectTransform.sizeDelta = new Vector2(650, 162);`
    },
  ]
};
export default definition;

