import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'ClickedEventArgs',
  type: 'class',
  description: 'Event args for [Clicked](/webview/WebViewPrefab#Clicked).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Point',
      returns: 'Vector2',
      type: 'property',
      description: 'The [normalized point](https://support.vuplex.com/articles/normalized-points) that was passed to [IWebView.Click()](/webview/IWebView#Click).'
    }
  ]
};
export default definition;

