import React, { Component } from 'react';
import searchImage from '../../../assets/search.svg';
import './styles.scss';

const SEARCH_BASE_URL = 'https://support.vuplex.com/search';
// const SEARCH_BASE_URL = 'http://localhost:8000/search';

export default class SearchInput extends Component {

  state = {
    value: ''
  };

  render() {
    return (
      <div className="search-input">
        <input
          type="search"
          placeholder="Search documentation"
          value={this.state.value}
          onChange={this._handleInputChange}
          onKeyDown={this._handleKeyDown}
        />
        <button disabled={!this.state.value.trim().length} onClick={this._submitSearch}>
          <img src={searchImage} alt="search"/>
        </button>
      </div>
    );
  }

  private _handleInputChange = (event) => this.setState({ value: event.target.value });

  private _handleKeyDown = (event) => {
    // Submit the search when Enter is pressed.
    if (event.keyCode === 13) {
      this._submitSearch();
    }
  }

  private _submitSearch = () => {
    if (this.state.value.trim().length) {
      location.href = `${SEARCH_BASE_URL}?q=${encodeURIComponent(this.state.value.trim())}`;
    }
  };
}
