import SessionStorage from './SessionStorage';
import PublicApiError from '../models/errors/PublicApiError';
import getApiErrorForResponse from '../utils/getApiErrorForResponse';
import LoginRedirectError from './LoginRedirectError';
import navigateToLogin from './navigateToLogin';
import StageHelper from '../utils/StageHelper';
import ExternalUser from '../models/accounts/ExternalUser';

export default class VuplexApi {

  static async authenticatedFetch(urlPath: string, options?: RequestInit) {

    const sessionInfo = SessionStorage.getSession();
    if (!sessionInfo) {
      navigateToLogin();
      throw new LoginRedirectError();
    }
    // Add the base URL if needed
    const result = await VuplexApi.fetch(urlPath, {
      headers: {
        'Authorization': sessionInfo!.token
      },
      ...options
    });
    if (result.status === 401) {
      navigateToLogin();
      throw new LoginRedirectError();
    }
    return result;
  }

  static async authenticatedGetJson(urlPath: string) {

    const response = await this.authenticatedFetch(urlPath);
    if (response.ok) {
      const body = await response.json();
      return body;
    }
    throw await getApiErrorForResponse(response);
  }

  static async authenticatedGetText(urlPath: string) {

    const response = await this.authenticatedFetch(urlPath);
    if (response.ok) {
      const body = await response.text();
      return body;
    }
    throw await getApiErrorForResponse(response);
  }

  static async authenticate(email: string, password: string) {

    const response = await fetch(`${this._baseUrl}/accounts/auth`, {
      method: 'post',
      body: JSON.stringify({ email, password })
    });
    if (response.ok) {
      const sessionInfo = await response.json();
      SessionStorage.setSession(sessionInfo);
      return;
    }
    let body = {} as { message?: string; userMessage?: string };
    try {
      body = await response.json();
    } catch (e) {
      console.warn('Error while trying to deserialize the error response body: ' + e);
    }
    if (body.message || body.userMessage) {
      throw new PublicApiError(body.message, body.userMessage);
    }
    throw new PublicApiError(`The /accounts/auth API responded with status code ${response.status}`, true);
  }

  static async fetch(urlPath: string, options?: RequestInit) {

    // Add the base URL if needed
    const url = urlPath.indexOf('http') === 0 ? urlPath : this._baseUrl + urlPath;
    const response = await fetch(url, options);
    return response;
  }

  static async fetchAndAssertOk(urlPath: string, options?: RequestInit) {

    const response = await VuplexApi.fetch(urlPath, options);
    if (!response.ok) {
      throw await getApiErrorForResponse(response);
    }
    return response;
  }

  static async getCurrentUser(): Promise<ExternalUser> {

    const { accountId, userId } = SessionStorage.getSessionOrNavigateToLogin();
    const result = await VuplexApi.authenticatedGetJson(`/accounts/${accountId}/admin/users/${userId}`);
    return result;
  }

  static async getJson(urlPath: string) {

    const response = await this.fetchAndAssertOk(urlPath);
    const body = await response.json();
    return body;
  }

  static async getUser(userId: string): Promise<ExternalUser> {

    const { accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const result = await VuplexApi.authenticatedGetJson(`/accounts/${accountId}/admin/users/${userId}`);
    return result;
  }

  private static _cachedBaseUrl = null as string|null;

  private static get _baseUrl() {

    if (!this._cachedBaseUrl) {
      this._cachedBaseUrl = StageHelper.isStaging() ? 'https://api-staging.vuplex.com' : 'https://api.vuplex.com';
    }
    return this._cachedBaseUrl;
  }
}
