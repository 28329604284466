import SessionInfo from '../models/accounts/SessionInfo';
import navigateToLogin from './navigateToLogin';
import LoginRedirectError from './LoginRedirectError';

// Note: the session token is stored in a cookie instead of
// LocalStorage in order to be able to share it across subdomains.
const SESSION_COOKIE_NAME = 'vx-s';
const COOKIE_REGEX = /vx-s=([a-z0-9]+)/i;
// 2 weeks
const MAX_AGE_SECONDS = 1209600;

export default class SessionStorage {

  static clearSession() {

    this._setSessionCookie('');
  }

  static getSession(): SessionInfo|null {
    try {
      const result = COOKIE_REGEX.exec(document.cookie);
      if (!result) {
        return null;
      }
      const encodedSessionInfo = result[1];
      if (!encodedSessionInfo) {
        return null;
      }
      const sessionInfoJson = atob(encodedSessionInfo);
      const sessionInfo = JSON.parse(sessionInfoJson);
      return sessionInfo;
    } catch (error) {
      console.warn('An error occurred while getting the session info: ' + error);
      return null;
    }
  }

  static getSessionOrNavigateToLogin() {

    const sessionInfo = SessionStorage.getSession();
    if (!sessionInfo) {
      navigateToLogin();
      throw new LoginRedirectError();
    }
    return sessionInfo as SessionInfo;
  }

  static setSession(sessionInfo: SessionInfo) {

    const sessionInfoJson = JSON.stringify(sessionInfo);
    const encodedSessionInfo = btoa(sessionInfoJson);
    this._setSessionCookie(encodedSessionInfo, MAX_AGE_SECONDS);
  }

  private static _setSessionCookie(value: string, maxAge = 0) {

    const domain = location.host.includes('vuplex.com') ? 'vuplex.com' : undefined;

    const cookieSegments = [
      `${SESSION_COOKIE_NAME}=${value}`,
      'path=/'
    ];
    if (maxAge) {
      // Cookie lifespan must be set, or else it defaults to a session cookie
      cookieSegments.push(`max-age=${maxAge}`);
    }
    if (domain) {
      cookieSegments.push(`domain=${domain}`);
    }
    document.cookie = cookieSegments.join('; ');
  }
}
