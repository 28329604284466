import Products from '../../web-common/models/products/Products';
import FileReferenceDefinition from '../FileReferenceDefinition';

const BEFORE_INIT_WARNING = "This method cannot be executed while the Chromium browser process is running. So, you will likely need to call it from Awake() to ensure that it's executed before Chromium is started. Alternatively, you can manually terminate Chromium prior to calling this method using [StandaloneWebView.TerminateBrowserProcess()](#TerminateBrowserProcess).";

const definition: FileReferenceDefinition = {
  name: 'StandaloneWebView',
  type: 'class',
  description: `StandaloneWebView is the [IWebView](/webview/IWebView) implementation used by ${Products.standalone.markdownLink}. It also includes additional APIs for Standalone-specific functionality.`,
  inheritsFrom: 'IWebView',
  additionalInterfaces: [
    'IWithAuth',
    'IWithCursorType',
    'IWithDownloads',
    'IWithFileSelection',
    'IWithIme',
    'IWithKeyDownAndUp',
    'IWithMovablePointer',
    'IWithMutableAudio',
    'IWithNativeJavaScriptDialogs',
    'IWithPdfCreation',
    'IWithPixelDensity',
    'IWithPointerDownAndUp',
    'IWithPopups',
    'IWithSettableUserAgent',
    'IWithTouch'
  ],
  sidebarSection: 'platform',
  members: [
    {
      name: 'ClientCertificateRequested',
      type: 'event',
      returns: 'EventHandler<StandaloneClientCertificateRequestedEventArgs>',
      description: `Indicates that the web page has requested a client certificate for authentication, and allows the application to select which client certificate to use.

In order for the Chromium browser engine to be able to use a client certificate, the certificate must be [installed in the system](https://www.digicert.com/kb/managing-client-certificates.htm). By default, if a web page requests a client certificate and the application hasn't attached an event handler to this event, then Chromium automatically selects the first client certificate available. If the application attaches an event handler to this event, the event handler should call the [eventArgs.Select()](/webview/StandaloneClientCertificateRequestedEventArgs#Select) callback and pass one of the certificates from the [eventArgs.Certificates](/webview/StandaloneClientCertificateRequestedEventArgs#Certificates) array as an argument. Or the handler can call \`eventArgs.Select(null)\` to continue without using a certificate.
  `,
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_STANDALONE || UNITY_EDITOR
    var standaloneWebView = webViewPrefab.WebView as StandaloneWebView;
    standaloneWebView.ClientCertificateRequested += (sender, eventArgs) => {
        if (eventArgs.Certificates.Length == 0) {
            Debug.Log("No matching client certificates are installed in the system; proceeding without a certificate.");
            eventArgs.Select(null);
            return;
        }
        // For your use case, you may want to select the certificate based on its fields,
        // such as Subject.CommonName or ValidStart.
        var certificate = eventArgs.Certificates[0];
        Debug.Log("Proceeding with client certificate: " + certificate);
        eventArgs.Select(certificate);
    };
#endif`
    },
    {
      name: 'CreatePdf',
      returns: 'Task<string>',
      type: 'method',
      arguments: [
        {
          type: 'StandalonePdfOptions',
          name: 'pdfOptions'
        }
      ],
      description: `Like [IWithPdfCreation.CreatePdf()](/webview/IWithPdfCreation#CreatePdf), but accepts an additional parameter with options for formatting the PDF.`,
      example: `#if UNITY_STANDALONE || UNITY_EDITOR
    var standaloneWebView = webViewPrefab.WebView as StandaloneWebView;
    var pdfFilePath = await standaloneWebView.CreatePdf(new StandalonePdfOptions {
        PageRanges = "1-3",
        PreferCssPageSize = true,
        PrintBackground = true
    });
    // Now that the PDF has been created, do something with it.
    // For example, you can move it to a different location.
    File.Move(pdfFilePath, someOtherLocation);
#endif`
    },
    {
      name: 'DeleteAllCookies',
      returns: 'Task<bool>',
      type: 'method',
      static: true,
      description: 'Deletes all cookies for all URLs and returns a `Task<bool>` indicating whether the deletion succeeded.',
      example: `#if UNITY_STANDALONE || UNITY_EDITOR
    var succeeded = await StandaloneWebView.DeleteAllCookies();
#endif`
    },
    {
      name: 'EnableRemoteDebugging',
      returns: 'void',
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'int',
          name: 'portNumber'
        }
      ],
      description: `Like [Web.EnableRemoteDebugging()](/webview/Web#EnableRemoteDebugging), but starts the DevTools session on the specified port instead of the default port 8080.`,
      example: `void Awake() {
    StandaloneWebView.EnableRemoteDebugging(9000);
}`
    },
    {
      name: 'SendDevToolsMessage',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'messageJson'
        }
      ],
      description: `Sends a method call message over the Chrome DevTools protocol. The messageJson parameter is a JSON DevTools message that contains "id" (int), "method" (string), and "params" (dictionary, optional) values. See the [DevTools protocol documentation](https://chromedevtools.github.io/devtools-protocol/) for details of supported methods and the expected "params" dictionary contents.`,
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_STANDALONE || UNITY_EDITOR
    var standaloneWebView = webViewPrefab.WebView as StandaloneWebView;
    var messageId = 0;
    standaloneWebView.SendDevToolsMessage(
        @$"{{
            ""id"": {messageId++},
            ""method"": ""Network.setUserAgentOverride"",
            ""params"": {{ ""userAgent"": ""a custom user agent"" }}
        }}"
    );
#endif`
    },
    {
      name: 'SetCachePath',
      static: true,
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'absoluteFilePath'
        }
      ],
      description: `By default, Chromium's cache is saved at the file path [Application.persistentDataPath](https://docs.unity3d.com/ScriptReference/Application-persistentDataPath.html)/Vuplex.WebView/chromium-cache, but you can call this method to specify a custom file path for the cache instead. This is useful, for example, to [allow multiple instances of your app to run on the same machine](https://support.vuplex.com/articles/multiple-app-instances), because multiple instances of Chromium cannot simultaneously share the same cache.`,
      example: `void Awake() {
    #if UNITY_STANDALONE || UNITY_EDITOR
        var customCachePath = Path.Combine(Application.persistentDataPath, "your-chromium-cache");
        StandaloneWebView.SetCachePath(customCachePath);
    #endif
}`,
    warnings: [BEFORE_INIT_WARNING]
    },
    {
      name: 'SetChromiumLogLevel',
      static: true,
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'ChromiumLogLevel',
          name: 'level'
        }
      ],
      description: `Sets the log level for the Chromium logs. The default is ChromiumLogLevel.Warning. For a description of where the Chromium logs are written to, see [SetChromiumLogPath()](#SetChromiumLogPath)`,
      warnings: [BEFORE_INIT_WARNING],
      example: `void Awake() {
    #if UNITY_STANDALONE || UNITY_EDITOR
        StandaloneWebView.SetChromiumLogLevel(ChromiumLogLevel.Disabled);
    #endif
}`,
      seeAlso: '[SetChromiumLogPath](#SetChromiumLogPath)'
    },
    {
      name: 'SetChromiumLogPath',
      static: true,
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'absoluteFilePath'
        }
      ],
      description: `Overrides the absolute file path to which the Chromium debug log is written. By default, Chromium's log file is written to the following locations:
- Windows editor: \`{ProjectPath}\\Assets\\Vuplex\\WebView\\Standalone\\Windows\\Plugins\\VuplexWebViewChromium\\log-chromium.txt~\`
- Windows player: \`{AppPath}\\{AppName}_Data\\Plugins\\{Architecture}\\VuplexWebViewChromium\\log-chromium.txt\`
- macOS: \`~/Library/Logs/Vuplex/log-chromium.txt\``,
      warnings: [BEFORE_INIT_WARNING],
      example: `void Awake() {
    #if UNITY_STANDALONE || UNITY_EDITOR
        var customLogPath = Path.Combine(Application.persistentDataPath, "your-chromium-log.txt");
        StandaloneWebView.SetChromiumLogPath(customLogPath);
    #endif
}`,
      seeAlso: '[SetChromiumLogLevel](#SetChromiumLogLevel)'
    },
    {
      name: 'SetCommandLineArguments',
      static: true,
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string',
          name: 'args'
        }
      ],
      description: `Sets additional command line arguments to pass to Chromium. For reference, [here's an unofficial list of Chromium command line arguments](https://peter.sh/experiments/chromium-command-line-switches/).`,
      example: `void Awake() {
    #if UNITY_STANDALONE || UNITY_EDITOR
        StandaloneWebView.SetCommandLineArguments("--ignore-certificate-errors --disable-web-security");
    #endif
}`,
    warnings: [BEFORE_INIT_WARNING]
    },
    {
      name: 'SetNativeFileDialogEnabled',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "The native file picker for file input elements is enabled by default,  but it can be disabled with this method.",
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_STANDALONE || UNITY_EDITOR
    var standaloneWebView = webViewPrefab.WebView as StandaloneWebView;
    standaloneWebView.SetNativeFileDialogEnabled(false);
#endif`
    },
    {
      name: 'SetScreenSharingEnabled',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: `
By default, web pages cannot share the device's screen
via JavaScript. Invoking \`SetScreenSharingEnabled(true)\` allows
**all web pages** to share the screen.

The screen that is shared is the default screen, and there isn't currently
support for sharing a different screen or a specific application window.
This is a limitation of Chromium Embedded Framework (CEF), which 3D WebView
uses to embed Chromium.`,
      example: `void Awake() {
    #if UNITY_STANDALONE || UNITY_EDITOR
        StandaloneWebView.SetScreenSharingEnabled(true);
    #endif
}`,
    warnings: [BEFORE_INIT_WARNING]
    },
    {
      name: 'SetTargetFrameRate',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          type: 'uint',
          name: 'targetFrameRate'
        }
      ],
      description: 'Sets the target web frame rate. The default is `60`, which is also the maximum value. Specifying a target frame rate of `0` disables the frame rate limit.',
      example: `void Awake() {
    #if UNITY_STANDALONE || UNITY_EDITOR
        // Disable the frame rate limit.
        StandaloneWebView.SetTargetFrameRate(0);
    #endif
}`,
      warnings: [BEFORE_INIT_WARNING]
    },
    {
      name: 'SetZoomLevel',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'float',
          name: 'zoomLevel'
        }
      ],
      description: "Sets the zoom level to the specified value. Specify `0.0` to reset the zoom level.",
      example: `// Set the zoom level to 1.75 after the page finishes loading.
await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.LoadProgressChanged += (sender, eventArgs) => {
    if (eventArgs.Type == ProgressChangeType.Finished) {
        #if UNITY_STANDALONE || UNITY_EDITOR
            var standaloneWebView = webViewPrefab.WebView as StandaloneWebView;
            standaloneWebView.SetZoomLevel(1.75f);
        #endif
    }
};`
    },
    {
      name: 'TerminateBrowserProcess',
      returns: 'Task',
      type: 'method',
      static: true,
      description: `Terminates the Chromium browser process.

When 3D WebView for Windows and macOS initializes the application's first webview, it starts Chromium in a separate process. That Chromium process runs until the application closes, at which point 3D WebView automatically calls this method to terminate the Chromium process. However, in some rare cases, you may want your application to call this method manually to terminate Chromium while the app is still running. For example, some 3D WebView APIs like [Web.ClearAllData()](/webview/Web#ClearAllData) cannot be called while Chromium is running, but your application can use this method to terminate Chromium so that it can call those APIs. Prior to calling this method, your application must destroy all of the application's existing webviews (e.g. using [WebViewPrefab.Destroy()](/webview/WebViewPrefab#Destroy)). After Chromium finishes terminating, you can restart Chromium by creating new webview instances. (e.g. using [WebViewPrefab.Instantiate()](/webview/WebViewPrefab#Instantiate)).`,
      example: `async void ClearDataAtRuntime() {
    #if UNITY_STANDALONE || UNITY_EDITOR
        // 1. Destroy all the webviews in the application.
        var webViewPrefabs = GameObject.FindObjectsOfType<BaseWebViewPrefab>();
        foreach (var prefab in webViewPrefabs) {
            prefab.Destroy();
        }

        // 2. Terminate Chromium.
        await StandaloneWebView.TerminateBrowserProcess();

        // 3. Call the API that can't be called while Chromium is running.
        Web.ClearAllData();

        // 4. TODO: Create new webviews with WebViewPrefab.Instantiate() if needed.
    #else
        // On other platforms, Web.ClearAllData() can be called while the browser process is running.
        Web.ClearAllData();
    #endif
}`
    },
  ]
};
export default definition;
