import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'StandaloneClientCertificateRequestedEventArgs',
  type: 'class',
  description: 'Event args for [StandaloneWebView.ClientCertificateRequested](/webview/StandaloneWebView#ClientCertificateRequested).',
  inheritsFrom: 'System.EventArgs',
  members: [
    {
      name: 'Certificates',
      returns: 'StandaloneX509Certificate[]',
      type: 'property',
      description: 'The list of certificates to choose from. This list has already been pruned by Chromium so that it only contains certificates from issuers that the server trusts.'
    },
    {
      name: 'Host',
      returns: 'string',
      type: 'property',
      description: 'The hostname of the SSL server.'
    },
    {
      name: 'Port',
      returns: 'int',
      type: 'property',
      description: 'The port of the SSL server.'
    },
    {
      name: 'IsProxy',
      returns: 'bool',
      type: 'property',
      description: 'Indicates whether the host is an HTTPS proxy or the origin server.'
    },
    {
      name: 'Select',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          name: 'certificate',
          type: 'StandaloneX509Certificate'
        }
      ],
      description: 'The callback to invoke to select a certificate. The certificate parameter can either be one of the certificates from the [Certificates](#Certificates) array or `null` to continue without a certificate.'
    },
  ]
};
export default definition;
