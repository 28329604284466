import FileReferenceDefinition from '../FileReferenceDefinition';
import sharedKeyboardMembers from './keyboard-shared';

const definition: FileReferenceDefinition = {
  name: 'Keyboard',
  type: 'class',
  description: `A 3D, on-screen keyboard prefab that you can hook up to a webview for typing.
You can add a Keyboard to your scene either by dragging the Keyboard.prefab file into it
via the editor or by programmatically calling [Keyboard.Instantiate()](#Instantiate).
For use in a [Canvas](https://docs.unity3d.com/2020.1/Documentation/Manual/UICanvas.html), please see [CanvasKeyboard](/webview/CanvasKeyboard) instead.

The keyboard UI is a React.js app that runs inside a [WebViewPrefab](/webview/WebViewPrefab) and
emits messages to C# when keys are pressed.
[The keyboard UI is open source and available on GitHub](https://github.com/vuplex/unity-keyboard).

The keyboard supports layouts for the following languages and automatically sets the layout
based on the operating system's default language: English, Spanish, French, German, Italian, Russian, Danish, Norwegian, and Swedish.

Please note that 3D WebView's on-screen keyboard prefabs do not support Chinese, Japanese, or Korean. For those languages, please see [this article about IME support](https://support.vuplex.com/articles/chinese-japanese-and-korean) and [this section](https://support.vuplex.com/articles/chinese-japanese-and-korean#ime-unsupported) that describes how to enter characters for those languages programmatically.`,
  inheritsFrom: [ 'MonoBehaviour' ],
  seeAlso: [
    '[CanvasKeyboard](/webview/CanvasKeyboard)',
    '[How does keyboard input work?](https://support.vuplex.com/articles/keyboard)'
  ],
  members: [
    ...sharedKeyboardMembers,
    {
      name: 'WebViewPrefab',
      returns: 'WebViewPrefab?',
      type: 'property',
      propertyAccessors: { get: true },
      description: "Gets the WebViewPrefab used for the keyboard UI, or `null` if the keyboard hasn't finished initializing yet. You can use [WaitUntilInitialized()](#WaitUntilInitialized) to detect when the WebViewPrefab property is ready to use.",
      example: `await keyboard.WaitUntilInitialized();
keyboard.WebViewPrefab.Clicked += (sender, eventArgs) => {
    Debug.Log("Keyboard was clicked");
};`
    },
    {
      name: 'Instantiate',
      returns: 'Keyboard',
      type: 'method',
      static: true,
      description: 'Creates an instance using the default width and height.',
      example: `// Add a keyboard under a WebViewPrefab.
var keyboard = Keyboard.Instantiate();
keyboard.transform.SetParent(webViewPrefab.transform, false);
keyboard.transform.localPosition = new Vector3(0, -0.31f, 0);
keyboard.transform.localEulerAngles = Vector3.zero;`
    },
    {
      name: 'Instantiate',
      returns: 'Keyboard',
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'float',
          name: 'width'
        },
        {
          type: 'float',
          name: 'height'
        }
      ],
      description: 'Like Instantiate(), but creates an instance using the specified width and height.'
    }
  ]
};
export default definition;
