import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

/**
 * This component is used to fix an issue where when you click
 * on a react-router-dom Link to a new page, it doesn't actually
 * take you to the top of that page.
 */
class ScrollToTop extends Component<RouteComponentProps> {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
