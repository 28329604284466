import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'IWithMutableAudio',
  type: 'interface',
  description: `An interface implemented by a webview if it supports muting audio.`,
  example: `await webViewPrefab.WaitUntilInitialized();
var mutableWebView = webViewPrefab.WebView as IWithMutableAudio;
if (mutableWebView != null) {
    mutableWebView.SetAudioMuted(true);
}`,
  members: [
    {
      name: 'SetAudioMuted',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'muted',
          type: 'bool'
        }
      ],
      description: 'Sets whether audio is muted. The default is `false`.'
    }
  ]
};
export default definition;
