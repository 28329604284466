import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'TouchEvent',
  type: 'struct',
  description: "A touch event that can be dispatched with [IWithTouch.SendTouchEvent()](/webview/IWithTouch#SendTouchEvent).",
  members: [
    {
      name: 'TouchID',
      returns: 'int',
      type: 'property',
      description: "ID of the touch. This value must be unique per touch but is shared by all events for the same touch (i.e. the Start, Move, and End events for a single touch share the same TouchID). This can be any number except -1, and a maximum of 16 concurrent touches are tracked."
    },
    {
      name: 'Type',
      returns: 'TouchEventType',
      type: 'property',
      description: "The touch event type. Touches begin with one [TouchEventType.Start](/webview/TouchEventType#Start) event, followed by zero or more [TouchEventType.Move](/webview/TouchEventType#Move) events, and finally one [TouchEventType.End](/webview/TouchEventType#End) or [TouchEventType.Cancel](/webview/TouchEventType#Cancel) event."
    },
    {
      name: 'Point',
      returns: 'Vector2',
      type: 'property',
      description: `The [normalized point](https://support.vuplex.com/articles/normalized-points) of the touch event.`
    },
    {
      name: 'RadiusX',
      returns: 'float',
      type: 'property',
      description: "(optional) The X radius of the touch in pixels. If not set, the default value of 25px will be used."
    },
    {
      name: 'RadiusY',
      returns: 'float',
      type: 'property',
      description: "(optional) The Y radius of the touch in pixels. If not set, the default value of 25px will be used."
    },
    {
      name: 'RotationAngle',
      returns: 'float',
      type: 'property',
      description: "(optional) The rotation angle in radians."
    },
    {
      name: 'RotationAngle',
      returns: 'float',
      type: 'property',
      description: "(optional) The normalized pressure of the touch in the range of \\[0, 1]."
    },
  ]
};
export default definition;
