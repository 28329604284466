import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'VisionOSWindow',
  type: 'class',
  description: 'Accessible via the [VisionOSWebView.Window](/webview/VisionOSWebView#Window) property, this class provides APIs for manipulating the window of a webview created via [VisionOSWebView.CreateInWindow()](/webview/VisionOSWebView#CreateInWindow).',
  members: [
    {
      name: 'IsOpen',
      returns: 'bool',
      type: 'property',
      description: 'Indicates whether the window is currently open.',
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow();
    if (webView.Window.IsOpen) {
        Debug.Log("Window is open");
    }
#endif`
    },
    {
      name: 'Closed',
      returns: 'EventHandler',
      type: 'event',
      description: 'Indicates that the window closed, either because the user pressed its close button or because the application called [Close()](#Close).',
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow();
    // Add an event handler that destroys the webview when the user closes the window.
    webView.Window.Closed += (sender, eventArgs) => {
        webView.Dispose();
    };
#endif`
    },
    {
      name: 'Close',
      returns: 'void',
      type: 'method',
      description: `Closes the window. If the window is already closed, this method has no effect. Note that closing the window doesn't destroy the webview, and the window can be reopened by calling [Open()](#Open). To destroy the webview, call [IWebView.Dispose()](/webview/IWebView#Dispose) instead.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    webView.Window.Close();
#endif`
    },
    {
      name: 'Open',
      returns: 'void',
      type: 'method',
      description: 'Reopens the window after it has previously been closed with [Close()](#Close). If the window is already open, this method has no effect. This version of Open() opens the window using the size and position specified when the webview was created with CreateInWindow(). To open the window at a different size and position, use [Open(VisionOSWindowOptions)](#Open).',
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    webView.Window.Open();
#endif`
    },
    {
      name: 'Open',
      returns: 'void',
      arguments: [
        {
          type: 'VisionOSWindowOptions',
          name: 'options'
        }
      ],
      type: 'method',
      description: `Like Open(), except it also accepts a [VisionOSWindowOptions](/webview/VisionOSWindowOptions) parameter for customizing the window's initial size and position.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    webView.Window.Open(
        new VisionOSWindowOptions {
            Size = new Vector2Int(1000, 1000),
            Position = new VisionOSWindowPosition(
                VisionOSWindowPositionType.Trailing,
                VisionOSWindowPosition.UnityBoundedSceneID
            )
        }
    );
#endif`,
      warnings: [
        `The VisionOSWindowsOptions.Size and Position options require visionOS 2.0. On visionOS 1.x, the Size and Position options are ignored, and a window always uses the system's default size and position.`
      ],
      seeAlso: '[Fullscreen support in 3D WebView](https://support.vuplex.com/articles/fullscreen)'
    },
    {
      name: 'SetFullscreenEnabled',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      description: `Sets whether web pages can use the [JavaScript Fullscreen API](https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API) to make an HTML element occupy the entire webview. The default is \`true\`, meaning that the JavaScript Fullscreen API is enabled by default.`,
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow();
    // Disable the JavaScript Fullscreen API.
    webView.Window.SetFullscreenEnabled(false);
#endif`
    },
    {
      name: 'SetScrollViewBounces',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          name: 'bounces',
          type: 'bool'
        }
      ],
      description: 'Sets whether the scroll view bounces past the edge of content and back again. The default is `true`.',
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow();
    webView.Window.SetScrollViewBounces(false);
#endif`
    },
  ]
};
export default definition;

