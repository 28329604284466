import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Spinner from '../../web-common/components/Spinner';
import ExternalProductRelease from '../../web-common/models/products/ExternalProductRelease';
import VuplexApi from '../../web-common/client/VuplexApi';
import ReleaseView from './ReleaseView';
import '../../shared-styles/page.scss';
import './styles.scss';

export default class GettingStartedPage extends Component {

  state = {
    releases: [] as ExternalProductRelease[]
  }

  async componentDidMount() {

    const releases = await VuplexApi.getJson('/products/webview/releases');
    this.setState({ releases });
  }

  render() {
    return (
      <div className="page releases-page">
        <Helmet>
          <title>Releases | 3D WebView</title>
          <meta name="description" content="Release notes for 3D WebView"/>
        </Helmet>
        <h1>3D WebView Release Notes</h1>
        {this._renderContent()}
      </div>
    );
  }

  private _renderContent() {

    if (!this.state.releases.length) {
      return (
        <div className="spinner-container">
          <Spinner className="spinner"/>
        </div>
      )
    }
    return (
      <div>
        {this.state.releases.map(r => <ReleaseView key={r.version} release={r}/>)}
      </div>
    )
  }
}
