import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'TouchEventType',
  type: 'enum',
  description: 'Touch event type values for [TouchEvent.Type](/webview/TouchEvent#Type).',
  members: [
    {
      name: 'End',
      type: 'value',
      description: ''
    },
    {
      name: 'Start',
      type: 'value',
      description: ''
    },
    {
      name: 'Move',
      type: 'value',
      description: ''
    },
    {
      name: 'Cancel',
      type: 'value',
      description: ''
    },
  ]
};
export default definition;
