import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FileReferenceDefinition from '../../../models/FileReferenceDefinition';
import getFileReferenceLink from '../../../utils/getFileReferenceLink';
import './styles.scss';

interface AdditionalInterfacesInfoProps {
  fileReferenceNames: string[];
  fileReferenceDefinitions: FileReferenceDefinition[];
  title?: string;
}

export default class AdditionalInterfacesInfo extends Component<AdditionalInterfacesInfoProps> {

  render() {

    const { fileReferenceNames } = this.props;
    return (
      <div className="additional-interfaces">
        <span>{this.props.title || 'Additional feature interfaces'}: <Link className="help-link" to="/webview/additional-interfaces">?</Link></span>
        <ul>
          {fileReferenceNames.map(name => (
            <li key={name}>
              {getFileReferenceLink(this.props.fileReferenceDefinitions, name)}
            </li>
          ))}
        </ul>
      </div>
    )
  }
}
